// REACT
import React, { useCallback, useMemo } from "react";

// TYPES
import { SubjectActions, SubjectData } from "p6m-subjects";
import { CoverImage } from "p6-react-shared";

// LIBRARIES
import { useT, T } from "@transifex/react";
import ReactTooltip from "react-tooltip";

// COMPONENTS
import PopUp from "../../../basic/popupAnchored/Popup";
import UserSubjectFurtherActions from "../../../connected/userSubjectFurtherActions";
import SubjectAction from "./UserSubjectsListEntryAction";

// HELPERS
import {
    getSubjectCover,
    getCardCount,
    getShopUrl,
    convertSecondsIntoHighestPossibleTimeUnit,
    TimeUnitName,
} from "../../../../helpers/Subjects";

// STYLED COMPONENTS
import {
    Divider,
    Container,
    ExpirationContainer,
    MoreActionsButtonContainer,
    MoreActionsPhaseSixIcon,
    PopupContainer,
    Stat,
    Statistics,
    StyledPhaseSixIcon,
    SubjectActionContainer,
    TitleContainer,
    TitleLink,
    TitleText,
} from "./styles";

export interface UserSubjectsListEntryProps {
    type: "due" | "inactive" | "further" | "expired";
    subject: SubjectData;
    userId: string | undefined;
    subjectActions: SubjectActions;
}

const UserSubjectsListEntry: React.FC<UserSubjectsListEntryProps> = (props) => {
    const t = useT();
    const { subject } = props;

    let totalCardCount = 0;
    let dueCardCount = 0;
    let inactiveCardCount = 0;
    let inProgressCardCount = 0;
    let inLongTermMemoryCardCount = 0;
    let nextPracticeDate: string | undefined = undefined;

    if (props.subject.groupedCardCount) {
        totalCardCount = props.subject.groupedCardCount.cardCounts["LIBRARY"].cardCount;
        dueCardCount = props.subject.groupedCardCount.cardCounts["PRACTICE"].cardCount;
        inactiveCardCount = props.subject.groupedCardCount.cardCounts["LEARN_NEW"].cardCount;
        inProgressCardCount = props.subject.groupedCardCount.cardCounts["ACTIVE_NOT_LEARNED"].cardCount;
        inLongTermMemoryCardCount = props.subject.groupedCardCount.cardCounts["LAST_PHASE"].cardCount;
        nextPracticeDate = props.subject.groupedCardCount?.nextPracticeDate;
    }

    const shouldPracticeToday = useMemo(() => {
        if (!subject) return 0;
        return getCardCount(subject, "SHOULD_PRACTICE_TODAY");
    }, [subject]);

    const allCardsLearned = (totalCardCount > 0 && totalCardCount === inLongTermMemoryCardCount) || dueCardCount < 1; // all cards in last phase OR all due cards for today learned
    const allCardsInactive = totalCardCount > 0 && totalCardCount === inactiveCardCount;
    const isClassroomSubject = props.subject.subjectMetadata.classroomFlag;

    const image = props.subject && getSubjectCover(props.subject);

    const tooltipId = props.subject.subjectMetadata.subjectIdToOwner.id + "_options_tooltip";
    const inactiveTooltipId = props.subject.subjectMetadata.subjectIdToOwner.id + "_inactive_cards_tooltip";
    const activeTooltipId = props.subject.subjectMetadata.subjectIdToOwner.id + "_active_cards_tooltip";
    const learnedTooltipId = props.subject.subjectMetadata.subjectIdToOwner.id + "_learned_cards_tooltip";

    const trialTime = props?.subject?.groupedCardCount?.trialTime;

    const renderExpirationDaysT = () => {
        if (!trialTime || trialTime === 0) return;
        const seconds = trialTime < 6000 ? 60 : trialTime / 1000;
        const { timeUnit, unitAmount, showPlural } = convertSecondsIntoHighestPossibleTimeUnit(seconds);

        let expirationInfo: string;
        switch (timeUnit) {
            case TimeUnitName.minute:
                expirationInfo = showPlural
                    ? t("Expires in {x} minutes", { x: unitAmount, _tags: "subject,expiration,timeUnitDistance" })
                    : t("Expires in 1 minute", { _tags: "subject,expiration,timeUnitDistance" });
                break;
            case TimeUnitName.hour:
                expirationInfo = showPlural
                    ? t("Expires in {x} hours", { x: unitAmount, _tags: "subject,expiration,timeUnitDistance" })
                    : t("Expires in 1 hour", { _tags: "subject,expiration,timeUnitDistance" });
                break;
            case TimeUnitName.day:
                expirationInfo = showPlural
                    ? t("Expires in {x} days", { x: unitAmount, _tags: "subject,expiration,timeUnitDistance" })
                    : t("Expires in 1 day", { _tags: "subject,expiration,timeUnitDistance" });
                break;
            default:
                expirationInfo = "";
        }

        return `${expirationInfo} - ${t("Unlock now?", { _tags: "subject,expiration,callToAction" })}`;
    };

    // build pup-up content
    const PopupContent = useCallback(
        (PopupActions?: any) => {
            const closePopup = !!PopupActions && !!PopupActions.closePopup ? PopupActions.closePopup : () => {};
            return (
                <UserSubjectFurtherActions
                    userId={props.userId}
                    type={props.type}
                    subject={props.subject}
                    subjectActions={props.subjectActions}
                    totalCardCount={totalCardCount || 0}
                    inactiveCardCount={inactiveCardCount || 0}
                    closePopup={closePopup}
                    allCardsLearned={allCardsLearned}
                    allCardsInactive={allCardsInactive}
                    isClassroomSubject={isClassroomSubject}
                    hasNextPracticeDate={!!nextPracticeDate}
                />
            );
        },
        [
            props.userId,
            props.type,
            props.subject,
            props.subjectActions,
            totalCardCount,
            inactiveCardCount,
            allCardsLearned,
            allCardsInactive,
            isClassroomSubject,
            nextPracticeDate,
        ]
    );

    return (
        <Container {...props}>
            <CoverImage
                imageId={image || null}
                size="small"
            />
            <TitleContainer>
                {!!trialTime && trialTime > 0 ? (
                    <TitleLink
                        href={getShopUrl(props.subject.subjectMetadata.subjectIdToOwner.id)}
                        target={"_blank"}
                    >
                        <TitleText>{props.subject.subjectContent.name}</TitleText>
                        <ExpirationContainer>
                            {renderExpirationDaysT()}
                            <StyledPhaseSixIcon name="lock" />
                        </ExpirationContainer>
                    </TitleLink>
                ) : (
                    <TitleText>{props.subject.subjectContent.name}</TitleText>
                )}
            </TitleContainer>
            <SubjectActionContainer>
                <SubjectAction
                    type={props.type}
                    subject={props.subject}
                    subjectActions={props.subjectActions}
                    dueCardCount={dueCardCount}
                    shouldPracticeToday={shouldPracticeToday}
                    nextPracticeDate={nextPracticeDate}
                    userId={props.userId}
                />
            </SubjectActionContainer>
            <PopupContainer>
                <PopUp
                    arrow={true}
                    position={["top", "center"]}
                    content={PopupContent}
                >
                    <MoreActionsButtonContainer
                        data-for={tooltipId}
                        data-tip=""
                    >
                        <MoreActionsPhaseSixIcon name="menu" />
                    </MoreActionsButtonContainer>
                    <ReactTooltip id={tooltipId}>
                        <T
                            _str="More options"
                            _tags="tooltip,subject,home,options"
                        />
                    </ReactTooltip>
                </PopUp>
            </PopupContainer>
            <Statistics>
                {props.type !== "expired" && (
                    <>
                        <Stat
                            data-for={inactiveTooltipId}
                            data-tip=""
                        >
                            {inactiveCardCount.toLocaleString("de-DE") || "0"}
                        </Stat>
                        <ReactTooltip id={inactiveTooltipId}>
                            <T
                                _str="inactive cards"
                                _tags="tooltip,subject,home"
                            />
                        </ReactTooltip>
                        <Divider>{" | "}</Divider>
                        <Stat
                            data-for={activeTooltipId}
                            data-tip=""
                        >
                            {inProgressCardCount.toLocaleString("de-DE") || "0"}
                        </Stat>
                        <ReactTooltip id={activeTooltipId}>
                            <T
                                _str="active cards"
                                _tags="tooltip,subject,home"
                            />
                        </ReactTooltip>
                        <Divider>{" | "}</Divider>
                        <Stat
                            data-for={learnedTooltipId}
                            data-tip=""
                        >
                            {inLongTermMemoryCardCount.toLocaleString("de-DE") || "0"}
                        </Stat>
                        <ReactTooltip id={learnedTooltipId}>
                            <T
                                _str="learned cards"
                                _tags="tooltip,subject,home"
                            />
                        </ReactTooltip>
                    </>
                )}
            </Statistics>
        </Container>
    );
};

export default UserSubjectsListEntry;
