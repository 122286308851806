// REACT
import React from "react";

// LIBRARIES
import styled from "styled-components";
import { useT } from "@transifex/react";

// TYPES
import { SubjectActions, SubjectData } from "p6m-subjects";

// COMPONENTS
import UserSubjectsListEntry from "./userSubjectListEntry/UserSubjectsListEntry";

export interface UserSubjectsListProps {
    type: "due" | "inactive" | "further" | "expired";
    subjects: SubjectData[];
    userId: string | undefined;
    subjectActions: SubjectActions;
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${(props) => props.theme.base.spacing};
`;

const Title = styled.h1`
    font-size: ${(props) => props.theme.base.titleSize};
    color: ${(props) => props.theme.colors.textOnBackground};
    font-weight: ${(props) => props.theme.base.weight};
    font-family: ${(props) => props.theme.base.fontFamily};
    padding: ${(props) => props.theme.base.spacing} 0 ${(props) => props.theme.base.spacingInContent}
        ${(props) => props.theme.base.spacingSmall};
    margin: 0;
`;

const UserSubjectsList: React.FC<UserSubjectsListProps> = (props) => {
    const t = useT();

    const dueCards_t = t("Contents with due cards", { _tags: "title,subjectList" });
    const inactiveCards_t = t("Contents with inactive cards", { _tags: "title,subjectList" });
    const otherContents_t = t("Other contents", { _tags: "title,subjectList" });
    const expiredContents_t = t("Tested contents (trial period expired)", { _tags: "title,subjectList" });

    let title = dueCards_t;
    switch (props.type) {
        case "inactive":
            title = inactiveCards_t;
            break;
        case "further":
            title = otherContents_t;
            break;
        case "expired":
            title = expiredContents_t;
            break;
        default:
            break;
    }

    return (
        <Container {...props}>
            <Title>{title}</Title>
            {props.subjects.map((subject) => {
                return (
                    <UserSubjectsListEntry
                        key={subject.subjectMetadata.subjectIdToOwner.id}
                        userId={props.userId}
                        type={props.type}
                        subject={subject}
                        subjectActions={props.subjectActions}
                    />
                );
            })}
        </Container>
    );
};

export default UserSubjectsList;
