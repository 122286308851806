// LIBRARIES
import React from "react";
import styled from "styled-components";

export interface SecondaryButtonProps {
    text: string;
    onClick: () => void;
    color?: string;
    className?: string;
}

const StyledButton = styled.button`
    text-transform: none;
    height: auto;
    border: 1px solid ${(props) => props.theme.colors.text};
    color: ${(props) => props.theme.colors.text};
    font-size: ${(props) => props.theme.base.bigSize};
    font-weight: 600;
    width: 100%;
    margin-top: 10px;
    font-family: ${(props) => props.theme.base.fontFamily};
    background: none;
    padding: ${(props) => props.theme.base.spacingSmall};
    border-radius: ${(props) => props.theme.base.borderRadius};
    cursor: pointer;
`;

const SecondaryButton: React.FC<SecondaryButtonProps> = (props) => {
    return <StyledButton {...props}>{props.text}</StyledButton>;
};

export default SecondaryButton;
