import React, { FunctionComponent, useMemo, useEffect, useState, useCallback, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { GameSubject } from "p6m-games";
import { ampli } from "../../../../ampli";
import { selectors, actions } from "../../../../redux/games/gamesSlice";
import { actions as userActions } from "../../../../redux/user/userSlice";
import { actions as goalsActions, selectors as goalsSelectors } from "../../../../redux/goals/goalsSlice";
import { getMaxTimeByScore } from "../../../../helpers/MatchGame";
import Component from "./WordMatchGame";
import { SubjectUnitCard } from "p6m-subjects";

const gameDefaults = {
    time: getMaxTimeByScore(1),
    cardsPerPage: 16,
};

export const WordMatchGame: FunctionComponent = () => {
    const { subjectId, gameType } = useParams<{ subjectId: string; gameType: "normal" | "explorer" }>();
    const subject = useSelector(selectors.matchGameSubjectById(subjectId)) || ({} as GameSubject);
    const { score: { match: score = 0 } = {} } = subject;
    const [page, setPage] = useState<number>(1);
    const [resultTime, setResultTime] = useState<number>(0);
    const [newRecord, setNewRecord] = useState<boolean>(false);
    const [gameStatus, setGameStatus] = useState<"in progress" | "lose" | "win">("in progress");

    const userGoalsData = useSelector(goalsSelectors.goals);
    const practiced50Cards = userGoalsData?.goals.practiced50Cards;

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        ampli.gamePlayedPxp({
            game: "match",
        });
    }, []);

    useEffect(() => {
        if (!userGoalsData) {
            dispatch(goalsActions.fetchGoals());
            history.push("/games/wordMatch");
            return;
        }
        if (practiced50Cards) return;
        history.push("/home");
    }, [practiced50Cards, userGoalsData, dispatch, history]);

    useEffect(() => {
        // starts when win
        if (gameStatus !== "win" || resultTime <= 0) return;
        if ((score === 0 && 0 < resultTime) || resultTime < score) {
            setNewRecord(true);
            dispatch(actions.fetchAddMatchGameScore({ score: resultTime, subject }));
        }
        if (gameType === "explorer") {
            dispatch(userActions.fetchAddUserStar());
        }
    }, [gameStatus, resultTime, score, subject, gameType, dispatch]);

    const onTimeEnd = useCallback(() => {
        setGameStatus("lose");
    }, []);

    const onStop = useCallback((time: number) => {
        if (time > 0) setResultTime(time);
    }, []);

    const onPlay = useCallback(() => {
        setNewRecord(false);
        setGameStatus("in progress");
        setPage(1);
    }, []);

    const onExit = useCallback(() => {
        history.push("/games/wordMatch");
    }, [history]);

    const onDone = useCallback((status: "in progress" | "win") => {
        if (status === "in progress") return setPage((page) => 1 + page);
        setGameStatus(status);
    }, []);

    const { cards, count } = useGetCards(gameStatus, page, gameDefaults.cardsPerPage, onDone);

    return (
        <Component
            gameStatus={gameStatus}
            cards={cards}
            pagination={{
                page,
                count: count,
                perPage: gameDefaults.cardsPerPage,
            }}
            timeline={{
                time: gameDefaults.time,
                stop: gameStatus !== "in progress",
                onTimeEnd,
                onStop,
            }}
            modals={{
                newRecord: newRecord,
                time: resultTime,
                onPlay,
                onExit,
            }}
        />
    );
};

type TUseGetCards = {
    count: number;
    cards: any[];
};

function useGetCards(
    gameStatus: "in progress" | "lose" | "win",
    page: number,
    cardsPerPage: number,
    onDone: (status: "in progress" | "win") => void
): TUseGetCards {
    const { cards, count } = useGetSubjectCards(gameStatus, page, cardsPerPage);
    const [selectedCards, setSelectedCards] = useState<string[]>([]);
    const [selectedCardId, setSelectedCardId] = useState<string>("");
    const lastPage: number = Math.floor(count / (cardsPerPage > count ? count : cardsPerPage));

    useEffect(() => {
        if (!cards.length || selectedCards.length < cards.length) return;
        onDone(page < lastPage ? "in progress" : "win");
        setSelectedCards([]);
    }, [selectedCards.length, cards.length, page, lastPage, onDone]);

    const onStart = useCallback((id: string) => {
        setSelectedCardId(id);
    }, []);

    const onDrop = useCallback(
        (dropId: string): boolean => {
            if (selectedCardId) setSelectedCardId("");
            if (!selectedCardId || selectedCardId === dropId) return false;
            if (selectedCardId.split("__")[0] !== dropId.split("__")[0]) return true;
            setSelectedCards((prevState) => [...prevState, dropId, selectedCardId]);
            return false;
        },
        [selectedCardId]
    );

    const result = useMemo(() => {
        if (selectedCards.length >= cards.length) return [];
        return cards.map((card, i: number) => {
            const id: string = `${card.id}__${i}`;
            const resolved: boolean = selectedCards.includes(id);
            const selected: boolean = id === selectedCardId;
            return {
                ...card,
                id,
                resolved,
                selected,
                onDrop: !resolved ? onDrop : undefined,
                onStart: !resolved ? onStart : undefined,
            };
        });
    }, [cards, selectedCards, selectedCardId, onDrop, onStart]);

    return {
        count,
        cards: result,
    };
}

type TGetSubjectCards = {
    count: number;
    cards: {
        id: string;
        title: string;
        question: boolean;
    }[];
};

function useGetSubjectCards(
    gameStatus: "in progress" | "lose" | "win",
    page: number,
    cardsPerPage: number
): TGetSubjectCards {
    const subjectCards: SubjectUnitCard[] | undefined = useSelector(selectors.matchGameCards);
    const { subjectId, gameType } = useParams<{ subjectId: string; gameType: "normal" | "explorer" }>();
    const dispatch = useDispatch();

    const prevGameStatusRef = useRef<string>(gameStatus);

    useEffect(() => {
        // get subject cards on first load if no cards in storage
        if (subjectCards) return;
        dispatch(actions.fetchMatchGameCards({ id: subjectId, type: gameType, loading: true }));
    }, [subjectCards, subjectId, gameType, dispatch]);

    useEffect(() => {
        // get subjects on start new game
        const prevStatus = prevGameStatusRef.current;
        if (gameStatus === "in progress" && prevStatus !== "in progress") {
            dispatch(actions.fetchMatchGameCards({ id: subjectId, type: gameType, loading: true }));
        }
        prevGameStatusRef.current = gameStatus;
    }, [gameStatus, subjectId, gameType, dispatch]);

    const result = useMemo(() => {
        const startIndex: number = (page - 1) * (cardsPerPage / 2);
        const endIndex: number = page * (cardsPerPage / 2);
        if (!subjectCards) return [];
        return subjectCards
            .slice(startIndex, endIndex) // slice for card
            .reduce((result: TGetSubjectCards["cards"], card: SubjectUnitCard) => {
                const {
                    cardContent: { question, answer },
                    cardIdToOwner: { id },
                } = card;
                result.push({
                    id,
                    title: question.replace(/(<([^>]+)>)/gi, " ").replace(/(\[{([^>]+)}\])/gi, " "),
                    question: true,
                });
                result.push({
                    id,
                    title: answer.replace(/(<([^>]+)>)/gi, " ").replace(/(\[{([^>]+)}\])/gi, " "),
                    question: false,
                });
                return result;
            }, [])
            .sort(() => Math.random() - 0.5);
    }, [subjectCards, page, cardsPerPage]);

    return {
        count: (subjectCards || []).length * 2,
        cards: result,
    };
}
