import styled from "styled-components";

interface BlankButtonProps {
    inline?: boolean;
}

export const BlankButton = styled.button<BlankButtonProps>`
    display: ${({ inline = false }) => (inline ? "inline-flex" : "flex")};
    ${({ inline = false }) => (inline ? "" : "width: 100%;")}
    padding: 0 ${({ theme }) => theme.base.spacing};
    min-height: 40px;
    justify-content: center;
    align-items: center;
    font-size: ${({ theme }) => theme.base.mediumSize};
    font-weight: ${({ theme }) => theme.base.weight};
    background-color: unset;
    border-radius: 50px;
    border: 0;
    color: ${({ theme }) => theme.colors.text};
    transition: background 0.25s, color 0.25s, border-color 0.25s;

    &:hover {
        cursor: pointer;
    }
`;

export const OutlinedButton = styled(BlankButton)`
    border: ${({ theme }) => `1px solid ${theme.colors.text}`};

    &:hover {
        border-color: ${({ theme }) => theme.buttons.primary.hoverBorderColor};
        color: ${({ theme }) => theme.buttons.secondary.textColor};
    }
`;

export const FilledButton = styled(BlankButton)`
    color: ${({ theme }) => theme.buttons.primary.textColor};
    font-weight: ${({ theme }) => theme.base.bigWeight};
    background-color: ${({ theme }) => theme.buttons.primary.backgroundColor};

    &:hover {
        background-color: ${({ theme }) => theme.buttons.primary.hoverBackgroundColor};
    }
`;
