import styled from "styled-components";

export const SubTitle = styled.h2`
    margin: 5px 0 0 0;
    font-size: ${({ theme: { base } }) => base.bigSize};
    color: ${({ theme: { colors } }) => colors.text};
    text-align: center;
    font-weight: normal;

    > span {
        color: ${({ theme: { colors } }) => colors.text};
    }
`;

export const ModalButton = styled.button<{
    outline?: boolean;
    disabled?: boolean;
    primary?: boolean;
    fullWidth?: boolean;
}>`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
    ${({ fullWidth = false }) => (fullWidth ? "width: 100%;" : "")}
    min-width: 100px;
    padding: ${({ theme: { base } }) => base.borderRadius + " " + base.spacingSmall};
    font-size: 18px;
    color: ${({ theme: { colors }, primary = false }) => (primary ? colors.backgroundContrast : colors.text)};
    text-align: center;
    border-radius: ${({ theme: { base } }) => base.borderRadius};
    border: ${({ outline = false, primary = false, theme: { colors } }) => {
        if (!outline) return "none";
        return `1px solid ${primary ? colors.primary : colors.backgroundMain}`;
    }};
    background: ${({ outline = false, primary = false, theme: { colors } }) => {
        if (outline) return "none";
        return primary ? colors.primary : colors.backgroundMain;
    }};
    cursor: ${({ disabled = false }) => (disabled ? "default" : "pointer")};
    ${({ disabled = false, theme: { base } }) =>
        disabled
            ? "opacity: 0.5;"
            : `
        &:hover {
            opacity: ${base.hoverOpacity};
        }
    `}
`;

export const ModalFooter = styled.div`
    padding: ${({ theme: { base } }) => base.spacingSmall};
`;

export const ExplanationText = styled.p`
    padding: ${({ theme: { base } }) => base.spacingSmall};
    color: ${({ theme: { colors } }) => colors.text};
`;
