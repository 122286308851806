// LIBRARIES
import React from "react";

// MODELS
import { SubjectData } from "p6m-subjects";
import { User, FamilyMember } from "p6m-user";

// COMPONENTS
import { CoverImage } from "p6-react-shared";
import {
    Container,
    Header,
    List,
    Subject,
    Title,
    SubjectTitle,
    Statistics,
    Total,
    LastPractice,
    StatisticEntry,
    NoPhasesContainer,
    NoPhasesIcon,
    NoPhasesText,
    NoPhasesHeader,
    NoPhasesBody,
    NoPhasesInfo,
    NoPhasesButton,
} from "./styles";

import { getSubjectCover } from "../../../../helpers/Subjects";
import { useT } from "@transifex/react";
import { toDDMMYY } from "../../../../helpers/timeFormat";
import PhaseSixIcon from "../../../../components/basic/phaseSixIcon/PhaseSixIcon";
import { getAddContentForChildUrl } from "../../../../helpers/Url";
export interface ReportsProps {
    user: User;
    member?: FamilyMember;
    subjects: SubjectData[] | null;
    selectSubject: (subjectId: string) => void;
}

const ReportTopics: React.FC<ReportsProps> = (props) => {
    const t = useT();

    const t_collection = t("Collection", { _tags: "title, reports" });
    const t_inactive = t("Inactive", { _tags: "title, reports" });
    const t_active = t("Active", { _tags: "title, reports" });
    const t_finished = t("Finished", { _tags: "title, reports" });
    const t_total = t("Total", { _tags: "title, reports" });
    const t_lastPractice = t("Last Practice", { _tags: "title, reports" });
    const t_Cards = t("Cards", { _tags: "reports,own cards,title,additional,text" });

    const noPhasesHeader_t = t("No content available yet!", {
        member: props.member && props.member.userId !== props.user.userDnsId ? props.member.name : props.user.firstName,
        _tags: "reports,message",
    });
    const noPhasesInfoTextSelect_t = t("You can select a content via the phase6 website.", {
        member: props.member?.name ?? "you",
        _tags: "reports,message",
    });
    const noPhasesInfoTextTrial_t = t("All contents can be tested for 7 days free of charge and without obligation.", {
        _tags: "reports,message",
    });
    const noPhasesButtonText_t = t("There is no data for Select content now.", { _tags: "reports,message" });

    const addContentForChildUrl = getAddContentForChildUrl(
        props.member?.userId,
        props.user.userDnsId,
        props.user.jossoSessionId
    );

    const renderNoPhasesPage = () => {
        return (
            <NoPhasesContainer>
                <NoPhasesIcon>
                    <PhaseSixIcon
                        name="documents"
                        size="medium"
                    />
                </NoPhasesIcon>
                <NoPhasesText>
                    <NoPhasesHeader>{noPhasesHeader_t}</NoPhasesHeader>
                    <NoPhasesBody>
                        <NoPhasesInfo>{noPhasesInfoTextSelect_t}</NoPhasesInfo>
                        <NoPhasesInfo>{noPhasesInfoTextTrial_t}</NoPhasesInfo>
                    </NoPhasesBody>
                    <NoPhasesButton
                        href={addContentForChildUrl}
                        target="_blank"
                    >
                        {noPhasesButtonText_t}
                    </NoPhasesButton>
                </NoPhasesText>
            </NoPhasesContainer>
        );
    };

    return (
        <Container>
            {props.subjects && props.subjects.length > 0 ? (
                <Header>
                    <Title>
                        <SubjectTitle>{t_collection}</SubjectTitle>
                    </Title>
                    <Statistics>
                        <StatisticEntry>{t_inactive}</StatisticEntry>
                        <StatisticEntry>{t_active}</StatisticEntry>
                        <StatisticEntry>{t_finished}</StatisticEntry>
                        <Total>{t_total}</Total>
                    </Statistics>
                    <LastPractice>{t_lastPractice}</LastPractice>
                </Header>
            ) : null}
            <List>
                {props.subjects &&
                    (props.subjects.length > 0
                        ? props.subjects.map((subject) => {
                              const image = getSubjectCover(subject);
                              const cardsInactive = subject.groupedCardCount?.cardCounts.LEARN_NEW.cardCount || 0;
                              const cardsActive =
                                  subject.groupedCardCount?.cardCounts.ACTIVE_NOT_LEARNED.cardCount || 0;
                              const cardsFinished = subject.groupedCardCount?.cardCounts.LAST_PHASE.cardCount || 0;
                              const cardsTotal = cardsInactive + cardsActive + cardsFinished;
                              return (
                                  <Subject
                                      key={"subject_" + subject.subjectMetadata.subjectIdToOwner.id}
                                      onClick={() => props.selectSubject(subject.subjectMetadata.subjectIdToOwner.id)}
                                  >
                                      <Title>
                                          <CoverImage
                                              imageId={image}
                                              size={"small"}
                                          />
                                          <SubjectTitle>
                                              {subject.subjectContent.name}
                                              {subject.subjectMetadata.isSharedSubjectOfAdmin ? " " + t_Cards : ""}
                                          </SubjectTitle>
                                      </Title>
                                      <Statistics>
                                          <StatisticEntry>{cardsInactive.toLocaleString("de-DE")}</StatisticEntry>
                                          <StatisticEntry>{cardsActive.toLocaleString("de-DE")}</StatisticEntry>
                                          <StatisticEntry>{cardsFinished.toLocaleString("de-DE")}</StatisticEntry>
                                          <Total>{cardsTotal.toLocaleString("de-DE")}</Total>
                                      </Statistics>
                                      <LastPractice>
                                          {subject.subjectMetadata && subject.subjectMetadata.lastPracticedDate
                                              ? toDDMMYY(subject.subjectMetadata.lastPracticedDate)
                                              : "-"}
                                      </LastPractice>
                                  </Subject>
                              );
                          })
                        : renderNoPhasesPage())}
            </List>
        </Container>
    );
};

export default ReportTopics;
