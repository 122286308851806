import React, { FunctionComponent, memo } from "react";
import { useT } from "@transifex/react";
import { AddCardsMenu, Props as MenuProps } from "../../../complex/addCards/addCardsMenu";

export type Props = Omit<MenuProps, "dropdownTitle" | "inputTitle"> & {
    deleteCardsCount: number;
};

const UnitsMenu: FunctionComponent<Props> = (props) => {
    const { deleteCardsCount, items = [], selected, ...restProps } = props;
    const t = useT();

    const dropdownTitle = t("units(s)", {});
    const inputTitle = t("Unit", {});
    const t_deleteItems = t("{deleted} card(s) will be deleted. This action cannot be undone!", {
        deleted: deleteCardsCount,
    });

    return (
        <AddCardsMenu
            dropdownTitle={dropdownTitle}
            inputTitle={inputTitle}
            alert={deleteCardsCount ? t_deleteItems : undefined}
            items={items}
            selected={selected}
            closeOnSave
            {...restProps}
        />
    );
};

export default memo(UnitsMenu);
