import styled from "styled-components";
import PrimaryButton from "../../../basic/primaryButton/PrimaryButton";
import { Col } from "../../../basic/grid";

export const ButtonCol = styled(Col)`
    padding-top: 0;
`;

export const StyledPrimaryButton = styled(PrimaryButton)<{ disabled?: boolean }>`
    width: 300px;
    padding: 5px;
    margin: 0;
    font-weight: normal;
    text-transform: uppercase;
    font-size: ${({ theme: { base } }) => base.bigSize};

    ${({ disabled = false }) => (disabled ? "opacity: 0.5;" : "")}
`;
