// REACT
import React, {
    memo,
    forwardRef,
    ChangeEventHandler,
    KeyboardEventHandler,
    useContext,
    useState,
    MouseEventHandler,
    useMemo,
} from "react";

import { useT, T } from "@transifex/react";

// LIBRARIES
import DOMPurify from "dompurify";

// TYPES
import { State } from "p6m-learning";

// COMPONENTS
import { Row, Col } from "../../../basic/grid";
import Buttons, { Props as ButtonsProps } from "../Buttons/Buttons";
import Tooltip from "../../../basic/tooltip/Tooltip";

// UTILS
import { LanguageCodes, LanguagesContext } from "../../../../context/languages";
import { PracticeContext } from "../../../../context/practice";
import ImgArrows from "../../../../assets/img/icon-arrows-noinput.svg";

// STYLED COMPONENTS
import {
    Textarea,
    CardResultWrapper,
    ActionsWrapper,
    ActionIcon,
    ResultBox,
    ResultIcon,
    ClearButton,
    ClearButtonContainer,
    TextareaWrapper,
    TextareaFooter,
    Flag,
    charsMenuCss,
    CharSelect,
    charChildCss,
    Colors,
    ArrowsInfo,
} from "./styles";

export type Props = {
    value?: string;
    prevValue?: string;
    state: State;
    isPractice: boolean;
    title: string;
    disabledNoInput: boolean;
    lang?: string;
    showArrows?: boolean;
    typingIsActive?: boolean;
    textAreaChange: ChangeEventHandler<HTMLTextAreaElement>;
    textAreaKeyDown: KeyboardEventHandler<HTMLTextAreaElement>;
    toggleTyping: () => void;
    onCharSelect: (char: string) => void;
    getTextareaRef: (node: HTMLTextAreaElement | null) => void;
} & ButtonsProps;

const KeyButton = ({
    enabledInput,
    typingIsActive,
    onClick,
}: {
    enabledInput?: boolean;
    typingIsActive?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement>;
}) => {
    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    let content = <T _str="Deactivated by parent account" />;
    if (enabledInput) {
        content = typingIsActive ? <T _str="Disable keyboard input." /> : <T _str="Enable keyboard input." />;
    }

    const handleShowTooltip = (showTooltip: boolean) => {
        // The appearance/popup of the tooltip shall be delayed by 1 second,
        // as opposed to the hiding of the tooltip, which shall happen immediately.
        const delayInMilliseconds = showTooltip ? 1000 : 0;
        setTimeout(() => {
            setShowTooltip(showTooltip);
        }, delayInMilliseconds);
    };

    return (
        <ClearButtonContainer xs="auto">
            <Tooltip
                position="top"
                content={content}
                showTooltip={showTooltip}
                onMouseOverCapture={() => handleShowTooltip(true)}
                onMouseLeave={() => handleShowTooltip(false)}
            >
                <ClearButton
                    onClick={enabledInput ? onClick : undefined}
                    tabIndex={enabledInput ? 3 : undefined}
                    data-qa="toggle-typing"
                >
                    <ActionIcon name="keys" />
                </ClearButton>
            </Tooltip>
        </ClearButtonContainer>
    );
};

const CardResult = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const {
        value,
        prevValue,
        state,
        isPractice,
        title,
        lang,
        showArrows,
        buttonsIndex,
        isLoading,
        typingIsActive,
        disabledNoInput,
        textAreaChange,
        textAreaKeyDown,
        toggleTyping,
        onCharSelect,
        callback,
        getTextareaRef,
    } = props;
    const { getFlagSource } = useContext(LanguagesContext);
    const practiceContext = useContext(PracticeContext);
    const t = useT();

    const resultBoxType: Colors = (
        {
            show: "primary",
            default: "primary",
            success: "success",
            wrong: "danger",
            basic: "neutral",
            dontKnow: "danger",
        } as Record<Props["state"], Colors>
    )[state];

    const t_typeAnswer = t("Type your answer here", {});

    const sanitizedTitle = DOMPurify.sanitize(`${title}`, { USE_PROFILES: { html: true } });
    const placeholder = !isPractice || ["wrong", "dontKnow"].includes(state) ? sanitizedTitle : t_typeAnswer;
    const showText = prevValue || state === "success" ? prevValue || sanitizedTitle : undefined;

    const placeholderWithSpecialChars = useMemo(() => {
        const elementWithText = document.createElement("span");
        elementWithText.innerHTML = placeholder;
        return elementWithText.textContent || "";
    }, [placeholder]);

    return (
        <CardResultWrapper ref={ref}>
            {![1, 3].includes(buttonsIndex) && (
                <ResultBox
                    $type={resultBoxType}
                    key={state}
                    state={state}
                >
                    {state === "success" ? (
                        <ResultIcon
                            name="correct"
                            size={"medium"}
                        />
                    ) : ["wrong", "dontKnow"].includes(state) ? (
                        <ResultIcon
                            name="wrong"
                            size={"medium"}
                        />
                    ) : (
                        <ResultIcon
                            name="help"
                            size={"medium"}
                        />
                    )}
                    {!!showText && <div dangerouslySetInnerHTML={{ __html: showText }} />}
                </ResultBox>
            )}

            {[1, 2, 3, 8, 9].includes(buttonsIndex) && (
                <TextareaWrapper>
                    <Textarea
                        ref={getTextareaRef}
                        data-qa="practice-input"
                        value={value}
                        placeholder={placeholderWithSpecialChars}
                        onChange={textAreaChange}
                        tabIndex={1}
                        onKeyDown={textAreaKeyDown}
                        spellCheck={false}
                        autoComplete="off"
                        autoCorrect="off"
                    />
                    <TextareaFooter>
                        {!!lang && <Flag src={getFlagSource(lang as LanguageCodes)} />}
                        <CharSelect
                            onCharSelect={onCharSelect}
                            isOpen={practiceContext.isCharMenuOpen}
                            lang={lang}
                            position={["bottom", "right"]}
                            menuWrapperProps={{
                                style: charsMenuCss,
                            }}
                            childWrapperProps={{
                                style: charChildCss as any,
                            }}
                        />
                    </TextareaFooter>
                </TextareaWrapper>
            )}
            <ActionsWrapper>
                <Col>
                    <Row>
                        {state === "default" && (
                            <KeyButton
                                enabledInput={!disabledNoInput}
                                typingIsActive={typingIsActive}
                                onClick={toggleTyping}
                            />
                        )}
                    </Row>
                    {state === "show" && !!showArrows && (
                        <Row vertical="center">
                            <Col>
                                <ArrowsInfo>
                                    <h3>
                                        <T _str="You can use the arrow keys from your keyboard." />
                                    </h3>
                                    <br />
                                    <img
                                        src={ImgArrows}
                                        alt="arrows"
                                    />
                                </ArrowsInfo>
                            </Col>
                        </Row>
                    )}
                    <Buttons
                        buttonsIndex={buttonsIndex}
                        callback={callback}
                        isLoading={isLoading}
                    />
                </Col>
            </ActionsWrapper>
        </CardResultWrapper>
    );
});

export default memo(CardResult);
