import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;
export const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: ${(props) => props.theme.base.spacing};
    border-bottom: 1px solid ${(props) => props.theme.colors.textLight};
`;
export const Activity = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    overflow-y: scroll;
`;
export const Limit = styled.div`
    margin-top: ${(props) => props.theme.base.spacing};
    padding: ${(props) => props.theme.base.spacing};
    max-width: 400px;
`;
export const DateHeader = styled.span`
    flex: 2;
    font-weight: bold;
    color: ${(props) => props.theme.colors.text};
`;
export const DueHeader = styled.span`
    flex: 1;
    font-weight: bold;
    color: ${(props) => props.theme.colors.primary};
`;
export const LearnedHeader = styled.span`
    flex: 1;
    font-weight: bold;
    color: ${(props) => props.theme.colors.text};
`;
export const Action = styled.span`
    flex: 1;
    color: ${(props) => props.theme.colors.text};
`;
export const Entry = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    border-bottom: 1px solid ${(props) => props.theme.colors.textLight};
    cursor: pointer;
`;
export const Statistics = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: ${(props) => props.theme.base.spacing};
`;
export const DateText = styled.span`
    flex: 2;
    color: ${(props) => props.theme.colors.text};
`;
export const DueText = styled.span`
    flex: 1;
    color: ${(props) => props.theme.colors.primary};
`;
export const LearnedText = styled.span`
    flex: 1;
    color: ${(props) => props.theme.colors.text};
`;
export const Details = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    padding: ${(props) => props.theme.base.spacing};
    padding-top: 0;
`;
export const Detail = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    padding-bottom: ${(props) => props.theme.base.spacingInContent};
`;
export const Label = styled.span`
    flex: 3;
    color: ${(props) => props.theme.colors.text};
`;
export const Value = styled.span`
    flex: 2;
    color: ${(props) => props.theme.colors.text};
`;
export const NoDataText = styled.span`
    text-align: center;
    color: ${(props) => props.theme.colors.text};
`;
