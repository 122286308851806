// REACT
import React, { FunctionComponent, useState, useCallback } from "react";

// COMPONENTS
import Component, { Props as ComponentProps } from "./AddCardsMenu";

export type Props = {
    closeOnSave?: boolean;
    selectFirstElement?: boolean;
} & Omit<ComponentProps, "edit" | "onEdit">;

export const AddCardsMenu: FunctionComponent<Props> = (props) => {
    const { closeOnSave = false, onCloseEdit, onSave, ...restProps } = props;
    const [edit, setEdit] = useState<boolean>(false);

    const toggleEdit = useCallback(() => {
        setEdit((prevState: boolean) => !prevState);
    }, []);

    const onCloseEditCallback = useCallback(() => {
        onCloseEdit();
        toggleEdit();
    }, [onCloseEdit, toggleEdit]);

    const onSaveCallback = useCallback(
        (...saveProps: Parameters<ComponentProps["onSave"]>) => {
            onSave(...saveProps);
            if (closeOnSave) toggleEdit();
        },
        [closeOnSave, onSave, toggleEdit]
    );

    return (
        <Component
            edit={edit}
            onSave={onSaveCallback}
            onEdit={toggleEdit}
            onCloseEdit={onCloseEditCallback}
            onToggle={onCloseEdit}
            {...restProps}
        />
    );
};
