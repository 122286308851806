// REACT
import React, { FunctionComponent, memo } from "react";

// COMPONENTS
import { Row, Col } from "../../../basic/grid";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";
import { DropdownSelection } from "../dropdownSelection";
import EditDropdown from "../editDropdown/EditDropdown";

// STYLED COMPONENTS
import { EditIcon } from "./styles";

// TYPES
import { ItemProps, EditItemProps } from "p6m-dropdown";

export type Props = {
    dropdownTitle: string;
    inputTitle: string;
    items: ItemProps[];
    selected?: string | string[];
    disableEdit?: boolean;
    edit: boolean;
    onSelect: (subject: ItemProps) => void;
    onEdit: () => void;
    alert?: EditItemProps["alert"];
    editItems: EditItemProps["items"];
    onSave: EditItemProps["onSave"];
    onCloseEdit: EditItemProps["onClose"];
    onDelete?: EditItemProps["onDelete"];
    onToggle?: (state: boolean) => void;
};

const AddCardsMenu: FunctionComponent<Props> = (props) => {
    const {
        dropdownTitle,
        inputTitle,
        items,
        selected,
        disableEdit = false,
        edit,
        onSelect,
        onEdit,
        onCloseEdit,
        editItems = [],
        onSave,
        alert,
        onDelete,
        onToggle,
    } = props;

    const dropdownProps = !edit
        ? {
              items: items,
              dropdownHeader: (
                  <Row horizontal="between">
                      <Col>
                          {items.length} {dropdownTitle}
                      </Col>
                      {!disableEdit && (
                          <Col xs="auto">
                              <EditIcon onClick={() => onEdit()}>
                                  <PhaseSixIcon name="edit-avatar" />
                              </EditIcon>
                          </Col>
                      )}
                  </Row>
              ),
              onSelect,
              closeOnSelect: true,
          }
        : {
              customDropdown: (
                  <EditDropdown
                      items={editItems}
                      onClose={onCloseEdit}
                      onSave={onSave}
                      alert={alert}
                      onDelete={onDelete}
                  />
              ),
          };

    return (
        <DropdownSelection
            fullWidth
            oneLine
            label={inputTitle}
            selected={selected}
            onToggle={onToggle}
            {...dropdownProps}
        />
    );
};

export default memo(AddCardsMenu);
