import styled, { css } from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-content: flex-start;
    align-items: center;
    justify-content: flex-start;
    margin-right: ${(props) => props.theme.base.spacingSmall};
    padding: ${(props) => props.theme.base.spacingSmall};
    background-color: ${({ theme: { colors } }) => colors.backgroundContrast};
    font-size: ${({ theme: { base } }) => base.mediumSize};
    color: ${({ theme: { colors } }) => colors.text};
    height: 44px;
    flex: 1;
    border-radius: 22px;
`;

export const RemoveIcon = styled.div`
    vertical-align: middle;
    margin-left: 5px;
    cursor: pointer;
`;

export const Item = styled.div<{ removable?: boolean }>`
    display: inherit;
    color: ${({ theme: { colors } }) => colors.primary};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    ${({ removable = false }) =>
        removable
            ? `
        &:hover {
            text-decoration: underline;
        }
    `
            : ""}
`;

const oneLineCss = css`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const Items = styled.div<{ oneLine?: boolean }>`
    width: 100%;
    overflow: hidden;
    ${({ oneLine = false }) => (oneLine ? oneLineCss : "")}

    ${Item} + ${Item} {
        margin-left: ${({ theme: { base } }) => base.spacingTiny};
    }
`;

export const Label = styled.div`
    & + ${Items} {
        margin-left: ${({ theme: { base } }) => base.spacingSmall};
    }
`;

export const Chevron = styled.div`
    margin-left: auto;
    height: 25px;
    display: flex;
    align-items: center;
`;
