export const getTestGeneratorUrl = (jossoSessionID?: string, articleId?: number, iframeMode?: boolean): string => {
    let urlParameters = [];
    if (typeof jossoSessionID != "undefined") {
        urlParameters.push("JossoSessionID=" + jossoSessionID);
    }
    if (typeof articleId != "undefined") {
        urlParameters.push("articleId=" + articleId);
    }
    if (typeof iframeMode != "undefined") {
        urlParameters.push("iframeMode=true");
    }

    return (
        process.env.REACT_APP_WEBSITE_FULL_DOMAIN +
        "classic/service/user/teacher/test-generator/?" +
        urlParameters.join("&")
    );
};

export const getMeinKontoUrl = (jossoSessionId?: string): string => {
    return `${process.env.REACT_APP_WEBSITE_FULL_DOMAIN}classic/service/user/login/?JossoSessionID=${jossoSessionId}`;
};

export const getSupportUrl = (): string => {
    return "https://help.phase-6.de/";
};

export const getSupportChatUrl = (): string => {
    return "https://chat.phase-6.de/livechat?mode=popout";
};

export const getImpressumUrl = (): string => {
    return "http://www.phase-6.de/Imprint/index.html";
};

export const getBuyPremiumUrl = (): string => {
    return process.env.REACT_APP_WEBSITE_FULL_DOMAIN + "shop/basket.html?ArticleID=3284";
};

export const getShareAppUrl = (): string => {
    return process.env.REACT_APP_WEBSITE_FULL_DOMAIN + "classic/recommend/";
};

export const getAddSubjectUrl = (jossoId: string): string => {
    return process.env.REACT_APP_WEBSITE_FULL_DOMAIN + "classic/search/login/?JossoSessionID=" + jossoId;
};

export const getUrlForIncludedContentSelection = (jossoId: string): string => {
    return process.env.REACT_APP_WEBSITE_FULL_DOMAIN + "classic/search/consume-content/?JossoSessionID=" + jossoId;
};

export const getGDPRWindowUrl = (jossoId?: string) => {
    if (!jossoId) return "";
    return process.env.REACT_APP_WEBSITE_FULL_DOMAIN + "pxp/popup/gdpr-reprofile/login/?JossoSessionID=" + jossoId;
};

export const getAgreementUrl = (): string => {
    return process.env.REACT_APP_WEBSITE_FULL_DOMAIN + "shop/agb.html";
};

export const getInviteChildUrl = (jossoId: string): string => {
    return (
        process.env.REACT_APP_WEBSITE_FULL_DOMAIN +
        "/classic/service/user/parent/?JossoSessionID=" +
        jossoId +
        "&#collapseAddChildForm"
    );
};

export const getAddContentForChildUrl = (memberId?: string, userId?: string, jossoId?: string): string => {
    if (!memberId || !userId || !jossoId) return "";
    return (
        process.env.REACT_APP_WEBSITE_FULL_DOMAIN +
        "/pxp/popup/child-demo-content-add/?ChildContactID=" +
        memberId +
        "&ParentContactID=" +
        userId +
        "&JossoSessionID=" +
        jossoId
    );
};

export function pushSubdomain(url: URL, subdomain: string): URL {
    const hostname = url.hostname;
    const parts = hostname.split(".");
    const index = parts[0] === "www" ? 1 : 0;
    parts.splice(index, 0, subdomain);
    url.hostname = parts.join(".");
    return url;
}

export function getTeacherUrl(jossoId: string): URL {
    const phase6Url = process.env.REACT_APP_WEBSITE_FULL_DOMAIN || "https://www.phase-6.de/";
    return new URL(`/pxp/exit-intent/teacher/login/?JossoSessionID=${jossoId}`, phase6Url);
}

export function getParentUrl(jossoId: string): URL {
    const phase6Url = process.env.REACT_APP_WEBSITE_FULL_DOMAIN || "https://www.phase-6.de/";
    return new URL(`/pxp/exit-intent/parent/login/?JossoSessionID=${jossoId}`, phase6Url);
}

export function getLearningSystemInfoUrl(): URL {
    const phase6Url = process.env.REACT_APP_WEBSITE_FULL_DOMAIN || "https://www.phase-6.de/";
    return new URL(`/presse/classic-wissenschaft/`, phase6Url);
}

export function getAppDownloadUrl(jossoId: string): URL {
    //https://dns.phase-6.de/dns-support/Language/?LanguageID=1&CodeFilter=user.exit-intent.email
    const phase6Url = process.env.REACT_APP_WEBSITE_FULL_DOMAIN || "https://www.phase-6.de/";
    return new URL(`/pxp/exit-intent/send-email/login/?JossoSessionID=${jossoId}`, phase6Url);
}

export function getDataProtectionUrl() {
    return "https://www.phase-6.de/datenschutz_text.html";
}

export function getStrictImprintUrl() {
    return "https://www.phase-6.de/impressum_text.html";
}
