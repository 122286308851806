// REACT
import React, { FunctionComponent, memo, ReactNode } from "react";

// COMPONENTS
import Popup from "../../../basic/popup/Popup";

type Props = {
    input: ReactNode;
    dropdown: ReactNode;
    fullWidth?: boolean;
    onToggle: (state: boolean) => void;
};

const Dropdown: FunctionComponent<Props> = (props) => {
    const { input, dropdown, fullWidth = false, onToggle } = props;

    return (
        <Popup
            position={["bottom", "left"]}
            content={dropdown}
            fullWidth={fullWidth}
            onToggle={onToggle}
            minChildWidth
            arrow
            hasSetWidth
        >
            {input}
        </Popup>
    );
};

export default memo(Dropdown);
