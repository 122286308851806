import styled from "styled-components";

export const EditIcon = styled.div`
    cursor: pointer;
    transition: color ${({ theme: { base } }) => base.transition}s;

    &:hover {
        color: ${({ theme: { colors } }) => colors.primary};
    }
`;
