// REACT
import React, { FunctionComponent, useCallback, memo } from "react";

// STYLED COMPONENTS
import { StyledPhaseSixIcon } from "../../../basic/dropdownSelection/styles";
import { ComponentWrapper, InputWrapper, Error, Input, IconWrapper } from "./styles";

// TYPES
import { EditDropdownInputProps } from "p6m-dropdown";

const EditDropdownInput: FunctionComponent<EditDropdownInputProps> = (props) => {
    const {
        value,
        notDeletable = false,
        notEditable = false,
        onChange,
        onCreate,
        onDelete,
        error,
        onSubmit,
        deleted = false,
    } = props;

    const iconCallback = !!onCreate ? onCreate : onDelete;

    const onKeyupCallback = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.keyCode !== 13 || !onSubmit) return;
            onSubmit();
        },
        [onSubmit]
    );

    return (
        <ComponentWrapper as={!!onCreate ? "div" : "li"}>
            <IconWrapper
                onClick={() => !!iconCallback && iconCallback()}
                disabled={notDeletable}
                $deleted={deleted}
            >
                <StyledPhaseSixIcon name={!!onCreate ? "add" : "trash"} />
            </IconWrapper>
            <InputWrapper>
                <Input
                    defaultValue={value || ""}
                    onChange={({ target: { value } }) => !!onChange && onChange(value.trim())}
                    onKeyUp={onKeyupCallback}
                    disabled={notEditable || deleted}
                    minLength={process.env.REACT_APP_INPUT_CHARS_MIN ? +process.env.REACT_APP_INPUT_CHARS_MIN : 1}
                    maxLength={process.env.REACT_APP_INPUT_CHARS_MAX ? +process.env.REACT_APP_INPUT_CHARS_MAX : 254}
                    $deleted={deleted}
                />
                {!!error && <Error>{error}</Error>}
            </InputWrapper>
        </ComponentWrapper>
    );
};

export default memo(EditDropdownInput);
