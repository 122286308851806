import React from "react";
import styled from "styled-components";
import { T, useT } from "@transifex/react";

//COMPONENTS
import { DefaultHeader } from "../../basic/modal/DefaultHeader";

//TYPES
import { SubjectData } from "p6m-subjects";
import { GlobalModalProps } from "../../../helpers/Modal";

interface ResetSubjectCardsModal extends GlobalModalProps {
    subject: SubjectData;
    reset: () => void;
}

const Background = styled.div`
    height: 100%;
    width: 100%;
    padding: 0;
    border-radius: ${({ theme: { base } }) => base.borderRadius};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    overflow: hidden;
`;
const Content = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: ${(props) => props.theme.base.spacing};
    background: ${(props) => props.theme.colors.backgroundMain};
`;
const ButtonsContainer = styled.div`
    width: 100%;
    padding: ${(props) => props.theme.base.spacing};
    background: ${(props) => props.theme.colors.backgroundMain};
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`;
const Button = styled.span<{ highlighted?: boolean }>`
    margin-left: ${(props) => props.theme.base.spacing};
    padding: ${(props) => props.theme.base.spacingSmall};
    border-radius: ${(props) => props.theme.base.borderRadiusSmall};
    background: ${(props) => (props.highlighted ? props.theme.colors.primary : props.theme.colors.backgroundMain)};
    color: ${(props) => (props.highlighted ? props.theme.colors.backgroundContrast : props.theme.colors.text)};
    border: ${(props) => (props.highlighted ? "" : `1px solid ${props.theme.colors.text}`)};
    text-transform: uppercase;
    text-align: center;
    font-size: ${({ theme: { base } }) => base.bigSize};
    cursor: pointer;
`;
const Description = styled.p`
    color: ${(props) => props.theme.colors.text};
`;
const SubjectName = styled.span`
    color: ${(props) => props.theme.colors.primary};
    word-break: break-word;
`;

const ResetSubjectCardsModal: React.FC<ResetSubjectCardsModal> = (props) => {
    const t = useT();

    const assign_t = t("Reset learning progress", { _tags: "subject,modal,title" });
    const cancel_t = t("Cancel", { _tags: "subject,modal,button" });
    const resetContent_t = t("Reset content", { _tags: "subject,modal,button" });

    const subjectName = <SubjectName>{props.subject.subjectContent.name}</SubjectName>;

    return (
        <Background>
            <DefaultHeader
                title={assign_t}
                close={props.close}
            />
            <Content>
                <Description>
                    <T
                        _str="You are about to reset {subjectName}."
                        subjectName={subjectName}
                    />{" "}
                    <T _str="Your cards will be moved back to library. This step can not be undone." />
                </Description>
            </Content>
            <ButtonsContainer>
                <Button onClick={props.close}>{cancel_t}</Button>
                <Button
                    onClick={props.reset}
                    highlighted
                >
                    {resetContent_t}
                </Button>
            </ButtonsContainer>
        </Background>
    );
};

export default ResetSubjectCardsModal;
