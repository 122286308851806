import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
`;
export const InformationBox = styled.div`
    margin-top: calc(25px + ${(props) => props.theme.base.spacing});
    margin-right: calc(25px + ${(props) => props.theme.base.spacing});
    padding: ${(props) => props.theme.base.spacing};
    border-radius: ${(props) => props.theme.base.borderRadiusBig};
    box-shadow: 0 0 10px ${(props) => props.theme.colors.text};
    background: ${(props) => props.theme.colors.backgroundContrast};
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
`;
export const InfoImageContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const InfoImage = styled.img`
    height: auto;
    width: 230px;
`;
export const InfoTextContainer = styled.div`
    flex: 2;
    display: flex;
    align-items: center;
`;
export const InfoText = styled.p`
    text-align: center;
    font-size: ${(props) => props.theme.base.bigSize};
    color: ${(props) => props.theme.colors.text};
`;
export const InfoHighlight = styled.span`
    color: ${(props) => props.theme.colors.primary};
`;
export const List = styled.div`
    margin-top: 25px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    overflow-y: scroll;
`;
export const Entry = styled.div<{ header?: boolean }>`
    padding: ${(props) => props.theme.base.spacing} 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.colors.text};
    border-top: ${(props) => (props.header ? 0 : "1px")} solid ${(props) => props.theme.colors.textLight};
    > span {
        font-weight: ${(props) => (props.header ? "bold" : "normal")};
    }
`;
export const Question = styled.div`
    flex: 1;
`;
export const CorrectAnswer = styled.div`
    flex: 1;
`;
export const GivenAnswer = styled.div`
    flex: 1;
`;
