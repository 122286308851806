import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
`;
export const InformationBox = styled.div<{ selected: boolean }>`
    margin-top: calc(25px + ${(props) => props.theme.base.spacing});
    margin-right: calc(25px + ${(props) => props.theme.base.spacing});
    background: ${(props) => props.theme.colors.backgroundContrast};
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
`;
export const InfoImageContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const InfoImage = styled.img`
    height: auto;
    width: 230px;
    margin-bottom: -40px;
    z-index: 1;
`;
export const InfoTextContainer = styled.div<{ selected: boolean }>`
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 60px ${(props) => props.theme.base.spacing} ${(props) => props.theme.base.spacing};
    color: ${(props) => (props.selected ? props.theme.colors.backgroundContrast : props.theme.colors.text)};
    background: ${(props) => (props.selected ? props.theme.colors.primary : "transparent")};
    border-top-left-radius: ${(props) => props.theme.base.borderRadiusBig};
    border-top-right-radius: ${(props) => props.theme.base.borderRadiusBig};
    border-right: ${(props) => (props.selected ? "none" : "1px solid " + props.theme.colors.text)};
    border-left: ${(props) => (props.selected ? "none" : "1px solid " + props.theme.colors.text)};
    border-top: ${(props) => (props.selected ? "none" : "1px solid " + props.theme.colors.text)};
`;
export const ModalTextContainer = styled.div`
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 60px ${(props) => props.theme.base.spacing} ${(props) => props.theme.base.spacing};
    color: ${(props) => props.theme.colors.text};
`;
export const InfoText = styled.p`
    text-align: center;
    font-size: ${(props) => props.theme.base.titleSize};
    font-weight: bold;
`;
export const InfoSubtext = styled.p`
    text-align: center;
    font-size: ${(props) => props.theme.base.bigSize};
    margin-top: 40px;
`;
export const InfoLinkContainer = styled.div<{ selected: boolean }>`
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${(props) => props.theme.base.spacing};
    color: ${(props) => (props.selected ? props.theme.colors.backgroundContrast : props.theme.colors.text)};
    background: ${(props) => (props.selected ? props.theme.colors.primary : "transparent")};
    // background: ${(props) => (props.selected ? props.theme.colors.primary : "transparent")};
    border-bottom-left-radius: ${(props) => props.theme.base.borderRadiusBig};
    border-bottom-right-radius: ${(props) => props.theme.base.borderRadiusBig};
    border-right: ${(props) => (props.selected ? "none" : "1px solid " + props.theme.colors.text)};
    border-left: ${(props) => (props.selected ? "none" : "1px solid " + props.theme.colors.text)};
    border-bottom: ${(props) => (props.selected ? "none" : "1px solid " + props.theme.colors.text)};
`;
export const InfoLink = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;
export const InfoLinkText = styled.span`
    text-align: center;
    font-size: ${(props) => props.theme.base.titleSize};
    font-weight: bold;
`;
export const InfoLinkTextSmall = styled.span`
    text-align: center;
    font-size: ${(props) => props.theme.base.bigSize};
`;
export const SelectedInformationBox = styled.div`
    margin-top: calc(${(props) => props.theme.base.spacing});
    margin-right: calc(25px + ${(props) => props.theme.base.spacing});
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
`;
export const SelectedInfoImageContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
`;
export const SelectedInfoImage = styled.img`
    height: auto;
    width: calc(230px + (2 * ${(props) => props.theme.base.spacing}));
`;
export const HighlightArea = styled.div`
    padding: ${(props) => props.theme.base.spacing};
    padding-top: 50px;
    border-radius: ${(props) => props.theme.base.borderRadiusBig};
    box-shadow: 0 0 10px ${(props) => props.theme.colors.text};
    background: ${(props) => props.theme.colors.primary};
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    position: relative;
    top: -50px;
`;
export const SelectedInfoTextContainer = styled.div`
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: stretch;
`;
export const SelectedInfoText = styled.p`
    text-align: center;
    font-size: ${(props) => props.theme.base.bigSize};
    color: ${(props) => props.theme.colors.backgroundContrast};
`;
export const SelectedMessageContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const SelectedMessage = styled.span`
    text-align: center;
    font-size: ${(props) => props.theme.base.bigSize};
    color: ${(props) => props.theme.colors.backgroundContrast};
`;
export const Phases = styled.div`
    padding-top: 25px;
    flex: 3;
    display: flex;
    overflow-x: auto;
`;
export const NoPhasesText = styled.p`
    padding: ${(props) => props.theme.base.spacing};
    font-size: ${(props) => props.theme.base.mediumSize};
    color: ${(props) => props.theme.colors.text};
`;
export const Phase = styled.div`
    height: 100%;
    min-width: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
export const BarArea = styled.div<{ selected: boolean }>`
    margin: ${(props) => props.theme.base.spacing};
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    background: ${(props) => props.theme.colors.backgroundMain};
    border-radius: ${(props) => props.theme.base.borderRadiusSmall};
    cursor: pointer;
`;
export const NonFilledArea = styled.div<{ fill: number }>`
    flex: ${(props) => props.fill};
`;
export const FilledArea = styled.div<{ fill: number; selected: boolean }>`
    flex: ${(props) => props.fill};
    position: relative;
    background: ${(props) => (props.selected ? props.theme.colors.primary : props.theme.colors.textLight)};
    border-radius: ${(props) => props.theme.base.borderRadiusSmall};
`;
export const Number = styled.div<{ selected: boolean }>`
    position: absolute;
    left: 0;
    right: 0;
    top: -25px;
    text-align: center;
    color: ${(props) => (props.selected ? props.theme.colors.primary : props.theme.colors.text)};
`;
export const PhaseName = styled.span`
    text-align: center;
    color: ${(props) => props.theme.colors.text};
    min-height: 36px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
`;
export const LinkContainer = styled.div`
    width: 100%;
    margin-bottom: ${(props) => props.theme.base.spacing};
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const PrimaryLink = styled.a`
    justify-content: center;
    align-items: center;
    padding: ${(props) => props.theme.base.spacing};
    border-radius: ${(props) => props.theme.base.borderRadius};
    background: ${(props) => props.theme.colors.primary};
    text-align: center;
    color: ${(props) => props.theme.colors.backgroundContrast};
    text-decoration: none;
`;
