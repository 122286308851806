import styled from "styled-components";

export const InnerModal = styled.div`
    padding: 32px;
`;

export const Actions = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.base.spacingInContent};
`;

export const TestNameField = styled.input`
    display: flex;
    width: 100%;
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.base.mediumSize};
    line-height: 1;
    border-radius: ${({ theme }) => theme.base.borderRadiusSmall};
    border-top: 2px solid ${({ theme }) => theme.colors.shadow};
    border-right: 0.5px solid ${({ theme }) => theme.colors.shadow};
    border-bottom: 0.5px solid ${({ theme }) => theme.colors.shadow};
    border-left: 2px solid ${({ theme }) => theme.colors.shadow};
    background: ${({ theme }) => theme.colors.backgroundMain};
    padding: 14px ${({ theme }) => theme.base.spacing};
    margin-bottom: ${({ theme }) => theme.base.spacing};
`;

export const Title = styled.h4`
    font-size: ${(props) => props.theme.base.bigSize};
    font-weight: ${(props) => props.theme.base.bigWeight};
    text-align: center;
    margin: 0 0 ${(props) => props.theme.base.spacingInContent};
    color: ${({ theme }) => theme.colors.primary};
`;

export const Warning = styled.p`
    text-align: center;
    font-size: ${(props) => props.theme.base.size};
    margin-bottom: ${({ theme }) => theme.base.spacing};
`;
