// LIBRARIES
import React, { useMemo, useEffect } from "react";
import { useT } from "@transifex/react";
import { useDispatch } from "react-redux";

//REDUX
import { actions } from "../../../redux/tests/testsSlice";

// TYPES
import { SubjectData } from "p6m-subjects";
import { CoverImage } from "p6-react-shared";

// HELPERS
import { getSubjectCover } from "../../../helpers/Subjects";

// STYLES
import { Header, SubjectTitle, SubjectName, SubjectInfo } from "../styles";

export interface TestsHeaderProps {
    selectedSubject: SubjectData;
}

const TestsHeader: React.FC<TestsHeaderProps> = ({ selectedSubject }) => {
    const dispatch = useDispatch();
    const t = useT();

    const testsAndExercises_t = t("Tests and Exercises", { _tags: "tests,header" });

    const image = useMemo(() => selectedSubject && getSubjectCover(selectedSubject), [selectedSubject]);

    useEffect(() => {
        dispatch(actions.fetchTests());
    }, [dispatch]);

    return (
        <>
            <Header>
                <SubjectTitle>{testsAndExercises_t}</SubjectTitle>
            </Header>
            <SubjectInfo>
                <CoverImage
                    imageId={image || null}
                    responsive
                    size="small"
                    alt={`CoverImage ${selectedSubject.subjectContent.name || ""}`}
                />
                <SubjectName>{selectedSubject.subjectContent.name}</SubjectName>
            </SubjectInfo>
        </>
    );
};

export default React.memo(TestsHeader);
