import { actions } from "./responseSlice";
import { takeLatest, put, delay } from "redux-saga/effects";
import { RESPONSE_TIMEOUT } from "../../constants/ResponseConstants";

export function* cancelTimer() {
    yield delay(RESPONSE_TIMEOUT);
    yield put(actions.cancelResponse());
}

export function* responseSaga() {
    yield takeLatest(actions.showResponse.type, cancelTimer);
}
