// LIBRARIES
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, Redirect, useHistory, useParams } from "react-router-dom";
import { debounce, cloneDeep } from "lodash";
import { useT } from "@transifex/react";
import _ from "lodash";

// REDUX
import { actions as subjectsActions, derived as subjectsDerived } from "../../redux/subjects/subjectsSlice";
import { actions as userActions, selectors as userSelectors } from "../../redux/user/userSlice";
import { actions as responseActions } from "../../redux/response/responseSlice";
import { actions as appStatusActions } from "../../redux/appStatus/appStatusSlice";
import { actions as modalActions } from "../../redux/modal/modalSlice";
import { ampli } from "../../ampli";

//TYPES
import { DropdownOption, EditableContentType } from "p6m-viewData";
import {
    EntryChange,
    IdToOwnerId,
    SubjectContent,
    SubjectData,
    SubjectUnitContent,
    SubjectUnitData,
} from "p6m-subjects";

// COMPONENTS
import Manage from "./Manage";

// NETWORK
import { postSubject } from "../../networking/subjects";
import { postUnit, PostUnitProps } from "../../networking/units";

// UTILS
import { ResponseType } from "../../constants/ResponseConstants";
import { GlobalModalView } from "../../helpers/Modal";
import { getDefaultUnitId, getNextUnitOrder } from "../../helpers/Units";
import { getValidSubjectOptionsFromSubjects, generateSubjectTemplate } from "../../helpers/Subjects";
import { generateUuid } from "../../helpers/Id";
import { defaultUnitOrder } from "../../constants/ContentConstants";

// HOOKS
import { useAppVersionCheck } from "../../hooks/useAppVersionCheck";
import { useFirstPracticeResult } from "../../hooks/useFirstPracticeResult";

const ManageWrapper: React.FC = () => {
    const t = useT();
    const history = useHistory();
    const dispatch = useDispatch();
    const { subjectId, searchText } = useParams();

    const user = useSelector(userSelectors.user);
    const userMetadata = useSelector(userSelectors.userMetadata);
    const userPreferences = useSelector(userSelectors.userPreferences);
    const isTeacher = useSelector(userSelectors.isTeacher);
    const allValidSubjects = useSelector(subjectsDerived.nonExpiredSubjects);
    const { phases } = useSelector(userSelectors.userPreferences);
    const isFirstPracticeFinished = useFirstPracticeResult();

    const [subjectsWereFetched, setSubjectsWereFetched] = useState<boolean>(false);

    const hasFirstPractice = isTeacher || isFirstPracticeFinished;
    const isLibraryAccessDisabled = userPreferences.parentSettings
        ? !userPreferences.parentSettings.libraryAccess
        : false;

    const T_defaultUnit = t("General", {});
    const t_libraryAccessBlockedByAdmin = t("The group admin has blocked the access to the library.", {
        _tags: "response,error,library",
    });

    const setResponse = useCallback(
        (type: ResponseType, text: string) => {
            dispatch(responseActions.showResponse({ type: type, text: [text] }));
        },
        [dispatch]
    );

    useAppVersionCheck();

    useEffect(() => {
        if (!hasFirstPractice || isLibraryAccessDisabled) {
            if (isLibraryAccessDisabled) {
                setResponse("ERROR", t_libraryAccessBlockedByAdmin);
            }
            history.push("/home");
        }
    }, [hasFirstPractice, isLibraryAccessDisabled, history, setResponse, t_libraryAccessBlockedByAdmin]);

    useEffect(() => {
        dispatch(subjectsActions.loadUserSubjects({ withUnits: true }));
        dispatch(subjectsActions.loadFamilySubjects());
    }, [dispatch]);

    const loadSubjects = useCallback(async () => {
        dispatch(appStatusActions.setLoading(true));
        dispatch(subjectsActions.loadUserSubjects({ withUnits: true }));
        setSubjectsWereFetched(true);
        dispatch(appStatusActions.setLoading(false));
    }, [dispatch]);

    useEffect(() => {
        if (!allValidSubjects?.length && !subjectsWereFetched) {
            loadSubjects();
        }
    }, [allValidSubjects, subjectsWereFetched, loadSubjects]);

    useEffect(() => {
        if (!phases || phases.phaseList.length === 0) {
            dispatch(userActions.refreshPreferencesData(user.userDnsId || ""));
        }
    }, [dispatch, phases, user]);

    const [subjectOptions, setSubjectOptions] = useState<DropdownOption[]>([]);

    useEffect(() => {
        setSubjectOptions(getValidSubjectOptionsFromSubjects(allValidSubjects, "" + user.userDnsId));
    }, [allValidSubjects, setSubjectOptions, user.userDnsId]);

    // debounce switching subject to prevent a back and forth switching when moving cards
    const onSubjectSelect = useCallback(
        debounce((passedSubjectId: string | undefined, passedSearchValue: string) => {
            if (passedSubjectId) {
                let path: string = "/manage/" + passedSubjectId;
                if (passedSearchValue.length > 0) path += "/" + passedSearchValue;
                history.push(path);
            } else {
                // use generatePath to remove params
                history.push({ pathname: generatePath("/manage") });
            }
        }, 500),
        [history]
    );

    const openDeleteModal = useCallback(
        (deleteData: {
            contentType: EditableContentType;
            contentNames: string[];
            itemIds: IdToOwnerId[];
            deletableCardCount: number;
            selectedSubjectId?: string;
            selectedCardCount?: number;
        }) => {
            dispatch(modalActions.setData(deleteData));
            dispatch(modalActions.setModalView(GlobalModalView.DeleteContent));
        },
        [dispatch]
    );

    const changeSubject = useCallback(
        (index: number, name: string) => {
            const newSubjectOptions = cloneDeep(subjectOptions);
            newSubjectOptions[index].title = name;
            setSubjectOptions(newSubjectOptions);
        },
        [subjectOptions]
    );

    const resetSubjects = useCallback(() => {
        setSubjectOptions(getValidSubjectOptionsFromSubjects(allValidSubjects, "" + user.userDnsId));
    }, [allValidSubjects, user.userDnsId]);

    // Utility function for handling subject and unit creations, updates and deletions
    const processChanges = <T extends SubjectData | SubjectUnitData>({
        changes,
        findItemFn,
        handleCreationFn,
        handleUpdateFn,
        handleDeletionsFn,
    }: {
        changes: EntryChange[];
        findItemFn: (id: string) => T | undefined; // find a unit/subject
        handleCreationFn: (name: string) => Promise<void>;
        handleUpdateFn: (id: string, data: T) => Promise<any>;
        handleDeletionsFn: (itemsToDelete: T[]) => void;
    }): { itemsToCreate: Promise<any>[]; itemsToUpdate: Promise<any>[] } => {
        let itemsToCreate: Promise<void>[] = [];
        let itemsToUpdate: Promise<any>[] = [];
        let itemsToDelete: T[] = [];

        changes.forEach(async (change) => {
            const originalItem = findItemFn(change.id);
            if (originalItem) {
                const itemContent: SubjectContent | SubjectUnitContent =
                    (originalItem as SubjectData).subjectContent || (originalItem as SubjectUnitData).unitContent;

                if (change.deleted) {
                    itemsToDelete.push(originalItem);
                } else if (itemContent.name !== change.name) {
                    const copiedItem = _.cloneDeep(originalItem);
                    const copiedItemContent: SubjectContent | SubjectUnitContent =
                        (copiedItem as SubjectData).subjectContent || (copiedItem as SubjectUnitData).unitContent;
                    copiedItemContent.name = change.name;
                    itemsToUpdate.push(handleUpdateFn(change.id, copiedItem));
                }
            } else if (change.name) {
                itemsToCreate.push(handleCreationFn(change.name));
            }
        });

        if (itemsToDelete.length > 0) {
            handleDeletionsFn(itemsToDelete);
        }

        return { itemsToCreate, itemsToUpdate };
    };

    const saveUnitEdit = async (changes: EntryChange[]) => {
        const subject = allValidSubjects.find((subject) => subject.subjectMetadata.subjectIdToOwner.id === subjectId);
        if (!subject) return;

        const findUnit = (id: string): SubjectUnitData | undefined =>
            subject.units?.find((unit) => unit.unitId.id === id);

        const handleUnitUpdate = async (_: string, data: SubjectUnitData) => {
            const postData: PostUnitProps = {
                ownerId: data.unitId.ownerId,
                unitId: data.unitId.id,
                subjectId: data.unitContent.subjectIdToOwner.id,
                name: data.unitContent.name,
                order: data.unitContent.order,
            };

            return await postUnit(postData);
        };

        const handleUnitCreation = async (name: string) => {
            if (user.userDnsId) {
                await postUnit({
                    ownerId: user.userDnsId,
                    unitId: generateUuid(),
                    subjectId,
                    name,
                    order: getNextUnitOrder(subject.units),
                });
            }
        };

        const handleUnitDeletions = (unitsToDelete: SubjectUnitData[]) => {
            const cardAmountToBeDeleted = unitsToDelete.reduce((count, unit) => count + (unit.cardCount || 0), 0);
            openDeleteModal({
                contentType: "units",
                contentNames: unitsToDelete.map((unit) => unit.unitContent.name),
                itemIds: unitsToDelete.map((unit) => unit.unitId),
                deletableCardCount: cardAmountToBeDeleted,
                selectedSubjectId: subjectId,
            });
        };

        const { itemsToUpdate, itemsToCreate } = processChanges<SubjectUnitData>({
            changes,
            findItemFn: findUnit,
            handleUpdateFn: handleUnitUpdate,
            handleCreationFn: handleUnitCreation,
            handleDeletionsFn: handleUnitDeletions,
        });

        if (itemsToUpdate.length > 0) {
            await Promise.all(itemsToUpdate);
        }
        if (itemsToCreate.length > 0) {
            await Promise.all(itemsToCreate);
        }

        dispatch(subjectsActions.loadSubjectUnits(subjectId));
    };

    const saveSubjectEdit = async (changes: EntryChange[]) => {
        const findSubject = (id: string): SubjectData | undefined =>
            allValidSubjects.find((subject) => subject.subjectMetadata.subjectIdToOwner.id === id);

        const handleSubjectUpdate = async (id: string, data: SubjectData) =>
            await postSubject(
                generateSubjectTemplate({
                    subjectId: id,
                    creatorId: data.subjectMetadata.subjectIdToOwner.ownerId,
                    name: data.subjectContent.name,
                })
            );

        const handleSubjectCreation = async (name: string) => {
            if (user.userDnsId) {
                ampli.clickCreateSubjectA({ app_screen: "library" });
                const newSubjectId = generateUuid();
                await postSubject(
                    generateSubjectTemplate({
                        subjectId: newSubjectId,
                        creatorId: user.userDnsId,
                        name,
                        primaryLang: "de",
                    })
                );
                await postUnit({
                    ownerId: user.userDnsId,
                    unitId: getDefaultUnitId(newSubjectId),
                    subjectId: newSubjectId,
                    name: T_defaultUnit,
                    order: defaultUnitOrder,
                });
            }
        };

        const handleSubjectDeletions = (subjectsToDelete: SubjectData[]) => {
            const contentNames = subjectsToDelete.map((subject) => subject.subjectContent.name);
            const itemIds = subjectsToDelete.map((subject) => subject.subjectMetadata.subjectIdToOwner);
            const cardAmountToBeDeleted = subjectsToDelete.reduce(
                (count, subject) => count + (subject.groupedCardCount?.cardCounts.LIBRARY.cardCount || 0),
                0
            );
            openDeleteModal({
                contentType: "subjects",
                contentNames,
                itemIds,
                deletableCardCount: cardAmountToBeDeleted,
                selectedSubjectId: subjectId,
            });
        };

        const { itemsToCreate, itemsToUpdate } = processChanges<SubjectData>({
            changes,
            findItemFn: findSubject,
            handleUpdateFn: handleSubjectUpdate,
            handleCreationFn: handleSubjectCreation,
            handleDeletionsFn: handleSubjectDeletions,
        });

        if (itemsToUpdate.length > 0) {
            await Promise.all(itemsToUpdate);
        }
        if (itemsToCreate.length > 0) {
            await Promise.all(itemsToCreate);
        }

        dispatch(subjectsActions.loadUserSubjects({ withUnits: true }));
    };

    const subject = allValidSubjects.find((subject) => subject.subjectMetadata.subjectIdToOwner.id === subjectId);

    if (subject) {
        return (
            <Manage
                user={user}
                isTeacher={isTeacher}
                subjectId={subjectId}
                allUserSubjects={allValidSubjects}
                subject={subject}
                subjectOptions={subjectOptions}
                initialSearchText={searchText}
                onSubjectSelect={onSubjectSelect}
                availablePhases={phases?.phaseList.map((_, i) => i) || []}
                setResponse={setResponse}
                changeSubject={changeSubject}
                resetSubjectEdit={resetSubjects}
                saveSubjectEdit={saveSubjectEdit}
                saveUnitEdit={saveUnitEdit}
            />
        );
    } else {
        return <Redirect to="/manage" />;
    }
};

export default ManageWrapper;
