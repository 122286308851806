import styled from "styled-components";
import PhaseSixIcon from "../../../../basic/phaseSixIcon/PhaseSixIcon";
import { Row } from "../../../../basic/grid";
import { focusStyles } from "../../Buttons/styles";

export const StyledIcon = styled(PhaseSixIcon)`
    display: inline-block;
    margin-top: -2px;
    font-size: ${({ theme: { base } }) => base.titleSize};
    vertical-align: middle;
    color: inherit;
`;

export const ButtonRow = styled(Row)`
    margin-bottom: 0 !important;
`;

export const InfoContainer = styled.div`
    margin-top: 70px;
    // full-width colored ribbon
    margin-left: -${({ theme: { base } }) => base.spacing};
    margin-right: -${({ theme: { base } }) => base.spacing};
    // double padding to compensate for margin stretch
    padding: calc(2 * ${({ theme: { base } }) => base.spacing});
    background: ${({ theme: { colors } }) => colors.primary};
    color: ${({ theme: { colors } }) => colors.backgroundContrast};

    p {
        font-size: 16px;
        text-align: center;

        span {
            font-size: 22px;
        }
    }
`;

export const InfoContainerDivider = styled.div`
    width: 220px;
`;

export const ModalHeader = styled.h1`
    margin: ${({ theme: { base } }) => base.spacing} 0 0 0;
    font-size: ${({ theme: { base } }) => base.titleBigSize};
    color: ${({ theme: { colors } }) => colors.primary};
    text-align: center;
    font-weight: normal;
    flex: 1;
`;

export const CloseIcon = styled(PhaseSixIcon)`
    font-size: ${({ theme: { base } }) => base.iconSmall};
`;

export const CloseIconWrapper = styled.div`
    color: ${({ theme: { colors } }) => colors.primary};
    cursor: pointer;
    float: right;
    margin: ${({ theme: { base } }) => base.spacingInContent} ${({ theme: { base } }) => base.spacingInContent} 0 0;
`;

export const ResultContainer = styled.div`
    width: fit-content;
    margin: -175px auto 0 auto;

    > img {
        display: block;
        margin: ${({ theme: { base } }) => base.spacingInContent} auto ${({ theme: { base } }) => base.spacing} auto;
        width: 220px;
        height: auto;
    }
`;

export const StarContainer = styled.div`
    max-height: 80px;
`;

export const ShareLink = styled.span`
    color: ${({ theme: { colors } }) => colors.primary};
    text-decoration: underline;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`;

export const ShareResult = styled.div`
    font-size: 16px;
    margin-top: 10px;
    color: ${({ theme: { colors } }) => colors.text};
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 12px;
`;

export const ShareMailLink = ShareLink.withComponent("a");

export const StarWrapper = styled.div`
    position: relative;
    display: inline-block;
    &:nth-child(2) {
        transform: translateY(-10px);
    }

    ${StyledIcon} {
        font-size: inherit;
    }
`;

export const Star = styled.div<{ active?: boolean }>`
    position: relative;
    top: -15px;
    z-index: 2;
    font-size: 72px;
    max-width: 90px;
    color: ${({ theme: { colors }, active = false }) => (active ? colors.primary : colors.textLight)};
`;

export const StarBg = styled.div`
    position: absolute;
    left: -4px;
    top: -22px;
    z-index: 1;
    color: ${({ theme: { colors } }) => colors.backgroundContrast};
    font-size: 80px;
`;

export const SubTitle = styled.h2`
    margin: 5px 0 0 0;
    font-size: ${({ theme: { base } }) => base.bigSize};
    color: ${({ theme: { colors } }) => colors.text};
    text-align: center;
    font-weight: normal;

    > span {
        color: ${({ theme: { colors } }) => colors.text};
    }
`;

export const Buttons = styled.div`
    margin-top: 20px;
`;

export const Button = styled.button<{ primary?: boolean }>`
    display: flex;
    padding: ${({ theme: { base } }) => base.spacingInContent} ${({ theme: { base } }) => base.spacing};
    font-size: ${({ theme: { base } }) => base.mediumSize};
    color: ${({ theme: { colors }, primary }) => (primary ? colors.primary : colors.text)};
    border: 1px solid ${({ theme: { colors }, primary }) => (primary ? colors.primary : colors.text)};
    background: none;
    border-radius: ${({ theme: { base } }) => base.borderRadius};
    text-align: center;
    cursor: pointer;
    align-items: center;
    margin: 0 ${({ theme: { base } }) => base.spacingTiny};
    &:hover {
        opacity: ${({ theme: { base } }) => base.hoverOpacity};
    }

    ${focusStyles}
`;

export const FilledButton = styled(Button)`
    border: 1px solid ${({ theme: { buttons } }) => buttons.primary.backgroundColor};
    background: ${({ theme: { buttons } }) => buttons.primary.backgroundColor};
    color: ${({ theme: { buttons } }) => buttons.primary.textColor};
`;
