// REACT
import React, { FunctionComponent } from "react";

// COMPONENTS
import PhaseSixIcon from "../../../../basic/phaseSixIcon/PhaseSixIcon";

// STYLED COMPONENTS
import { Wrapper, Label, Chevron, Items, Item, RemoveIcon } from "./styles";

// TYPES
import { InputProps, ItemProps } from "p6m-dropdown";

const DropdownInput: FunctionComponent<InputProps> = (props) => {
    const { items = [], oneLine, label, chevron, onRemove, isOpen } = props;

    const removable: boolean = !!onRemove;

    return (
        <Wrapper>
            {!!label && <Label>{label}</Label>}
            <Items oneLine={oneLine}>
                {items.map((item: ItemProps) => (
                    <Item
                        key={item.key}
                        removable={removable}
                    >
                        {item.component}
                        {!!removable && (
                            <RemoveIcon
                                onClick={(e: any) => {
                                    e.stopPropagation();
                                    if (!!onRemove) onRemove(item);
                                }}
                            >
                                <PhaseSixIcon name="close" />
                            </RemoveIcon>
                        )}
                    </Item>
                ))}
            </Items>
            {!!chevron && (
                <Chevron>
                    <PhaseSixIcon name={isOpen ? "chevron-up" : "chevron-down"} />
                </Chevron>
            )}
        </Wrapper>
    );
};

export default DropdownInput;
