import { useRef, useEffect } from "react";

export function useSelectFirstItem(items: string[], selected: string, selectItem: (id: string) => void) {
    const prevSelectedItem = useRef<string>("");
    const wasAutoselected = useRef<boolean>(false);

    useEffect(() => {
        wasAutoselected.current = false;
    }, [items]);

    useEffect(() => {
        if (wasAutoselected.current) return;
        const hasItems = items.length && items[0] !== "default";
        const defaultSelected = selected === "default";

        if (!hasItems && defaultSelected) return;

        if (!hasItems) {
            prevSelectedItem.current = selected;
            selectItem("default");
            return;
        }

        if (prevSelectedItem.current && items.includes(prevSelectedItem.current)) {
            selectItem(prevSelectedItem.current);
            prevSelectedItem.current = "";
            wasAutoselected.current = true;
            return;
        }

        if (!items.includes(selected) || defaultSelected) {
            selectItem(items[0]);
            wasAutoselected.current = true;
        }
    }, [items, selected, selectItem]);
}
