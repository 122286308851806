// DEPS
import React, { FunctionComponent, useState } from "react";
import { T } from "@transifex/react";

// COMPONENTS
import Modal from "../../../basic/modal/Modal";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";
import { Row } from "../../../basic/grid";
import { DefaultFocus } from "../../../basic/defaultFocus/DefaultFocus";

// STYLED COMPONENTS
import { ItemScoreName } from "../SubjectItem/styles";
import {
    ModalHeader,
    IconWrapper,
    ScoreWrapper,
    ModalButton,
    ShareModalButton,
    StyledPhaseSixIcon,
    PlayAgainCol,
    RightButtonWrapper,
    LeftButtonWrapper,
} from "./styles";

type Props = {
    onExit: () => void;
    onPlay: () => void;
};

const LoseModal: FunctionComponent<Props> = (props) => {
    const { onExit, onPlay } = props;
    const [showModalAndCallback, setModal] = useState<string>("");

    return (
        <Modal
            active={!showModalAndCallback}
            headerComponent={() => (
                <>
                    <DefaultFocus />
                    <ModalHeader>
                        <T _str="You Lose" />
                    </ModalHeader>
                </>
            )}
            afterClose={() => (showModalAndCallback === "onExit" ? onExit() : onPlay())}
            preventCloseOnBg
        >
            <IconWrapper>
                <PhaseSixIcon name={"clock"} />
            </IconWrapper>
            <ScoreWrapper>
                <ItemScoreName score={0}>
                    <T _str="Game Over!" />
                </ItemScoreName>
            </ScoreWrapper>
            <Row>
                <LeftButtonWrapper xs="auto">
                    <ModalButton
                        onClick={() => {
                            setModal("onExit");
                        }}
                    >
                        <T _str="Exit" />
                    </ModalButton>
                </LeftButtonWrapper>
                <PlayAgainCol>
                    <ModalButton
                        outline
                        fullWidth
                        onClick={() => {
                            setModal("onPlay");
                        }}
                    >
                        <T _str="Play again!" />
                    </ModalButton>
                </PlayAgainCol>
                <RightButtonWrapper xs="auto">
                    <ShareModalButton
                        disabled
                        primary
                    >
                        <StyledPhaseSixIcon name="share" />
                    </ShareModalButton>
                </RightButtonWrapper>
            </Row>
        </Modal>
    );
};

export default LoseModal;
