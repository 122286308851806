import styled from "styled-components";

export const Background = styled.div`
    height: 100%;
    width: 100%;
    padding: 0;
    border-radius: ${({ theme: { base } }) => base.borderRadius};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    overflow: hidden;
`;
export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: ${(props) => props.theme.base.spacing};
    background: ${(props) => props.theme.colors.backgroundMain};
`;
export const ButtonsContainer = styled.div`
    width: 100%;
    padding: ${(props) => props.theme.base.spacing};
    background: ${(props) => props.theme.colors.backgroundMain};
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`;
export const Button = styled.span<{ highlighted?: boolean }>`
    margin-left: ${(props) => props.theme.base.spacing};
    padding: ${(props) => props.theme.base.spacingSmall};
    border-radius: ${(props) => props.theme.base.borderRadiusSmall};
    background: ${(props) => (props.highlighted ? props.theme.colors.red : props.theme.colors.backgroundMain)};
    color: ${(props) => (props.highlighted ? props.theme.colors.backgroundContrast : props.theme.colors.text)};
    border: ${(props) => (props.highlighted ? "" : `1px solid ${props.theme.colors.text}`)};
    text-transform: capitalize;
    text-align: center;
    font-size: ${({ theme: { base } }) => base.bigSize};
    cursor: pointer;
`;
export const Description = styled.p`
    color: ${(props) => props.theme.colors.text};
    margin-top: ${(props) => props.theme.base.spacing};
`;
export const DescriptionHighlighted = styled.span`
    color: ${(props) => props.theme.colors.primary};
`;
