// REACT
import React, { FunctionComponent, memo } from "react";
import { T } from "@transifex/react";

// COMPONENTS
import { Props as ComponentProps } from "../ContentModal";
import PhaseSixIcon from "../../../../basic/phaseSixIcon/PhaseSixIcon";

// STYLED COMPONENTS
import { Button } from "./styles";

type Props = {
    type: ComponentProps["type"];
    onClick: ComponentProps["onAddClick"];
    onShare: ComponentProps["onShare"];
};

const ModalFooter: FunctionComponent<Props> = (props) => {
    const { type, onClick } = props;

    const typeTitles = {
        subject: <T _str="Add subject" />,
        unit: <T _str="Add unit" />,
        share: undefined,
    };

    return (
        <>
            {typeTitles[type] && (
                <Button onClick={onClick}>
                    <PhaseSixIcon name="add" />
                    &nbsp;{typeTitles[type]}
                </Button>
            )}
            {/* 
        --- We don't want to remove it yet permanently (including all relevant logic and functionalities in parent components), just in case we might need it again in the future! ----
        {type === 'subject' && onShare &&
            <Button onClick={onShare}>
                <T _str="Share cards" />&nbsp;<PhaseSixIcon name="share" />
            </Button>
        } */}
        </>
    );
};

export default memo(ModalFooter);
