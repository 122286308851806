// REACT
import React, { FunctionComponent } from "react";

// STYLED COMPONENTS
import { Container, Header, Footer, Content, Item } from "./styles";

// TYPES
import { DropdownProps } from "p6m-dropdown";

const DropdownList: FunctionComponent<DropdownProps> = (props) => {
    const { items = [], selected = [], onSelect = () => {}, header, footer } = props;

    return (
        <Container>
            {!!header && <Header>{header}</Header>}
            <Content>
                {items.map((item) => (
                    <Item
                        key={item.key}
                        onClick={() => onSelect(item)}
                        selected={selected.includes(item.key)}
                    >
                        {item.component}
                    </Item>
                ))}
            </Content>
            {!!footer && <Footer>{footer}</Footer>}
        </Container>
    );
};

export default DropdownList;
