// REACT
import React, { FunctionComponent, useCallback } from "react";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import { selectors, actions } from "../../../../redux/learning/learningSlice";

// COMPONENTS
import Component from "./Modals";

export const Modals: FunctionComponent = () => {
    const modals = useSelector(selectors.modals);

    const dispatch = useDispatch();

    const afterClose = useCallback(() => {
        dispatch(actions.removeModal(modals[0]));
    }, [dispatch, modals]);

    return (
        <Component
            modal={modals[0]}
            afterClose={afterClose}
        />
    );
};
