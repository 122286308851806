// LIBRARIES
import React, { useCallback, useEffect, useState } from "react";
import { ampli } from "../../../../ampli";

// MODELS
import { SubjectData, SubjectReportDifficultCardData } from "p6m-subjects";

// COMPONENTS
import {
    Container,
    Header,
    QuestionHeader,
    AnswerHeader,
    CountHeader,
    Statistics,
    Entry,
    QuestionText,
    AnswerText,
    CountText,
} from "./styles";
import { NoDataContainer, NoDataText } from "../../styles";
import { useT } from "@transifex/react";
import { getReportDifficultData } from "../../../../networking/reports";
import { stripMediaIds } from "../../../../helpers/Cards";

export interface ReportsProps {
    ownerId?: string;
    selectedSubject: SubjectData;
}

const ReportDifficult: React.FC<ReportsProps> = (props) => {
    const t = useT();
    const [data, setData] = useState<SubjectReportDifficultCardData[] | null>(null);

    const loadData = useCallback(async () => {
        const {
            data: { replyContent: difficultCards },
        } = await getReportDifficultData(
            props.ownerId || "",
            props.selectedSubject.subjectMetadata.subjectIdToOwner,
            "ONE_MONTH"
        );
        setData(difficultCards);
    }, [props.ownerId, props.selectedSubject]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    useEffect(() => {
        ampli.openReportsTab({
            reports_tab: "difficult_cards",
        });
    }, []);

    const questionHeader_t = t("Question", { _tags: "reports,difficult,title" });
    const answerHeader_t = t("Correct answer", { _tags: "reports,difficult,title" });
    const countHeader_t = t("Wrong answers", { _tags: "reports,difficult,title" });
    const noData_t = t("There is no data do display", { _tags: "reports,message" });

    return (
        <Container>
            <Header>
                <QuestionHeader>{questionHeader_t}</QuestionHeader>
                <AnswerHeader>{answerHeader_t}</AnswerHeader>
                <CountHeader>{countHeader_t}</CountHeader>
            </Header>
            {data &&
                (data.length > 0 ? (
                    data.map((card, index) => {
                        return (
                            <Entry key={"card_" + index}>
                                <Statistics>
                                    <QuestionText dangerouslySetInnerHTML={{ __html: stripMediaIds(card.question) }} />
                                    <AnswerText dangerouslySetInnerHTML={{ __html: stripMediaIds(card.answer) }} />
                                    <CountText>{card.counter.toLocaleString("de-DE")}</CountText>
                                </Statistics>
                            </Entry>
                        );
                    })
                ) : (
                    <NoDataContainer key={"noData"}>
                        <NoDataText>{noData_t}</NoDataText>
                    </NoDataContainer>
                ))}
        </Container>
    );
};

export default ReportDifficult;
