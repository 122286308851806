import styled from "styled-components";

export const Wrapper = styled.div<{ $active: boolean }>`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    padding: ${({ theme: { base } }) => base.spacingSmall};
    background-color: ${({ theme: { colors } }) => colors.backgroundMain};
    box-shadow: 0 -5px 20px -5px #666;
    opacity: ${({ $active }) => ($active ? 1 : 0)};
    transition: opacity ${({ theme: { base } }) => base.transition}s;
`;

export const Input = styled.input`
    height: 40px;
    width: 100%;
    padding: 0 5px;
    border-radius: 5px;
    background-color: ${({ theme: { colors } }) => colors.backgroundContrast};
    outline: none;
    border: 1px solid ${({ theme: { colors } }) => colors.textLight};
    font-size: ${({ theme: { base } }) => base.bigSize};
    line-height: 40px;
    color: ${(props) => props.theme.colors.text};

    &:focus {
        outline: none;
        border-color: ${({ theme: { colors } }) => colors.primary};
    }
`;

export const InputError = styled.p`
    margin: 0;
    font-size: ${({ theme: { base } }) => base.size};
    color: #d2002c;
`;

export const LanguagesWrapper = styled.div`
    padding: 10px 5px;
    padding-bottom: 5px;
    border-radius: ${({ theme: { base } }) => base.borderRadiusSmall};
    background-color: ${({ theme: { colors } }) => colors.backgroundContrast};
`;

export const LanguageText = styled.p`
    color: ${({ theme: { colors } }) => colors.text};
`;

export const ActionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

export const ActionClose = styled.div`
    margin: 5px 0;
    color: ${({ theme: { colors } }) => colors.text};
    font-size: ${({ theme: { base } }) => base.bigSize};
    cursor: pointer;
`;

export const ActionSave = styled(ActionClose)<{ $disabled: boolean }>`
    color: ${(props) => (props.$disabled ? props.theme.colors.text : props.theme.colors.primary)};
    ${({ $disabled }) =>
        $disabled
            ? `
        opacity: 0.5;
        cursor: default;
    `
            : ""}
`;
