// LIBRARIES
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import { useT } from "@transifex/react";
import { ampli } from "../../../ampli";

//REDUX
import { actions as modalActions, selectors as modalSelectors } from "../../../redux/modal/modalSlice";
import { selectors as userSelectors } from "../../../redux/user/userSlice";
import { actions as responseActions, actions as responseAction } from "../../../redux/response/responseSlice";
import { actions as subjectsActions, selectors as subjectsSelectors } from "../../../redux/subjects/subjectsSlice";

//QUERIES AND MUTATIONS
import { deleteSubject, moveCardsToSubjectAndUnit, postSubject } from "../../../networking/subjects";
import { deleteUnit, postUnit } from "../../../networking/units";

//HELPERS
import { GlobalModalView } from "../../../helpers/Modal";
import {
    getValidSubjectOptionsFromSubjects,
    getUnitOptionsFromSubject,
    generateSubjectTemplate,
} from "../../../helpers/Subjects";
import { getNextUnitOrder, isDefaultUnit, getDefaultUnitId } from "../../../helpers/Units";
import { generateUuid } from "../../../helpers/Id";
import { ResponseMessageKeys } from "../response";

//TYPES
import { EntryChange, IdToOwnerId, SubjectData } from "p6m-subjects";
import { User } from "p6m-user";
import { ExtendedOption } from "p6m-viewData";

//COMPONENTS
import ChangeCardSubjectModal from "./ChangeCardSubjectModal";

type InitialMessageParams = {
    subjectName: string;
    unitName: string;
    moveCount: number;
    duplicateCount: number;
};

const initialMessageParams: InitialMessageParams = {
    subjectName: "",
    unitName: "",
    moveCount: 0,
    duplicateCount: 0,
};

const ChangeCardSubjectModalWrapper: React.FC = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const t = useT();

    const user: User = useSelector(userSelectors.user);
    const isTeacher: boolean = useSelector(userSelectors.isTeacher);
    const {
        cardIds,
        initialSubjectId,
        containsProtectedCardIds,
        refreshCardList,
    }: {
        cardIds: IdToOwnerId[];
        initialSubjectId: string;
        containsProtectedCardIds?: boolean;
        refreshCardList?: () => void;
    } = useSelector(modalSelectors.data);

    const nonFilteredSubjects = useSelector(subjectsSelectors.subjects);
    const nonFilteredSubjectsIdMap: { [key: string]: SubjectData } = nonFilteredSubjects.reduce(
        (acc, s) => ({
            ...acc,
            [s.subjectMetadata.subjectIdToOwner.id]: s,
        }),
        {}
    );

    const [selectedSubjectId, setSelectedSubjectId] = useState<string>(initialSubjectId);
    const [selectedUnitId, setSelectedUnitId] = useState<string>("");

    const [subjectsToChooseFrom, setSubjectsToChooseFrom] = useState<ExtendedOption[]>([]);
    const [unitsToChooseFrom, setUnitsToChooseFrom] = useState<ExtendedOption[]>([]);

    const [messageParams, setMessageParams] = useState<InitialMessageParams>(initialMessageParams);

    const defaultUnit_t = t("General", {});
    const noUserId_t = t("Your userId could not be recognized", {
        _tags: "library,edit,response",
    });
    const cardsChangedSuccessfully_t = t(
        "{X} card(s) were moved to subject {subject_name} and assigned to unit {unit_name}.",
        {
            _tags: "library,edit,response",
            X: messageParams.moveCount,
            subject_name: messageParams.subjectName,
            unit_name: messageParams.unitName,
        }
    );
    const cardsChangedWarning_t = t(
        "{X} card(s) were moved to subject {subject_name} and assigned to unit {unit_name}. {newline}{Y} of {Z} card(s) could not be moved because they already exist in the chosen destination",
        {
            _tags: "library,edit,response.",
            X: messageParams.moveCount,
            subject_name: messageParams.subjectName,
            unit_name: messageParams.unitName,
            Y: messageParams.duplicateCount,
            Z: messageParams.duplicateCount + messageParams.moveCount,
            newline: <br />,
        }
    );
    const cardsChangedUnsuccessfully_t = t(
        "{Y} of {Z} card(s) could not be moved because they already exist in the chosen destination.",
        {
            _tags: "library,edit,response",
            Y: messageParams.duplicateCount,
            Z: messageParams.duplicateCount + messageParams.moveCount,
        }
    );
    const cardChangeFailed_t = t("Changing cards data failed", {
        _tags: "library,edit,response",
    });

    const selectSubject = useCallback(
        (newSelectedIds: string[]) => {
            if (subjectsToChooseFrom.length) {
                const newSelectedSubjectOption = subjectsToChooseFrom.find((s) => s.value === newSelectedIds[0]); // since multiselect is disabled there is always just one
                if (newSelectedSubjectOption) {
                    const newSubjectId = newSelectedSubjectOption.value;
                    if (newSubjectId) {
                        setSelectedSubjectId(newSubjectId);
                        const selectedSubject = nonFilteredSubjectsIdMap[newSubjectId];
                        setSelectedUnitId(selectedSubject.units?.length ? selectedSubject.units[0].unitId.id : "");
                    }
                }
            }
        },
        [subjectsToChooseFrom, nonFilteredSubjectsIdMap]
    );

    const selectUnit = useCallback(
        (selectedIds: string[]) => {
            if (unitsToChooseFrom.length) {
                const selectedUnitOption = unitsToChooseFrom.find((u) => u.value === selectedIds[0]); // since multiselect is disabled there is always just one
                if (selectedUnitOption) {
                    const unitId = `${selectedUnitOption.value}`;
                    setSelectedUnitId(unitId);
                }
            }
        },
        [unitsToChooseFrom]
    );

    const close = () => {
        dispatch(modalActions.setModalView(GlobalModalView.None));
    };

    const save = async () => {
        const userId = user.userDnsId;
        const selectedSubject = nonFilteredSubjectsIdMap[selectedSubjectId];
        if (userId) {
            const unitToSave = selectedSubject.units?.find((unit) => unit.unitId.id === selectedUnitId);
            let selectedUnitIdToOwnerId: IdToOwnerId = {
                id: selectedUnitId,
                ownerId: unitToSave ? unitToSave.unitId.ownerId : userId,
            };

            if (!selectedUnitId) {
                const defaultUnit = selectedSubject.units?.find((unit) => isDefaultUnit(unit));
                if (defaultUnit) {
                    // move to existing general unit
                    selectedUnitIdToOwnerId = defaultUnit.unitId;
                } else {
                    // create general unit
                    const newDefaultUnitId = getDefaultUnitId(selectedSubjectId);
                    const highestOrder = getNextUnitOrder(selectedSubject.units);

                    await postUnit({
                        ownerId: userId,
                        unitId: newDefaultUnitId,
                        subjectId: selectedSubjectId,
                        name: defaultUnit_t,
                        order: highestOrder,
                    });
                    dispatch(
                        subjectsActions.loadSubjectUnits({
                            subjectId: selectedSubjectId,
                            target: "subjects",
                        })
                    );

                    selectedUnitIdToOwnerId = { id: newDefaultUnitId, ownerId: userId };
                }
            }
            try {
                let moveCardsCounter: number = 0;
                let duplicateCardsCounter: number = 0;

                const selectedSubjectIdToOwnerIds =
                    nonFilteredSubjectsIdMap[selectedSubjectId].subjectMetadata.subjectIdToOwner;

                const movedCardsResponses = await moveCardsToSubjectAndUnit(
                    initialSubjectId,
                    cardIds,
                    selectedSubjectIdToOwnerIds,
                    selectedUnitIdToOwnerId,
                    userId
                );

                movedCardsResponses.forEach((res) =>
                    res.data.httpCode === 200 ? moveCardsCounter++ : duplicateCardsCounter++
                );

                if (selectedSubjectIdToOwnerIds.id !== initialSubjectId) {
                    ampli.clickedEditCardsButton({
                        cards_number: moveCardsCounter,
                        user_action: "changeSubject",
                    });
                } else if (selectedUnitIdToOwnerId) {
                    ampli.clickedEditCardsButton({
                        cards_number: moveCardsCounter,
                        user_action: "changeUnit",
                    });
                }

                setMessageParams(() => {
                    return {
                        subjectName: selectedSubject.subjectContent.name,
                        unitName: unitToSave ? unitToSave.unitContent.name : defaultUnit_t,
                        moveCount: moveCardsCounter,
                        duplicateCount: duplicateCardsCounter,
                    };
                });
            } catch (e) {
                dispatch(
                    responseAction.showResponse({
                        type: "ERROR",
                        text: [cardChangeFailed_t],
                    })
                );
            }
        } else {
            dispatch(
                responseAction.showResponse({
                    type: "ERROR",
                    text: [noUserId_t],
                })
            );
        }
        dispatch(subjectsActions.loadUserSubjects({ withUnits: true }));
        close();
    };

    const onDeleteSubject = useCallback(
        (subjectId: string) => {
            const subjectOptionIndex = subjectsToChooseFrom.findIndex((subOption) => subOption.value === subjectId);
            if (subjectOptionIndex > -1) {
                const newSubjectsOptions = cloneDeep(subjectsToChooseFrom);
                newSubjectsOptions[subjectOptionIndex].deleted = true;
                setSubjectsToChooseFrom(newSubjectsOptions);
            }
        },
        [subjectsToChooseFrom, setSubjectsToChooseFrom]
    );

    const changeSubjectName = useCallback(
        (index: number, name: string) => {
            const newSubjectsOptions = cloneDeep(subjectsToChooseFrom);
            newSubjectsOptions[index].title = name;
            setSubjectsToChooseFrom(newSubjectsOptions);
        },
        [subjectsToChooseFrom, setSubjectsToChooseFrom]
    );

    const changeUnitName = useCallback(
        (index: number, name: string) => {
            const newUnitOptions = cloneDeep(unitsToChooseFrom);
            newUnitOptions[index].title = name;
            setUnitsToChooseFrom(newUnitOptions);
        },
        [unitsToChooseFrom, setUnitsToChooseFrom]
    );

    const resetSubjectEdit = useCallback(() => {
        const validSubjectOptions = getValidSubjectOptionsFromSubjects(nonFilteredSubjects, `${user.userDnsId}`);
        setSubjectsToChooseFrom(
            validSubjectOptions.map((option) => ({
                ...option,
                selected: false,
                deleted: false,
            }))
        );
    }, [nonFilteredSubjects, user]);

    const resetUnitEdit = useCallback(() => {
        const selectedSubject = nonFilteredSubjectsIdMap[selectedSubjectId];
        const unitsToChooseFrom = getUnitOptionsFromSubject(selectedSubject, `${user.userDnsId}`, isTeacher);
        setUnitsToChooseFrom(
            unitsToChooseFrom.map((option) => ({
                ...option,
                selected: false,
                deleted: false,
            }))
        );
    }, [isTeacher, nonFilteredSubjectsIdMap, selectedSubjectId, user.userDnsId]);

    const updateOrDeleteOrAddSubjects = async (subjectsChanges: EntryChange[]) => {
        const subjectsToBeUpdated: Promise<any>[] = [];
        const subjectsToBeDeleted: Promise<any>[] = [];
        const subjectsToBeAdded: Promise<any>[] = [];
        const defaultUnitsToBeAdded: Promise<any>[] = [];

        subjectsChanges.forEach((change) => {
            const subjectToBeModified = nonFilteredSubjectsIdMap[change.id];
            const subjectShallBeDeleted = change.deleted;
            const newSubjectShallBeAdded = !subjectToBeModified;

            if (subjectToBeModified) {
                const ownerId = subjectToBeModified.subjectMetadata.subjectIdToOwner.ownerId;
                const subjectId = subjectToBeModified.subjectMetadata.subjectIdToOwner.id;
                if (subjectShallBeDeleted) {
                    subjectsToBeDeleted.push(deleteSubject({ id: subjectId, ownerId }));
                } else if (subjectToBeModified.subjectContent.name !== change.name) {
                    subjectsToBeUpdated.push(
                        postSubject({
                            ownerId,
                            subjectId,
                            data: { name: change.name, ownerId },
                        })
                    );
                }
            }

            if (newSubjectShallBeAdded) {
                ampli.clickCreateSubjectA({ app_screen: "library" });

                const creatorId = user.userDnsId;
                const newSubjectName = change.name;

                if (creatorId && newSubjectName) {
                    const newSubject = generateSubjectTemplate({
                        creatorId,
                        name: newSubjectName,
                        primaryLang: "de",
                    });
                    subjectsToBeAdded.push(postSubject(newSubject));
                    const newDefaultUnitId = getDefaultUnitId(newSubject.subjectId);
                    defaultUnitsToBeAdded.push(
                        postUnit({
                            ownerId: `${creatorId}`,
                            unitId: newDefaultUnitId,
                            subjectId: newSubject.subjectId,
                            name: defaultUnit_t,
                        })
                    );
                } else {
                    dispatch(
                        responseActions.showResponse({
                            type: "WARNING",
                            responseMessage: ResponseMessageKeys.ERROR_CREATING_SUBJECT,
                        })
                    );
                }
            }
        });
        await Promise.all([
            ...subjectsToBeDeleted,
            ...subjectsToBeUpdated,
            ...subjectsToBeAdded,
            ...defaultUnitsToBeAdded,
        ]);
        // reload subjects to get all updates
        setSubjectsToChooseFrom([]);
        dispatch(subjectsActions.loadUserSubjects({ withUnits: true }));
    };

    const updateOrDeleteOrAddUnits = useCallback(
        async (unitsChanges: EntryChange[]) => {
            const selectedSubject = nonFilteredSubjectsIdMap[selectedSubjectId];
            if (selectedSubject) {
                const selectedSubjectId = selectedSubject.subjectMetadata.subjectIdToOwner.id;
                const unitsToBeUpdated: Promise<any>[] = [];
                const unitsToBeDeleted: Promise<any>[] = [];
                const unitsToBeAdded: Promise<any>[] = [];

                unitsChanges.forEach((change) => {
                    const creatorId = user.userDnsId;
                    const newUnitName = change.name;
                    const unitToBeModified = selectedSubject.units?.find((unit) => unit.unitId.id === change.id);
                    const unitShallBeDeleted = change.deleted;
                    const newUnitShallBeAdded = !unitToBeModified;
                    if (unitToBeModified) {
                        if (unitShallBeDeleted) {
                            unitsToBeDeleted.push(
                                deleteUnit({ id: unitToBeModified.unitId.id, ownerId: unitToBeModified.unitId.ownerId })
                            );
                        } else {
                            unitsToBeUpdated.push(
                                postUnit({
                                    ownerId: unitToBeModified.unitId.ownerId,
                                    unitId: unitToBeModified.unitId.id,
                                    subjectId: unitToBeModified.unitContent.subjectIdToOwner.id,
                                    name: newUnitName,
                                    order: unitToBeModified.unitContent.order,
                                })
                            );
                        }
                    } else if (creatorId && newUnitShallBeAdded) {
                        // is add
                        const newUnitId = generateUuid();
                        const highestOrder = getNextUnitOrder(selectedSubject.units);

                        unitsToBeAdded.push(
                            postUnit({
                                ownerId: creatorId,
                                unitId: newUnitId,
                                subjectId: selectedSubjectId,
                                name: newUnitName,
                                order: highestOrder,
                            })
                        );
                    }
                });
                await Promise.all([...unitsToBeDeleted, ...unitsToBeUpdated, ...unitsToBeAdded]);
                dispatch(subjectsActions.loadSubjectUnits(selectedSubjectId));
            }
        },
        [nonFilteredSubjectsIdMap, selectedSubjectId, dispatch, user.userDnsId]
    );

    useEffect(() => {
        const validSubjectOptions = getValidSubjectOptionsFromSubjects(nonFilteredSubjects, `${user.userDnsId}`);
        setSubjectsToChooseFrom(
            validSubjectOptions.map((option) => ({
                ...option,
                selected: false,
                deleted: false,
            }))
        );
    }, [nonFilteredSubjects, user]);

    useEffect(() => {
        const selectedSubject = nonFilteredSubjects.find(
            (subject) => subject.subjectMetadata.subjectIdToOwner.id === selectedSubjectId
        )!;
        const unitsToChooseFrom = getUnitOptionsFromSubject(selectedSubject, `${user.userDnsId}`, isTeacher);
        setUnitsToChooseFrom(
            unitsToChooseFrom.map((option) => ({
                ...option,
                selected: false,
                deleted: false,
            }))
        );
    }, [user.userDnsId, isTeacher, setUnitsToChooseFrom, nonFilteredSubjects, selectedSubjectId]);

    useEffect(() => {
        // use current state of messageParams for useT-strings with parameters for responseActions-text
        if (!messageParams.subjectName) return; // return if no changes made
        if (messageParams.moveCount === cardIds.length) {
            dispatch(
                responseAction.showResponse({
                    type: "SUCCESS",
                    text: [cardsChangedSuccessfully_t],
                })
            );
            refreshCardList?.();
        } else if (messageParams.moveCount > 0) {
            dispatch(
                responseAction.showResponse({
                    type: "WARNING",
                    text: [cardsChangedWarning_t],
                })
            );
        } else if (!messageParams.moveCount) {
            dispatch(
                responseAction.showResponse({
                    type: "ERROR",
                    text: [cardsChangedUnsuccessfully_t],
                })
            );
        } else {
            dispatch(
                responseAction.showResponse({
                    type: "ERROR",
                    text: [cardChangeFailed_t],
                })
            );
        }

        setMessageParams(() => initialMessageParams);

        if (initialSubjectId === selectedSubjectId) {
            dispatch(subjectsActions.loadSubjectUnits(selectedSubjectId));
        } else {
            history.push(`/manage/${selectedSubjectId}`);
        }
    }, [
        messageParams,
        dispatch,
        cardIds.length,
        initialSubjectId,
        cardsChangedSuccessfully_t,
        cardsChangedWarning_t,
        cardsChangedUnsuccessfully_t,
        cardChangeFailed_t,
        history,
        selectedSubjectId,
        refreshCardList,
    ]);

    return (
        <ChangeCardSubjectModal
            allUserSubjects={nonFilteredSubjects}
            subjectsOptions={subjectsToChooseFrom}
            selectedSubjectId={selectedSubjectId}
            unitOptions={unitsToChooseFrom}
            selectedUnitId={selectedUnitId}
            changeSubject={selectSubject}
            changeUnit={selectUnit}
            close={close}
            save={save}
            deleteSubject={onDeleteSubject}
            changeSubjectEdit={changeSubjectName}
            resetSubjectEdit={resetSubjectEdit}
            saveSubjectEdit={updateOrDeleteOrAddSubjects}
            saveUnitEdit={updateOrDeleteOrAddUnits}
            resetUnitEdit={resetUnitEdit}
            changeUnitEdit={changeUnitName}
            containsProtectedCards={containsProtectedCardIds}
        />
    );
};

export default ChangeCardSubjectModalWrapper;
