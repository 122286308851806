// LIBRARIES
import React, { useCallback, useEffect, useState } from "react";
import { ampli } from "../../../../ampli";

// MODELS
import { ActivityDateStatistic, SubjectData } from "p6m-subjects";

// COMPONENTS
import {
    Container,
    Header,
    Activity,
    Limit,
    DateHeader,
    DueHeader,
    LearnedHeader,
    Action,
    Statistics,
    Entry,
    DateText,
    DueText,
    LearnedText,
    Details,
    Detail,
    Label,
    Value,
    NoDataText,
} from "./styles";
import { useT } from "@transifex/react";
import { getReportActivityData } from "../../../../networking/reports";
import PhaseSixIcon from "../../../../components/basic/phaseSixIcon/PhaseSixIcon";
import { toDDMMYY } from "../../../../helpers/timeFormat";
import ReportTTHSettingView from "../../../../components/connected/reportTTHSetting";

export interface ReportsProps {
    ownerId?: string;
    selectedSubject: SubjectData;
    isOwnSubject: boolean;
}

const ReportActivity: React.FC<ReportsProps> = (props) => {
    const t = useT();
    const [selectedDay, setSelectedDay] = useState<number | null>(null);
    const [data, setData] = useState<Array<ActivityDateStatistic> | null>(null);

    const noData_t = t("There is no data to display.", { _tags: "report,activity,message" });

    const loadData = useCallback(async () => {
        const {
            data: { replyContent: subjectReport },
        } = await getReportActivityData(props.ownerId || "", props.selectedSubject.subjectMetadata.subjectIdToOwner);
        const dateList = subjectReport.dayActivityList
            .sort((a, b) => {
                if (new Date(a.date).getTime() < new Date(b.date).getTime()) return 1;
                return -1;
            })
            .slice(0, 30); // max 30 entries
        setData(dateList);
    }, [props.ownerId, props.selectedSubject.subjectMetadata.subjectIdToOwner]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    useEffect(() => {
        ampli.openReportsTab({
            reports_tab: "activity",
        });
    }, []);

    const dateHeader_t = t("Date", { _tags: "title,report" });
    const dueHeader_t = t("Due", { _tags: "title,report" });
    const learnedHeader_t = t("Learned", { _tags: "title,report" });
    const today_t = t("Today", { _tags: "date,label,report" });
    const regularLearned_t = t("Learned regularly", { _tags: "label,report" });
    const learnedForTest_t = t("Learned for test", { _tags: "label,report" });
    const newVocabulary_t = t("New cards", { _tags: "label,report" });
    const learningTime_t = t("Learning time (in min)", { _tags: "label,report" });

    const showDetails = (index: number) => {
        if (selectedDay === index) {
            // close open details
            setSelectedDay(null);
        } else {
            setSelectedDay(index);
        }
    };

    return (
        <Container>
            <Header>
                <DateHeader>{dateHeader_t}</DateHeader>
                <DueHeader>{dueHeader_t}</DueHeader>
                <LearnedHeader>{learnedHeader_t}</LearnedHeader>
                <Action />
            </Header>
            <Activity>
                {data &&
                    (data.length > 0 ? (
                        data.map((day, index) => {
                            const selected = index === selectedDay;
                            const now = new Date();
                            const dayDate = new Date(day.date);
                            const isToday =
                                now.getDate() === dayDate.getDate() &&
                                now.getMonth() === dayDate.getMonth() &&
                                now.getFullYear() === dayDate.getFullYear();
                            const minutesLearned = Math.ceil(day.learningDuration / 60);
                            return (
                                <Entry key={"day_" + index}>
                                    <Statistics onClick={() => showDetails(index)}>
                                        <DateText>{isToday ? today_t : toDDMMYY(day.date)}</DateText>
                                        <DueText>{day.tthLimit}</DueText>
                                        <LearnedText>{day.dueCards}</LearnedText>
                                        <Action>
                                            <PhaseSixIcon name={selected ? "chevron-up" : "chevron-down"} />
                                        </Action>
                                    </Statistics>
                                    {selected && (
                                        <Details>
                                            <Detail>
                                                <Label>{regularLearned_t}</Label>
                                                <Value>{day.dueCards.toLocaleString("de-DE")}</Value>
                                            </Detail>
                                            <Detail>
                                                <Label>{learnedForTest_t}</Label>
                                                <Value>{day.notDueCards.toLocaleString("de-DE")}</Value>
                                            </Detail>
                                            <Detail>
                                                <Label>{newVocabulary_t}</Label>
                                                <Value>{day.newCards.toLocaleString("de-DE")}</Value>
                                            </Detail>
                                            <Detail>
                                                <Label>{learningTime_t}</Label>
                                                <Value>{minutesLearned}</Value>
                                            </Detail>
                                        </Details>
                                    )}
                                </Entry>
                            );
                        })
                    ) : (
                        <NoDataText>{noData_t}</NoDataText>
                    ))}
            </Activity>
            <Limit>{props.isOwnSubject && <ReportTTHSettingView onUpdate={loadData} />}</Limit>
        </Container>
    );
};

export default ReportActivity;
