import React, { FunctionComponent } from "react";
import { NavLink } from "react-router-dom";
import Badge from "../../../basic/badge/Badge";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";
import { useT } from "@transifex/react";
import Tooltip from "../../../basic/tooltip/Tooltip";
import styled from "styled-components";
import { ampli } from "../../../../ampli";

export type Props = {
    count?: number;
};

const StyledPhaseSixIcon = styled(PhaseSixIcon)`
    color: inherit;
`;

const TrophyItem: FunctionComponent<Props> = ({ count }) => {
    const t = useT();
    const tooltipText = t("Achievements", { _tags: "achievements,header,tooltip" });
    return (
        <Tooltip content={tooltipText}>
            <NavLink
                activeClassName="is-active"
                to="/achievements"
                data-qa="achievements-link"
                onClick={() => ampli.openedAchievements()}
            >
                {count ? (
                    <Badge
                        data-qa="achievements-counter"
                        count={count}
                        bigIcon
                    >
                        <StyledPhaseSixIcon
                            name="cup"
                            size={"medium"}
                        />
                    </Badge>
                ) : (
                    <StyledPhaseSixIcon
                        name="cup"
                        size={"medium"}
                    />
                )}
            </NavLink>
        </Tooltip>
    );
};

export default TrophyItem;
