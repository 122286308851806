import { SubjectUnitData, UnitsList } from "p6m-subjects";
import { defaultUnitIdPrefix } from "../constants/ContentConstants";

export function getDefaultUnitId(subjectId: string): string {
    return defaultUnitIdPrefix + subjectId;
}

export function isDefaultUnitId(id: string): boolean {
    return id.startsWith(defaultUnitIdPrefix);
}

export function isDefaultUnit(unit: SubjectUnitData): boolean {
    const {
        unitId: { id },
    } = unit;
    return isDefaultUnitId(id);
}

export function sortUnitsCallback(firstUnit: SubjectUnitData, secondUnit: SubjectUnitData): number {
    if (isDefaultUnit(firstUnit)) return 1;
    if (isDefaultUnit(secondUnit)) return -1;
    const {
        unitContent: { modificationDate },
    } = firstUnit;
    const {
        unitContent: { modificationDate: secondModifDate },
    } = secondUnit;
    const date = new Date(modificationDate).getTime();
    const secondDate = new Date(secondModifDate).getTime();

    return date - secondDate;
}

// Finding the max order in the units that are not general units, then using next number.
export const getNextUnitOrder = (units?: UnitsList): number => {
    let nextUnitOrderValue = 1;
    if (units && units.length) {
        const unitOrders = units.map((u) => (isDefaultUnit(u) ? 0 : u.unitContent.order || 0));
        const maxOrder = Math.max(...unitOrders);

        if (!isNaN(maxOrder)) {
            nextUnitOrderValue = maxOrder + 1;
        }
    }
    return nextUnitOrderValue;
};
