//LIBRARIES
import React from "react";
import styled from "styled-components";
import { T, useT } from "@transifex/react";

//COMPONENTS
import { DefaultHeader } from "../../basic/modal/DefaultHeader";

//TYPES
import { GlobalModalProps } from "../../../helpers/Modal";

interface DeleteMediaModalProps extends GlobalModalProps {
    onDeleteMedia: () => void;
}

const Background = styled.div`
    height: 100%;
    width: 100%;
    padding: 0;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    overflow: hidden;
`;
const Content = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: ${(props) => props.theme.base.spacing};
    background: ${(props) => props.theme.colors.backgroundMain};
    border-bottom: 1px solid ${(props) => props.theme.colors.textLight};
`;
const ButtonsContainer = styled.div`
    width: 100%;
    padding: ${(props) => props.theme.base.spacingTiny};
    background: ${(props) => props.theme.colors.backgroundMain};
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`;
const Button = styled.span<{ color?: string }>`
    margin-left: ${(props) => props.theme.base.spacing};
    padding: ${(props) => props.theme.base.spacingTiny};
    border-radius: ${(props) => props.theme.base.borderRadiusSmall};
    background: ${(props) => (props.color === "primary" ? props.theme.colors.red : props.theme.colors.backgroundMain)};
    color: ${(props) => (props.color === "primary" ? props.theme.colors.backgroundContrast : props.theme.colors.red)};
    text-transform: capitalize;
    cursor: pointer;
`;
const Description = styled.p``;

const DeleteMediaModal: React.FC<DeleteMediaModalProps> = (props) => {
    const t = useT();

    const delete_title_t = t("Delete Media", { _tags: "media,modal,title" });
    const cancel_t = t("Cancel", { _tags: "media,modal,button" });
    const delete_t = t("Delete", { _tags: "media,modal,button" });

    return (
        <Background>
            <DefaultHeader
                variant="error"
                title={delete_title_t}
                close={props.close}
            />
            <Content>
                <Description>
                    <T _str="Are you sure you want to delete this media item?" />
                </Description>
            </Content>
            <ButtonsContainer>
                <Button onClick={props.close}>{cancel_t}</Button>
                <Button
                    onClick={props.onDeleteMedia}
                    color={"primary"}
                >
                    {delete_t}
                </Button>
            </ButtonsContainer>
        </Background>
    );
};

export default DeleteMediaModal;
