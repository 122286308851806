// LIBRARIES
import React, { FunctionComponent, useState, useMemo, useCallback } from "react";
import { useT } from "@transifex/react";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import { selectors as subjectSelectors, actions as subjectActions } from "../../../../redux/subjects/subjectsSlice";
import { selectors as userSelectors } from "../../../../redux/user/userSlice";
import { selectors, actions } from "../../../../redux/add/addSlice";

// TYPES
import { SubjectData } from "p6m-subjects";

// COMPONENTS
import Component, { Props as ComponentProps } from "./UnitsMenu";

// HELPERS
import { generateUuid } from "../../../../helpers/Id";
import { sortUnitsCallback, isDefaultUnit, getNextUnitOrder } from "../../../../helpers/Units";
import { useSelectFirstItem } from "../../../../hooks/useSelectFirstItem";

type Props = {
    onToggle?: ComponentProps["onToggle"];
};

export const UnitsMenu: FunctionComponent<Props> = (props) => {
    const { onToggle } = props;
    const subjectId = useSelector(selectors.getAddCardsSubjectId);
    const unitId = useSelector(selectors.getAddCardsUnitId);
    const userDnsId = useSelector(userSelectors.userId);
    const units = useSelector(subjectSelectors.getUnitsBySubjectId(subjectId || "", "librarySubjects")) || [];
    const [deleteCardsCount, setDeleteCardsCount] = useState<number>(0);
    const dispatch = useDispatch();

    const defaultUnit = useGetDefaultUnit();

    const disableEdit = useMemo(() => {
        if (!units || !units.length) return false;

        const {
            unitId: { ownerId },
        } = units[0];
        const isMySubject: boolean = ownerId === userDnsId;

        return !isMySubject;
    }, [units, userDnsId]);

    const componentUnits = useMemo(() => {
        const items = [...units].sort(sortUnitsCallback).map((unit) => {
            const {
                unitContent: { name },
                unitId: { id, ownerId },
            } = unit;

            const isMySubject: boolean = ownerId === userDnsId;
            const isDefault = isDefaultUnit(unit);

            return {
                component: name,
                value: name,
                key: id,
                notDeletable: !isMySubject || isDefault,
                notEditable: !isMySubject || isDefault,
            };
        });

        const hasDefaultUnit = !!units.find(isDefaultUnit);

        if (!hasDefaultUnit) items.push(defaultUnit);

        return items;
    }, [units, userDnsId, defaultUnit]);

    const unitKeys = useMemo(() => componentUnits.map(({ key }) => key), [componentUnits]);

    const selectKey = useCallback((key: string) => dispatch(actions.setCardUnitId(key)), [dispatch]);

    useSelectFirstItem(unitKeys, unitId, selectKey);

    const findUnit = useCallback(
        (key: string) => {
            if (!units) return;
            return units.find((unit: Required<SubjectData>["units"][0]) => {
                const {
                    unitId: { id },
                } = unit;

                return key === id;
            });
        },
        [units]
    );

    const onSelect = useCallback(
        (selected: Required<ComponentProps>["items"][0]) => {
            dispatch(actions.setCardUnitId(selected.key as string));
        },
        [dispatch]
    );

    const onSaveCallback = useCallback(
        (data) => {
            const dataMapRelation: any = {
                deleted: ({ key }: { key: string }) => {
                    const unit = findUnit(key);
                    if (!unit) return undefined;

                    const {
                        unitId: { id },
                        unitContent: {
                            subjectIdToOwner: { ownerId },
                        },
                    } = unit;

                    return { unitId: id, ownerId, name: unit.unitContent.name };
                },
                updated: ({ key, value }: { key: string; value: string }) => {
                    const unit = findUnit(key);
                    if (!unit) return undefined;

                    const {
                        unitId: { id: unitId },
                        unitContent: {
                            subjectIdToOwner: { ownerId, id },
                        },
                    } = unit;

                    return {
                        unitId,
                        ownerId,
                        subjectId: id,
                        name: value,
                    };
                },
                created: (value: string) => {
                    return {
                        unitId: generateUuid(),
                        ownerId: userDnsId,
                        subjectId,
                        order: getNextUnitOrder(units),
                        name: value,
                    };
                },
            };

            const result: any = {};

            Object.entries(data).forEach(([key, values = []]: any) => {
                result[key] = values.map((value: any) => dataMapRelation[key](value));
            });

            if (result.created && result.created.length) {
                dispatch(actions.setCardUnitId(result.created[0].unitId));
            }

            dispatch(subjectActions.complexUnitsRest(result));
        },
        [findUnit, dispatch, userDnsId, subjectId]
    );

    const onDeleteCallback = useCallback(
        (item: any) => {
            const { key, deleted } = item;
            if (!key) return;

            const unit = findUnit(key);
            if (!unit) return;

            const { cardCount = 0 } = unit;
            if (!cardCount) return;

            const method = [
                (currentCount: number) => currentCount - cardCount,
                (currentCount: number) => currentCount + cardCount,
            ][Number(deleted)];

            setDeleteCardsCount((currentCount) => method(currentCount));
        },
        [findUnit]
    );

    const onCloseEditCallback = useCallback(() => {
        setDeleteCardsCount(0);
    }, []);

    return (
        <Component
            items={componentUnits}
            selected={unitId}
            onSelect={onSelect}
            disableEdit={disableEdit}
            editItems={componentUnits}
            onSave={onSaveCallback}
            deleteCardsCount={deleteCardsCount}
            onDelete={onDeleteCallback}
            onCloseEdit={onCloseEditCallback}
            onToggle={onToggle}
        />
    );
};

function useGetDefaultUnit() {
    const t = useT();
    const t_defaultUnit = t("General", {});

    return useMemo(
        function () {
            return {
                component: t_defaultUnit,
                value: t_defaultUnit,
                key: "default",
                notDeletable: true,
                notEditable: true,
            };
        },
        [t_defaultUnit]
    );
}
