// REACT
import React, { FunctionComponent, useCallback, memo } from "react";

// COMPONENTS
import Input from "./EditDropdownInput";

export type Props = {
    onCreate: () => void;
    onChange: (value: string) => void;
    error?: string;
    value?: string;
};

const CreateInput: FunctionComponent<Props> = (props) => {
    const { onCreate, onChange, error, value = "" } = props;

    const onChangeCallback = useCallback(
        (value) => {
            onChange(value);
        },
        [onChange]
    );

    const onCreateCallback = useCallback(() => {
        onCreate();
    }, [onCreate]);

    return (
        <Input
            value={value}
            onCreate={onCreateCallback}
            onChange={onChangeCallback}
            onSubmit={onCreate}
            error={error}
        />
    );
};

export default memo(CreateInput);
