import styled from "styled-components";
import { Item as ListItem } from "../dropdownSelection/DropdownList/styles";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";

import { Header as ExtendHeader, Footer as ExtendFooter } from "../dropdownSelection/DropdownList/styles";

export { Container, Content } from "../dropdownSelection/DropdownList/styles";

export const Header = styled(ExtendHeader)`
    padding: 0;
`;

export const Footer = styled(ExtendFooter)`
    padding-top: ${({ theme: { base } }) => base.spacingSmall};
    padding-bottom: 0;
`;

export const ComponentWrapper = styled(ListItem)`
    cursor: default;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:hover {
        color: ${({ theme: { colors } }) => colors.text};
    }
`;

export const InputWrapper = styled.div`
    width: 100%;
`;

export const Error = styled.div`
    color: #d2002c;
    font-size: ${({ theme: { base } }) => base.smallSize};
    padding-left: 5px;
`;

export const Input = styled.input<{ disabled?: boolean; $deleted: boolean }>`
    margin-left: 5px;
    height: 30px;
    width: calc(100% - 5px); // to offset the margin
    padding: 0 5px;
    border-radius: 5px;
    background-color: ${({ theme: { colors } }) => colors.backgroundMain};
    border: none;
    font-size: 14px;
    line-height: 30px;
    color: ${({ theme: { colors } }) => colors.text};

    opacity: ${({ disabled = false }) => (disabled ? 0.5 : 1)};

    &:focus {
        outline: none;
    }

    ${({ $deleted = false }) =>
        $deleted &&
        `
        background-color: transparent;
        color: #D2002C;
        text-decoration: line-through;
        opacity: 1;
        cursor: default;
        &:focus {
            outline: none;
        }
    `}

    ${({ disabled = false }) =>
        disabled &&
        `
        background-color: transparent;
        cursor: default;
        &:focus {
            outline: none;
        }
    `}
`;

export const IconWrapper = styled.div<{ disabled: boolean; $deleted: boolean }>`
    width: auto;
    cursor: ${({ disabled }) => (!disabled ? "pointer" : "not-allowed")};
    opacity: ${({ disabled }) => (!disabled ? 1 : 0.5)};

    ${({ $deleted = false }) =>
        $deleted &&
        `
        color: #D2002C;
    `}

    &:hover {
        ${({ disabled, $deleted, theme: { colors } }) =>
            !disabled &&
            !$deleted &&
            `
        color: ${colors.primary};
      `};
    }
`;

export const StyledPhaseSixIcon = styled(PhaseSixIcon)`
    color: inherit;
`;

export const CloseButton = styled.button`
    width: 100%;
    padding: 5px ${({ theme: { base } }) => base.spacingSmall};
    background: none;
    border: 1px solid transparent;
    border-radius: ${({ theme: { base } }) => base.borderRadius};
    font-size: ${({ theme: { base } }) => base.size};
    color: ${({ theme: { colors } }) => colors.primary};
    cursor: pointer;
    transition: ${({ theme: { base } }) => base.transition};

    &:hover {
        border-color: ${({ theme: { colors } }) => colors.primary};
    }
`;

export const SaveButton = styled(CloseButton)`
    background: ${({ theme: { colors } }) => colors.primary};
    border-color: ${({ theme: { colors } }) => colors.primary};
    color: ${({ theme: { colors } }) => colors.backgroundContrast};

    &:hover {
        opacity: ${({ theme: { base } }) => base.hoverOpacity};
    }
`;

export const AlertText = styled.p`
    margin: 0;
    font-size: ${({ theme: { base } }) => base.size};
    color: #d2002c;
`;
