// LIBRARIES
import React from "react";

// CONSTANTS
import ResponseConstants, { ResponseType } from "../../../constants/ResponseConstants";

//COMPONENTS
import {
    StyledText,
    StyledOuterContainer,
    StyledSuccessContainer,
    StyledWarningContainer,
    StyledErrorContainer,
} from "./styles";

export interface ResponseProps {
    show: boolean;
    text: string[];
    cancelResponse: () => void;
    type: ResponseType;
}

const Response: React.FC<ResponseProps> = (props) => {
    const { text } = props;
    if (props.show) {
        let content = text.map((txt, index) => {
            return <StyledText key={"response_" + index}>{txt}</StyledText>;
        });

        let innerContainer = (
            <StyledSuccessContainer
                onClick={props.cancelResponse}
                children={content}
            />
        );

        if (props.type === ResponseConstants.ERROR)
            innerContainer = (
                <StyledErrorContainer
                    onClick={props.cancelResponse}
                    children={content}
                />
            );
        if (props.type === ResponseConstants.WARNING)
            innerContainer = (
                <StyledWarningContainer
                    onClick={props.cancelResponse}
                    children={content}
                />
            );
        return <StyledOuterContainer>{innerContainer}</StyledOuterContainer>;
    }
    return null;
};

export default Response;
