// REACT
import React, { FunctionComponent, useMemo, useCallback, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import { actions as goalsActions } from "../../../../../redux/goals/goalsSlice";
import { actions, selectors } from "../../../../../redux/learning/learningSlice";
import { selectors as themeSelectors } from "../../../../../redux/theme/themeSlice";

// UTILS
import { Theme } from "../../../../../themes/constants";
import { ampli, CancelPracticePxpProperties } from "../../../../../ampli";
import { ThemeContext } from "styled-components";

// TYPES
import { Props as ModalProps } from "../../../../basic/modal/Modal";

// COMPONENTS
import Component from "./Exit";

type Props = Partial<ModalProps>;

export const Exit: FunctionComponent<Props> = (props) => {
    const practiced = useSelector(selectors.filteredItems({ type: "practice" }));
    const practiceLogParams = useSelector(selectors.practiceLogParams);
    const themeName: Theme = useSelector(themeSelectors.themeName);
    const theme = useContext(ThemeContext);

    const history = useHistory();
    const dispatch = useDispatch();

    const { testId } = useParams<{ testId?: string }>();

    const backgroundColor = theme.colors.backgroundContrast;

    const finished: number = useMemo(() => {
        return practiced.filter(({ resolved }) => resolved === true).length;
    }, [practiced]);

    const image = useMemo(() => {
        const relation: Record<Theme, string> = {
            [Theme.MAIN]: "grayTheme-smiley-0.png",
            [Theme.DARK]: "darkTheme-smiley-0.png",
            [Theme.ORANGE]: "orangeTheme-smiley-0.png",
            [Theme.PINK]: "pinkTheme-smiley-0.png",
            [Theme.BLUE]: "blueTheme-smiley-0.png",
        };
        return relation[themeName];
    }, [themeName]);

    const onGoHome = useCallback(() => {
        if (practiceLogParams) {
            const { cards_for_practice, book_has_accessment_mode, activation_mode, mode } = practiceLogParams;

            const practiceCancelledProps: CancelPracticePxpProperties = {
                cards_for_practice,
                book_has_accessment_mode,
                activation_mode,
                mode,
                cards_learned: finished,
            };

            if (
                practiceCancelledProps.mode === "exercise_moved_to_practice" ||
                practiceCancelledProps.mode === "exercise_only"
            ) {
                practiceCancelledProps.test_origin = "teacher";
                practiceCancelledProps.exercise_id = testId;
            }
            ampli.cancelPracticePxp(practiceCancelledProps);
        }

        dispatch(goalsActions.fetchGoals());
        history.push("/home");
    }, [dispatch, history, practiceLogParams, finished]);

    const closeModal = useCallback(() => {
        dispatch(actions.removeModal("exit"));
    }, [dispatch]);

    return (
        <Component
            finished={finished}
            image={image}
            backgroundColor={backgroundColor}
            onGoHome={onGoHome}
            closeModal={closeModal}
            {...props}
        />
    );
};
