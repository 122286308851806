import styled from "styled-components";
import { FilledButton as FilledBtn } from "../../basic/button/Button";
import PhaseSixIcon from "../../basic/phaseSixIcon/PhaseSixIcon";

export const Background = styled.div`
    height: 100%;
    width: 100%;
    padding: ${(props) => props.theme.base.spacingExtraBig};
    border-radius: ${({ theme: { base } }) => base.borderRadius};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    overflow: hidden;
    background: ${(props) => props.theme.colors.backgroundMain};
`;
export const Title = styled.h1`
    text-align: center;
    font-size: ${(props) => props.theme.base.titleSize};
    font-family: ${(props) => props.theme.base.fontFamily};
    text-decoration: none;
    color: ${(props) => props.theme.colors.primary};
    outline: none;
`;

export const CloseButton = styled.button`
    display: inline-block;
    background: none;
    border: none;
    margin-left: auto;
    cursor: pointer;
    margin-bottom: -30px;
    z-index: 1;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.colors.text};
`;

export const Description = styled.div`
    margin: 0 80px;
    text-align: center;
    font-size: ${(props) => props.theme.base.mediumSize};
`;

export const SupportLink = styled.a`
    text-decoration: none;
    color: ${(props) => props.theme.colors.primary};
`;

export const ExtraInfo = styled.div`
    margin-top: ${(props) => props.theme.base.spacingExtraBig};
    margin-bottom: ${(props) => props.theme.base.spacingInContent};
    font-size: ${(props) => props.theme.base.smallSize};
`;

export const ButtonsContainer = styled.div`
    width: 100%;
    padding: ${(props) => props.theme.base.spacing};
    background: ${(props) => props.theme.colors.backgroundMain};
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${(props) => props.theme.base.spacing};
`;

export const SelectableSquareDiv = styled.div<{ highlighted?: boolean }>`
    cursor: pointer;
    height: 18px;
    width: 18px;
    border: 1px solid ${(props) => (props.highlighted ? props.theme.colors.primary : props.theme.colors.text)};
    border-radius: 4px;

    & > i {
        position: absolute;
        font-size: ${(props) => props.theme.base.cardPracticeTextSize};
        margin: -4px 0 0 -4px;
        color: ${(props) => (props.highlighted ? props.theme.colors.primary : props.theme.colors.text)};
    }
`;

export const DontShowAgainInfo = styled.div`
    display: flex;
    gap: ${(props) => props.theme.base.spacingInContent};
    align-items: center;
    color: ${(props) => props.theme.colors.text};
`;

export const FilledButton = styled(FilledBtn)`
    width: 50%;
    text-transform: uppercase;
`;
