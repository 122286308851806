import styled from "styled-components";

export const Button = styled.div`
    margin: 0;
    font-size: ${({ theme: { base } }) => base.titleSize};
    color: ${({ theme: { colors } }) => colors.text};
    font-weight: normal;
    cursor: pointer;
    display: flex;
    align-items: center;
`;
