import styled from "styled-components";

export const Background = styled.div`
    height: 100%;
    width: 100%;
    padding: 0;
    border-radius: ${({ theme: { base } }) => base.borderRadius};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    overflow: hidden;
`;
export const Content = styled.div`
    width: 100%;
    height: calc(
        440px + 50px + (2 * ${(props) => props.theme.base.spacing})
    ); // 440px is dropdown height - 50px is dropdown title
    padding: ${(props) => props.theme.base.spacing};
    background: ${(props) => props.theme.colors.backgroundMain};
`;
export const DropdownContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;
export const Warning = styled.p`
    padding: ${(props) => props.theme.base.spacing} 0;
    color: ${(props) => props.theme.colors.red};
`;
export const ButtonsContainer = styled.div`
    width: 100%;
    padding: ${(props) => props.theme.base.spacing};
    background: ${(props) => props.theme.colors.backgroundMain};
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`;
export const Button = styled.span<{ highlighted?: boolean }>`
    margin-left: ${(props) => props.theme.base.spacing};
    min-width: ${(props) => props.theme.base.buttonMinWidth};
    padding: ${(props) => props.theme.base.spacingSmall};
    border-radius: ${(props) => props.theme.base.borderRadiusSmall};
    background: ${(props) => (props.highlighted ? props.theme.colors.primary : props.theme.colors.backgroundMain)};
    color: ${(props) => (props.highlighted ? props.theme.colors.backgroundContrast : props.theme.colors.text)};
    border: ${(props) => (props.highlighted ? "" : `1px solid ${props.theme.colors.text}`)};
    text-transform: uppercase;
    text-align: center;
    font-size: ${({ theme: { base } }) => base.bigSize};
    cursor: pointer;
`;
