// REACT
import React from "react";

// STYLED COMPONENTS
import { StyledButton, StyledButtonText } from "./styles";

export interface BlankButtonProps {
    text: string;
    onClick: () => void;
    disabled?: boolean;
    className?: string;
    textStyle?: any;
}

const BlankButton: React.FC<BlankButtonProps> = (props) => {
    return (
        <StyledButton {...props}>
            <StyledButtonText style={!!props.textStyle ? props.textStyle : {}}>{props.text}</StyledButtonText>
        </StyledButton>
    );
};

export default BlankButton;
