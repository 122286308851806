// REACT
import React, { FunctionComponent, useState, memo } from "react";

// LIBRARIES
import { ampli } from "../../../../../ampli";
import { T, useT } from "@transifex/react";

// COMPONENTS
import Modal, { Props as ModalProps } from "../../../../basic/modal/Modal";
import { Col, Row } from "../../../../basic/grid";

// UTILS
import { getSmileImage } from "../../../../../helpers/smiles";
import { getSharePracticeResultUrl, ShareType } from "../../../../../helpers/Share";

// STYLED COMPONENTS
import {
    Button,
    FilledButton,
    InfoContainer,
    ModalHeader,
    ResultContainer,
    ShareLink,
    ShareMailLink,
    ShareResult,
    Star,
    StarBg,
    StarContainer,
    StarWrapper,
    SubTitle,
    StyledIcon,
    InfoContainerDivider,
    CloseIcon,
    CloseIconWrapper,
} from "./styles";
import { calculatePercentage } from "../../../../../helpers/calculatePercentage";

export const StarIcon: FunctionComponent<{ active?: boolean }> = ({ active = false }) => (
    <StarWrapper>
        <Star active={active}>
            <StyledIcon name="star-full" />
        </Star>
        <StarBg>
            <StyledIcon name="star-full" />
        </StarBg>
    </StarWrapper>
);

export type Props = {
    total: number;
    possibleToday: number;
    atFirst: number;
    backgroundColor: string;
    userId: string;
    userStars: number;
    subjectId: string;
    subjectIsTrial: boolean;
    isChild: boolean;
    onGoHome: () => void;
    onBuyContent: () => void;
    onPracticeMore: () => void;
} & Partial<ModalProps>;

const PracticeDone: FunctionComponent<Props> = (props) => {
    const { total, atFirst, subjectIsTrial, onGoHome, onPracticeMore, onBuyContent, possibleToday, ...restProps } =
        props;
    const t = useT();
    const [shareLink, setShareLink] = useState("");
    const [emailLink, setEmailLink] = useState("");
    const [shareLinkCreated, setShareLinkCreated] = useState(false);

    const percent: number = calculatePercentage({ totalAmount: total, fractionAmount: atFirst });

    type TitleType = { [key: string]: string };

    const titles: TitleType = {
        "10": "You will improve soon",
        "20": "Keep going to improve.",
        "30": "Not that bad. Keep going!",
        "40": "Good job!",
        "50": "That was pretty good",
        "60": "Most of the words were correct",
        "70": "Keep going like this!",
        "80": "Cool. Great job.",
        "99": "Hey, that was awesome!",
        "100": "Wow, you got it all right!",
    };

    const mailBody_t = t("Results of my learning session:", { _tags: "email,body,colon" });
    const mailSubject_t = t("Today I've been hard-working!", { _tags: "email,subject,exclamation" });

    const shareTraining = (type: ShareType) => {
        const shareLink = getSharePracticeResultUrl(
            type,
            props.userId,
            props.subjectId,
            props.subjectIsTrial,
            props.total,
            props.atFirst,
            props.userStars
        );
        const emailLink = "mailto:?body=" + mailBody_t + " " + shareLink + "&subject=" + mailSubject_t;
        ampli.shareApp({
            app_screen: "practice",
        });
        setShareLink(shareLink);
        setEmailLink(emailLink);
        copyToClipboard(shareLink);
        setShareLinkCreated(true);
    };

    const copyToClipboard = (text: string) => {
        const textField = document.createElement("textarea");
        textField.innerText = text;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand("copy");
        textField.remove();
    };

    const key = Object.keys(titles).find((key) => percent <= parseInt(key)) || "10";

    const title: string = (titles as TitleType)[key];

    const renderShareText = () => {
        if (shareLinkCreated) {
            return (
                <>
                    <T _str="Link has been copied to clipboard. " />
                    <T
                        _str="You can {copyItAgain} or send it {viaEmail}"
                        copyItAgain={
                            <ShareLink onClick={() => copyToClipboard(shareLink)}>
                                <T _str="copy it again" />
                            </ShareLink>
                        }
                        viaEmail={
                            <ShareMailLink href={emailLink}>
                                <T _str="via Email" />
                            </ShareMailLink>
                        }
                    />
                </>
            );
        } else if (props.isChild) {
            return (
                <T
                    _str="Share the result with a {parent} or {friend}"
                    parent={
                        <ShareLink onClick={() => shareTraining(ShareType.parent)}>
                            <T _str="parent" />
                        </ShareLink>
                    }
                    friend={
                        <ShareLink onClick={() => shareTraining(ShareType.friend)}>
                            <T _str="friend" />
                        </ShareLink>
                    }
                />
            );
        }
        return (
            <T
                _str="Share the result with a {friend}"
                friend={
                    <ShareLink onClick={() => shareTraining(ShareType.friend)}>
                        <T _str="friend" />
                    </ShareLink>
                }
            />
        );
    };

    return (
        <Modal
            active={true}
            headerComponent={false}
            footerComponent={false}
            modalBodyStyle={{
                backgroundColor: props.backgroundColor,
                paddingBottom: "20px",
            }}
            preventCloseOnBg
            {...restProps}
        >
            <Row horizontal="between">
                {subjectIsTrial && <Col xs={1} />}
                <Col
                    xs="auto"
                    horizontal={"center"}
                >
                    <ModalHeader>
                        <T
                            _str={title}
                            _tags="practice"
                        />
                    </ModalHeader>
                </Col>
                {subjectIsTrial && (
                    <Col xs={1}>
                        <CloseIconWrapper onClick={onGoHome}>
                            <CloseIcon name="close" />
                        </CloseIconWrapper>
                    </Col>
                )}
            </Row>
            <SubTitle>
                <T
                    _str="{cards} at the first attempt!"
                    cards={
                        <span>
                            <T
                                _str="{atFirst} cards"
                                atFirst={atFirst}
                            />
                        </span>
                    }
                    _tags="practice"
                />
            </SubTitle>
            <InfoContainer>
                <Row horizontal="around">
                    <Col xs="auto">
                        <p>
                            <span>
                                {total} <StyledIcon name="annotation" />
                            </span>
                            <br />
                            <T _str="practised in total" />
                        </p>
                    </Col>
                    <InfoContainerDivider />
                    <Col xs="auto">
                        <p>
                            <span>{percent}%</span>
                            <br />
                            <T _str="at the first attempt!" />
                        </p>
                    </Col>
                </Row>
            </InfoContainer>
            <ResultContainer>
                <StarContainer>
                    <StarIcon active={percent >= 33} />
                    <StarIcon active={percent >= 66} />
                    <StarIcon active={percent >= 99} />
                </StarContainer>
                <img
                    src={getSmileImage(`smiley-${key}.png`)}
                    alt="smile"
                />
            </ResultContainer>
            <ShareResult>
                <StyledIcon name="share" />
                &nbsp;
                {renderShareText()}
            </ShareResult>
            <Row horizontal="center">
                <Button
                    autoFocus
                    onClick={onGoHome}
                >
                    <StyledIcon name="house" />
                    &nbsp;
                    <T _str="Back to learning centre" />
                </Button>
                {possibleToday > 0 && (
                    <FilledButton onClick={onPracticeMore}>
                        <StyledIcon name="learn" />
                        &nbsp;{" "}
                        <T
                            _str="Practice more"
                            _tags="button,EOP"
                        />{" "}
                        ({possibleToday})
                    </FilledButton>
                )}
            </Row>
        </Modal>
    );
};

export default memo(PracticeDone);
