// LIBRARIES
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { T, useT } from "@transifex/react";

// MODELS
import { SubjectData } from "p6m-subjects";
import { FamilyMember, User, UserPreferences } from "p6m-user";
import { ReportScreenType } from "p6m-report";

// COMPONENTS
import PhaseSixIcon from "../../components/basic/phaseSixIcon/PhaseSixIcon";
import ReportPhases from "./pages/phases/ReportPhases";
import ReportActivity from "./pages/activity/ReportActivity";
import ReportDifficult from "./pages/difficult/ReportDifficult";
import ReportPreview from "./pages/preview/ReportPreview";
import ReportControl from "./pages/control/ReportControl";
import Modal from "../../components/basic/modal/Modal";
import PlusFlag from "../../components/basic/plusFlag/PlusFlag";

// STATE
import { actions as modalActions } from "../../redux/modal/modalSlice";

// HELPERS
import { getBuyPremiumUrl } from "../../helpers/Url";
import { toDDMMYY } from "../../helpers/timeFormat";
import { GlobalModalView } from "../../helpers/Modal";

// STYLES
import { InfoSubtext, InfoText, LinkContainer, ModalTextContainer, PrimaryLink } from "./pages/phases/styles";
import {
    Content,
    Header,
    LastPractice,
    SubjectReportContainer,
    SubjectTitle,
    Tab,
    TabContent,
    TabMenu,
    TabText,
    Title,
} from "./styles";

export interface SubjectReportProps {
    user: User;
    member?: FamilyMember;
    hasPremium: boolean;
    isStrictMode: boolean;
    userPreferences: UserPreferences;
    screenType: ReportScreenType;
    selectedSubject: SubjectData;
    isOwnSubject: boolean;
    changeScreen: (type: ReportScreenType) => void;
    onSubjectSelect: (subjectId: string | null) => void;
}

const SubjectReport: React.FC<SubjectReportProps> = (props) => {
    const t = useT();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);

    const lastPracticed_t = t("last practiced: ", { _tags: "report,header,colon" });
    const menuTabKeys: { [key in ReportScreenType]?: JSX.Element } = {
        PHASES: (
            <T
                _str="Phases"
                _tags="report,type,tab,button"
            />
        ),
        ACTIVITY: (
            <T
                _str="Activity"
                _tags="report,type,tab,button"
            />
        ),
        PREVIEW: (
            <T
                _str="7-day preview"
                _tags="report,type,tab,button"
            />
        ),
        DIFFICULT: (
            <T
                _str="Difficult cards"
                _tags="report,type,tab,button"
            />
        ),
        CONTROL: (
            <T
                _str="Input control"
                _tags="report,type,tab,button"
            />
        ),
    };

    const buyPlusModalTitle = <T _str="Unlock reports" />;
    const buyPlusTitle = <T _str="More insight, more motivation!" />;
    const buyPlusSubTitle = (
        <T _str="keep track of your learning progress with the help of reports and thereby optimize your learning success" />
    );
    const buyPlusIntendedForChildSubTitle = (
        <T _str="Stay on top of your child's learning activity with the reports. Get the PLUS functionalities and then transfer them to your child in 'Mein Konto'." />
    );
    const plusButtonText = <T _str="Unlock PLUS features" />;

    const plusFlag = props.hasPremium ? null : <PlusFlag />;
    const isSubjectReportOfChild = !props.isOwnSubject && props.member?.userId;
    const shopPlusUrl = getBuyPremiumUrl();

    const goToTab = (screen: ReportScreenType) => {
        if (props.hasPremium || screen === "PHASES") {
            props.changeScreen(screen);
        } else if (props.isStrictMode) {
            dispatch(modalActions.setModalView(GlobalModalView.StrictPremium));
        } else {
            setShowModal(true);
        }
    };

    const renderContent = () => {
        if (props.hasPremium) {
            switch (props.screenType) {
                case "ACTIVITY":
                    return (
                        <ReportActivity
                            selectedSubject={props.selectedSubject}
                            ownerId={props.member ? props.member.userId : props.user.userDnsId}
                            isOwnSubject={props.isOwnSubject}
                        />
                    );
                case "PREVIEW":
                    return (
                        <ReportPreview
                            selectedSubject={props.selectedSubject}
                            ownerId={props.member ? props.member.userId : props.user.userDnsId}
                            isOwnSubject={props.isOwnSubject}
                        />
                    );
                case "DIFFICULT":
                    return (
                        <ReportDifficult
                            selectedSubject={props.selectedSubject}
                            ownerId={props.member ? props.member.userId : props.user.userDnsId}
                        />
                    );
                case "CONTROL":
                    return (
                        <ReportControl
                            selectedSubject={props.selectedSubject}
                            ownerId={props.member ? props.member.userId : props.user.userDnsId}
                        />
                    );
                case "PHASES":
                default:
                    return (
                        <ReportPhases
                            selectedSubject={props.selectedSubject}
                            ownerId={props.member ? props.member.userId : props.user.userDnsId}
                            ownerName={props.member?.name}
                            userPreferences={props.userPreferences}
                        />
                    );
            }
        }
        return (
            <ReportPhases
                selectedSubject={props.selectedSubject}
                ownerId={props.member ? props.member.userId : props.user.userDnsId}
                ownerName={props.member?.name}
                userPreferences={props.userPreferences}
            />
        );
    };

    return (
        <SubjectReportContainer>
            <Header>
                <Title onClick={() => props.onSubjectSelect(null)}>
                    <PhaseSixIcon name="chevron-left" />
                    <SubjectTitle>{props.selectedSubject.subjectContent.name}</SubjectTitle>
                </Title>
                <LastPractice>
                    {lastPracticed_t + " "}
                    {props.selectedSubject.subjectMetadata && props.selectedSubject.subjectMetadata.lastPracticedDate
                        ? toDDMMYY(props.selectedSubject.subjectMetadata.lastPracticedDate)
                        : "-"}
                </LastPractice>
            </Header>
            <TabContent>
                <TabMenu>
                    {(Object.keys(menuTabKeys) as ReportScreenType[]).map((screenType) => (
                        <Tab
                            key={screenType}
                            selected={props.screenType === screenType}
                            onClick={() => goToTab(screenType)}
                        >
                            <TabText>
                                {menuTabKeys[screenType]}
                                {screenType !== "PHASES" && plusFlag}
                            </TabText>
                        </Tab>
                    ))}
                </TabMenu>
                <Content>{renderContent()}</Content>
                <Modal
                    active={showModal}
                    preventCloseOnBg={true}
                    title={buyPlusModalTitle}
                    beforeClose={() => setShowModal(false)}
                >
                    <ModalTextContainer>
                        <InfoText>{buyPlusTitle}</InfoText>
                        <InfoSubtext>
                            {isSubjectReportOfChild ? buyPlusIntendedForChildSubTitle : buyPlusSubTitle}
                        </InfoSubtext>
                    </ModalTextContainer>
                    <LinkContainer>
                        <PrimaryLink
                            href={shopPlusUrl}
                            target="_blank"
                        >
                            {plusButtonText}
                        </PrimaryLink>
                    </LinkContainer>
                </Modal>
            </TabContent>
        </SubjectReportContainer>
    );
};

const isNewMemberId = (prevProps: SubjectReportProps, nextProps: SubjectReportProps) => {
    return prevProps.member?.userId !== nextProps.member?.userId;
};

export default React.memo(SubjectReport, isNewMemberId);
