import styled from "styled-components";

export const StyledButton = styled.button`
    text-transform: none;
    height: auto;
    font-size: 24px;
    font-weight: 600;
    width: 100%;
    margin-top: 10px;
    font-family: ${(props) => props.theme.base.fontFamily};
    background-color: ${(props) => props.theme.buttons.blank.backgroundColor};
    padding: 15px 5px;
    border-radius: 0.5rem;
    cursor: pointer;
    border: transparent 1px solid;

    &:hover {
        border: solid 1px ${(props) => props.theme.buttons.blank.hoverBorderColor};
    }

    &:disabled {
        border: transparent 1px solid;
        cursor: not-allowed;

        > span {
            opacity: ${(props) => props.theme.base.disabledOpacity};
        }
    }
`;

export const StyledButtonText = styled.span`
    color: ${(props) => props.theme.buttons.blank.textColor};
`;
