import React, { FunctionComponent, memo } from "react";
import { useT } from "@transifex/react";
import { AddCardsMenu, Props as MenuProps } from "../../../complex/addCards/addCardsMenu";

export type Props = Omit<MenuProps, "dropdownTitle" | "inputTitle" | "alert"> & {
    deleteCardsCount: number;
};

const SubjectsMenu: FunctionComponent<Props> = (props) => {
    const { deleteCardsCount, selected, items, ...restProps } = props;
    const t = useT();

    const t_dropdownTitle = t("subject(s)", {});
    const t_inputTitle = t("Content", {});
    const t_deleteItems = t("You are about to delete {deleted} card(s). This action cannot be undone!", {
        deleted: deleteCardsCount,
    });

    return (
        <AddCardsMenu
            dropdownTitle={t_dropdownTitle}
            inputTitle={t_inputTitle}
            alert={deleteCardsCount ? t_deleteItems : undefined}
            closeOnSave
            items={items}
            selected={selected}
            {...restProps}
        />
    );
};

export default memo(SubjectsMenu);
