import React from "react";
import {
    InviteChildContainer,
    InviteChildIcon,
    InviteChildText,
    InviteChildHeader,
    InviteChildBody,
    BenefitsList,
    BenefitItem,
    Benefit,
    InstructionsContainer,
    InstructionsHeader,
    InstructionsBody,
    InstructionsList,
    InstructionItem,
    InviteChildButton,
} from "./styles";

import { useT } from "@transifex/react/dist";
import PhaseSixIcon from "../../../../components/basic/phaseSixIcon/PhaseSixIcon";
import { getInviteChildUrl } from "../../../../helpers/Url";

const InviteChild = ({ jossoId = "" }: { jossoId: string | undefined }) => {
    const t = useT();
    const inviteChildHeader_t = t("Invite a child: your benefits", { _tags: "reports,message" });
    const viewActivity_t = t("View activity and other reports", { _tags: "reports,message" });
    const transferProduct_t = t("Transfer products and features", { _tags: "reports,message" });
    const followSteps_t = t("Follow these simple steps:", { _tags: "reports,message" });
    const goToAccount_t = t('Go to "Mein Konto" and click on "Kind einladen".', { _tags: "reports,message" });
    const enterName_t = t("Enter the child's name and e-mail address.", { _tags: "reports,message" });
    const changeSettings_t = t('Change the settings for your child via "Mein Konto" or via the web-app.', {
        _tags: "reports,message",
    });
    const openSettings_t = t('Open "Mein Konto" in new tab', { _tags: "reports,button" });

    const benefits = [viewActivity_t, changeSettings_t, transferProduct_t];
    const instructions = [goToAccount_t, enterName_t, changeSettings_t];

    const inviteChildUrl = getInviteChildUrl(jossoId);

    return (
        <InviteChildContainer>
            <InviteChildIcon>
                <PhaseSixIcon
                    name="reports-add-user"
                    size="medium"
                />
            </InviteChildIcon>
            <InviteChildText>
                <InviteChildHeader>{inviteChildHeader_t}</InviteChildHeader>
                <InviteChildBody>
                    <BenefitsList>
                        {benefits.map((benefit, i) => {
                            return (
                                <BenefitItem key={"benefit_" + i}>
                                    <PhaseSixIcon
                                        name="exercise-ok"
                                        highlighted
                                    />
                                    <Benefit>{benefit}</Benefit>
                                </BenefitItem>
                            );
                        })}
                    </BenefitsList>
                    <InstructionsContainer>
                        <InstructionsHeader>{followSteps_t}</InstructionsHeader>
                        <InstructionsBody>
                            <InstructionsList>
                                {instructions.map((instruction, i) => (
                                    <InstructionItem key={"instruction_" + i}>{instruction}</InstructionItem>
                                ))}
                            </InstructionsList>
                        </InstructionsBody>
                        <InviteChildButton
                            href={inviteChildUrl}
                            target="_blank"
                        >
                            {openSettings_t}
                        </InviteChildButton>
                    </InstructionsContainer>
                </InviteChildBody>
            </InviteChildText>
        </InviteChildContainer>
    );
};

export default InviteChild;
