// REACT
import React, { FunctionComponent, useState, useCallback, useMemo, memo } from "react";
import { T, useT } from "@transifex/react";

// COMPONENTS
import { Row, Col } from "../../../basic/grid";
import Input from "./EditDropdownInput";
import CreateInput from "./CreateInput";

// STYLED COMPONENTS
import { Container, Header, Footer, Content, CloseButton, SaveButton, AlertText } from "./styles";

// TYPES
import { EditItemProps, TNewItem } from "p6m-dropdown";

const EditDropdownMenu: FunctionComponent<EditItemProps> = (props) => {
    const { items: itemsFromProps = [], alert, onClose, onSave, onDelete } = props;
    const t = useT();

    const updateState = useUpdateState();

    const [newItemsList, setNewItemsList] = useState<TNewItem[]>([
        createItem(
            {
                key: "0",
                value: "",
                deleted: true,
            },
            updateState
        ),
    ]);

    const t_errorsEmpty = t("You have to enter a title for the content.", {});
    const t_errorsUniq = t("There is already an item with that title", {});
    const t_errorsMax = t("Value is too long", {});

    const errors = useMemo(() => {
        return {
            empty: t_errorsEmpty,
            uniq: t_errorsUniq,
            max: t_errorsMax,
        };
    }, [t_errorsEmpty, t_errorsUniq, t_errorsMax]);

    const checkError = useCallback(
        (item: any, items: any[]): string | undefined => {
            if (!item.value) {
                return errors.empty;
            }
            if (item.value.length > 254) {
                return errors.max;
            }
            const sameValueItem = items.find((searchItem) => {
                if (item === searchItem) return false;
                return item.value === searchItem.value;
            });

            if (sameValueItem) return errors.uniq;
        },
        [errors]
    );

    const checkErrors = useCallback(
        (items: any[] = []): boolean => {
            const result = items.findIndex((item: any, index: number) => {
                const restArray = [...items];
                restArray.splice(index, 1);
                const error = checkError(item, restArray);
                if (error) item.setError(error);
                return !!error;
            });

            return result >= 0;
        },
        [checkError]
    );

    const items = useMemo(() => {
        return itemsFromProps.map((item) => createItem(item, updateState, onDelete));
    }, [itemsFromProps, updateState, onDelete]);

    const onCreateCallback = useCallback(() => {
        const itemToSave = newItemsList[0];
        const error = checkError(itemToSave, [...items, ...newItemsList.slice(1)]);
        if (error) {
            itemToSave.setError(error);
            return updateState();
        }

        const newItem = createItem(itemToSave, updateState);
        const listLength: number = newItemsList.push(newItem);
        newItem.key = listLength.toString();
        newItem.deleted = false;

        if (itemToSave.onChange) {
            itemToSave.onChange("");
        }

        setNewItemsList(newItemsList);
    }, [updateState, newItemsList, items, checkError]);

    const onCreateChange = useCallback(
        (newValue: string) => {
            const { onChange } = newItemsList[0];
            if (onChange) onChange(newValue);
        },
        [newItemsList]
    );

    const onSaveCallback = useCallback(() => {
        if (newItemsList[0].value) {
            newItemsList[0].deleted = false;
        }

        if (
            checkErrors([...newItemsList.filter(({ deleted }) => !deleted), ...items.filter(({ deleted }) => !deleted)])
        )
            return;

        const updated = items.filter(({ changed }) => changed).filter(({ deleted }) => !deleted);

        const deleted = items.filter(({ deleted }) => deleted);

        const created = newItemsList.filter(({ deleted }) => !deleted).map(({ value }) => value);

        onSave({
            updated,
            deleted,
            created,
        });
    }, [onSave, items, newItemsList, checkErrors]);

    return (
        <Container>
            <Header>
                <CreateInput
                    key={newItemsList.length}
                    onCreate={onCreateCallback}
                    onChange={onCreateChange}
                    error={newItemsList[0].error}
                    value={newItemsList[0].value}
                />
            </Header>
            <Content>
                {newItemsList
                    .slice(1)
                    .reverse()
                    .map(({ key, ...restItemProps }) => (
                        <Input
                            key={key}
                            {...restItemProps}
                        />
                    ))}
                {items.map(({ key, ...restItemProps }) => (
                    <Input
                        key={key}
                        {...restItemProps}
                    />
                ))}
            </Content>
            <Footer>
                {!!alert && (
                    <Row>
                        <Col>
                            <AlertText>{alert}</AlertText>
                        </Col>
                    </Row>
                )}
                <Row horizontal="end">
                    <Col xs="auto">
                        <CloseButton onClick={onClose}>
                            <T _str="Close" />
                        </CloseButton>
                    </Col>
                    <Col xs="auto">
                        <SaveButton onClick={onSaveCallback}>
                            <T _str="Save" />
                        </SaveButton>
                    </Col>
                </Row>
            </Footer>
        </Container>
    );
};

function useUpdateState(): () => void {
    const [, setNewState] = useState<number>(0);
    // use to rerender component on new data or data change in elements list
    return useCallback(() => {
        setNewState((prevValue) => ++prevValue);
    }, []);
}

function createItem(item: any, onItemChange: any, onDelete?: any): any {
    const result = { ...item };

    if (!result.notDeletable) {
        result.onDelete = function () {
            result.deleted = !result.deleted;
            onItemChange();
            if (onDelete) onDelete(result);
        };
    }

    if (!result.notEditable) {
        result.onChange = function (value: string) {
            result.error = "";
            result.changed = result.value !== value;
            result.value = value;
            onItemChange();
        };
    }

    result.setError = function (error: string) {
        result.error = error;
        onItemChange();
    };

    return result;
}

export default memo(EditDropdownMenu);
