import { css, keyframes } from "styled-components";

const animations = [
    keyframes`
            0% {
                opacity: 1;
            }
            100% {
                opacity: 0;
            }
        `,
    keyframes`
            0% {
                left: -12px;
                top: -12px;
                right: -12px;
                bottom: -12px;
            }
            100% {
                left: -4px;
                top: -4px;
                right: -4px;
                bottom: -4px;
            }
        `,
];

const commonStyles = css`
    position: relative;
    transition: opacity 0.5s;

    &:after {
        content: " ";
        display: block;
        position: absolute;
        left: -4px;
        top: -4px;
        right: -4px;
        bottom: -4px;
        z-index: -1;
        border: 1px solid;
        border-color: inherit;
        border-radius: inherit;
        opacity: 0;
    }
`;

export const hoverStyles = css`
    ${commonStyles}

    &:hover {
        opacity: ${({ theme: { base } }) => base.hoverOpacity};
    }

    &:hover:after {
        animation-name: ${animations[0]};
        animation-duration: 0.5s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
    }
`;

export const focusStyles = css`
    ${commonStyles}

    &:focus {
        outline: 0;
    }

    &:focus:after {
        opacity: 1;
        animation-name: ${animations[1]};
        animation-duration: 0.5s;
        animation-iteration-count: 1;
        animation-direction: alternate;
    }
`;
