// REACT
import React, { ChangeEventHandler, FunctionComponent, memo } from "react";
import { T } from "@transifex/react";

// COMPONENTS
import Modal, { Props as ModalProps, WithModalActions } from "../../../../basic/modal/Modal";
import { Row, Col } from "../../../../basic/grid";

// STYLED COMPONENTS
import { ModalFooter, ModalButton, ExplanationText } from "../styles";
import { Label, InputRow, Input, Textarea } from "./styles";

export type Props = {
    question: string;
    answer: string;
    userAnswer: string;
    userName: string;
    email: string;
    setFeedback: ChangeEventHandler<HTMLTextAreaElement>;
    onSubmit: (actions: WithModalActions["modalActions"]) => () => void;
    onDecline: (actions: WithModalActions["modalActions"]) => () => void;
} & Partial<ModalProps>;

const Feedback: FunctionComponent<Props> = (props) => {
    const { question, answer, userAnswer, userName, email, setFeedback, onSubmit, onDecline, ...restProps } = props;
    return (
        <Modal
            active={true}
            title={<T _str="Suggest improvement to card content" />}
            footerComponent={({ modalActions }) => (
                <ModalFooter>
                    <Row horizontal="end">
                        <Col xs="auto">
                            <ModalButton
                                primary
                                onClick={onDecline(modalActions)}
                            >
                                <T _str="Cancel" />
                            </ModalButton>
                        </Col>
                        <Col xs="auto">
                            <ModalButton
                                outline
                                onClick={onSubmit(modalActions)}
                            >
                                <T _str="Submit" />
                            </ModalButton>
                        </Col>
                    </Row>
                </ModalFooter>
            )}
            preventCloseOnBg
            {...restProps}
        >
            <ExplanationText>
                <T _str="You are about to suggest improvement for the following card" />
            </ExplanationText>
            <InputRow>
                <Label>
                    <T _str="Question" />
                </Label>
                <Input
                    value={question}
                    disabled
                />
            </InputRow>
            <InputRow>
                <Label>
                    <T _str="Correct answer" />
                </Label>
                <Input
                    value={answer}
                    disabled
                />
            </InputRow>
            <InputRow>
                <Label>
                    <T _str="Your answer" />
                </Label>
                <Input
                    value={userAnswer}
                    disabled
                />
            </InputRow>
            <InputRow>
                <Label>
                    <T _str="Your name" />
                </Label>
                <Input value={userName} />
            </InputRow>
            <InputRow>
                <Label>
                    <T _str="Your email address" />
                </Label>
                <Input value={email} />
            </InputRow>
            <InputRow>
                <Label>
                    <T _str="Your message" />
                </Label>
                <Textarea onChange={setFeedback} />
            </InputRow>
        </Modal>
    );
};

export default memo(Feedback);
