import styled from "styled-components";

export const ModalTextContainer = styled.div`
    padding: 60px ${(props) => props.theme.base.spacing} ${(props) => props.theme.base.spacing};
    color: ${(props) => props.theme.colors.text};
`;

export const Title = styled.p`
    text-align: center;
    font-size: ${(props) => props.theme.base.titleSize};
    font-weight: bold;
`;

export const Description = styled.p`
    text-align: center;
    font-size: ${(props) => props.theme.base.bigSize};
    margin-top: ${(props) => props.theme.base.spacing};
`;

export const Actions = styled.div`
    width: 100%;
    margin-bottom: ${(props) => props.theme.base.spacing};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: ${(props) => props.theme.base.spacing};
`;

export const PrimaryButton = styled.button`
    min-width: 100px;
    border: 0;
    cursor: pointer;
    padding: ${(props) => props.theme.base.spacing};
    border-radius: ${(props) => props.theme.base.borderRadius};
    background: ${(props) => props.theme.colors.primary};
    text-align: center;
    color: ${(props) => props.theme.colors.backgroundContrast};
`;
