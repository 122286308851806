import styled from "styled-components";

export const Content = styled.div`
    padding: ${({ theme: { base } }) => base.spacingSmall};

    p {
        margin: ${({ theme: { base } }) => base.spacingSmall} 0;
    }
`;

export const Button = styled.button`
    padding: ${({ theme: { base } }) => base.spacingInContent} ${({ theme: { base } }) => base.spacing};
    font-size: ${({ theme: { base } }) => base.mediumSize};
    color: ${({ theme: { colors } }) => colors.primary};
    border: none;
    background: none;
    text-align: center;
    cursor: pointer;
    align-items: center;
    margin: 0 auto;
    &:hover {
        opacity: ${({ theme: { base } }) => base.hoverOpacity};
    }
`;

export const Footer = styled.div`
    padding: ${({ theme: { base } }) => base.spacingInContent} ${({ theme: { base } }) => base.spacing};
`;
