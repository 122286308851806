import React, { FunctionComponent, MouseEvent, useCallback, useState } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import Badge from "../../basic/badge/Badge";
import Popup from "../../basic/popup/Popup";
import { PopupContainer } from "../../basic/popup/styles";
import { INotificationsEvents, TEvent } from "p6m-notifications";
import Img_uc_others from "../../../assets/img/notifications/uc_others.jpg";
import Img_migration_compl from "../../../assets/img/notifications/migration_complete.png";
import Img_migration_start from "../../../assets/img/notifications/migration_started.png";
import Img_group_delete from "../../../assets/img/notifications/group_delete.png";
import Img_group_add from "../../../assets/img/notifications/group_add.png";
import Img_group_left from "../../../assets/img/notifications/group_left.png";
import Img_group_gained_admin from "../../../assets/img/notifications/group_gained_admin.png";
import Img_group_losing_admin from "../../../assets/img/notifications/group_losing_admin.png";
import Img_survey from "../../../assets/img/notifications/survey.png";
import Img_whats_new from "../../../assets/img/notifications/whats_new.png";
import Img_gc_shared from "../../../assets/img/notifications/gc_shared.jpg";
import Tooltip from "../../basic/tooltip/Tooltip";

import * as Styled from "./styles";
import { useT } from "@transifex/react";
import { ampli } from "../../../ampli";

type Props = {
    items: INotificationsEvents[];
    count?: number;
    onShowMore?: (e: MouseEvent) => void;
    onClearAll: (e: MouseEvent) => void;
    onMarkAsRead: () => void;
    onClearEach: (e: MouseEvent, item: INotificationsEvents) => void;
    showReminderSubscription: boolean;
    remindMe: boolean | undefined;
    onToggleSubscription: () => void;
};

const TopNotifications: FunctionComponent<Props> = (props) => {
    const { items, count, onShowMore, onClearAll, onClearEach, onMarkAsRead } = props;
    const [popupIsOpen, setPopupIsOpen] = useState<Boolean>(false);
    const t = useT();

    const showMore_t = t("Show More", { _tags: "button,notifications" });
    const clear_t = t("Clear", { _tags: "button,notifications" });
    const notifications_t = t("Notifications", { _tags: "notifications,header,tooltip" });
    const noNotifications_t = t("There are no notifications", { _tags: "notifications" });

    const handleTogglePopUp = useCallback(
        (state) => {
            setPopupIsOpen((currentOpenStatus) => !currentOpenStatus);
            !popupIsOpen && ampli.viewedNotificationBar();

            if (!state && !!count) {
                onMarkAsRead();
            }
        },
        [count, popupIsOpen]
    );

    const marketingNotificationWasClicked = (content: string) => {
        const linkToRegExMatch = content.substring(content.indexOf('<a href="') + 1, content.lastIndexOf('">'));
        if (linkToRegExMatch === "") return;
        const filteredValues = decodeURIComponent(linkToRegExMatch).match(/[?&]utm_campaign=([^&]+)/);
        // the parameters are double decoded, so we need a second decodeURI here
        const emailProcessName = (filteredValues && decodeURI(filteredValues[1])) || "";
        ampli.clickedNotification({ emailProcessName });
    };

    return (
        <Popup
            content={
                <Styled.Popup>
                    <Styled.PopupHeader>
                        <div>{notifications_t}</div>
                        <Styled.Button onClick={onClearAll}>{clear_t}</Styled.Button>
                    </Styled.PopupHeader>
                    <Styled.PopupBody>
                        {(!items.length && <Styled.EmptyBox>{noNotifications_t}</Styled.EmptyBox>) ||
                            items.map((item) => {
                                const data = getItemData(item);
                                const { eventTime } = item;
                                return (
                                    <Styled.PopupItem
                                        key={getSubjectId(item)}
                                        highlighted={data.status && data.status === "NEW"}
                                    >
                                        {!!data.image && (
                                            <Styled.PopupImage>
                                                <img
                                                    src={data.image}
                                                    alt={data.title}
                                                />
                                            </Styled.PopupImage>
                                        )}
                                        <Styled.PopupContent>
                                            <p>{data.title}</p>
                                            {(!!eventTime || !!data.detail) && (
                                                <Styled.PopupContentFooter>
                                                    {!!data.detail &&
                                                        (item.eventType === "MARKETING" ? (
                                                            <div
                                                                dangerouslySetInnerHTML={{ __html: data.detail }}
                                                                onClick={() => {
                                                                    marketingNotificationWasClicked(data.detail);
                                                                }}
                                                            />
                                                        ) : (
                                                            <div>{data.detail}</div>
                                                        ))}
                                                    {!!eventTime && (
                                                        <Styled.PullRight>
                                                            {dayjs(eventTime).fromNow()}
                                                        </Styled.PullRight>
                                                    )}
                                                </Styled.PopupContentFooter>
                                            )}
                                        </Styled.PopupContent>
                                        <Styled.PopupDeleteItem onClick={(e: MouseEvent) => onClearEach(e, item)}>
                                            <Styled.InheritColorPhaseSixIcon name="close" />
                                        </Styled.PopupDeleteItem>
                                    </Styled.PopupItem>
                                );
                            })}
                    </Styled.PopupBody>
                    {(items.length > 10 || (items.length === 10 && !!onShowMore)) && (
                        <PopupContainer>
                            <Styled.PopupItemShowMore onClick={onShowMore ? onShowMore : undefined}>
                                <Styled.Button disabled={!onShowMore}>{showMore_t}</Styled.Button>
                            </Styled.PopupItemShowMore>
                        </PopupContainer>
                    )}
                </Styled.Popup>
            }
            position={["bottom", "right"]}
            arrow
            style={{ right: "10px" }}
            onToggle={handleTogglePopUp}
        >
            <Tooltip content={notifications_t}>
                {count ? (
                    <Badge
                        count={count || 0}
                        type="red"
                    >
                        <Styled.StyledPhaseSixIcon
                            name="notification"
                            size="medium"
                        />
                    </Badge>
                ) : (
                    <Styled.StyledPhaseSixIcon
                        name="notification"
                        size="medium"
                    />
                )}
            </Tooltip>
        </Popup>
    );
};

function getSubjectId(item: INotificationsEvents): string {
    const { modificationDate } = item;
    return modificationDate;
}

function getItemData(item: INotificationsEvents): any {
    const { eventType, title = "", imageId = "", detail = "", status } = item;
    const data: Record<TEvent, any> = {
        PURCHASE: {
            title: `You have purchased: ${title}`,
            image: getImage(imageId) || Img_uc_others,
            detail: `${detail} Cards`,
            status,
        },
        PURCHASE_DEMO: {
            title: `You have ordered trial content: ${title}`,
            image: getImage(imageId) || Img_uc_others,
            detail: `${detail} Cards`,
            status,
        },
        MIGRATION_FINISHED: {
            title: "Migration has been completed successfully",
            image: Img_migration_compl,
            detail: `${detail} Cards`,
            status,
        },
        MIGRATION_STARTED: {
            title: "Migration has been started",
            image: Img_migration_start,
            detail,
            status,
        },
        GROUP_WAS_DELETED: {
            title: `Group ${title} was deleted`,
            image: Img_group_delete,
            detail,
            status,
        },
        NEW_GROUP_MEMBER: {
            title: `Added member to group, ${title}`,
            image: Img_group_add,
            detail,
            status,
        },
        REMOVED_GROUP_MEMBER: {
            title: `Removed member from group, ${title}`,
            image: Img_group_left,
            detail,
            status,
        },
        GAINED_GROUP_ADMIN: {
            title: `You became administrator of ${title}`,
            image: Img_group_gained_admin,
            detail,
            status,
        },
        LOST_GROUP_ADMIN: {
            title: `You lost administrator role for ${title}`,
            image: Img_group_losing_admin,
            detail,
            status,
        },
        NEW_SURVEY: {
            title: `Survey: ${title}`,
            image: Img_survey,
            detail: (
                <Link
                    to={detail}
                    target="_blank"
                >
                    Run survey
                </Link>
            ),
            status,
        },
        WHATS_NEW: {
            title: "We have updated our service for you",
            image: Img_whats_new,
            detail: (
                <Link
                    to="https://www.google.com.ua/search?q=what%27s+new"
                    target="_blank"
                >
                    See details
                </Link>
            ),
            status,
        },
        TEACHER_ACTIVATED: {
            title: `Teacher activated cards in ${title} subject for you.`,
            image: getImage(imageId) || Img_gc_shared,
            detail: `${detail} Cards`,
            status,
        },
        PARENT_ACTIVATED: {
            title: `Parent activated cards in ${title} subject for you.`,
            image: getImage(imageId) || Img_gc_shared,
            detail: `${detail} Cards`,
            status,
        },
        NOT_APPROVED_CARDS: {
            title,
            image: getImage(imageId) || Img_gc_shared,
            status,
        },
        MARKETING: {
            title,
            detail,
            status,
        },
    };

    return data[eventType];
}

function getImage(id?: string): string | undefined {
    if (!id) return;
    const url = process.env.REACT_APP_BACKEND_API_URL;
    return `${url}media/${id}`;
}

export default TopNotifications;
