import styled, { css } from "styled-components";

const borderColor = ({ theme: { colors } }: any) => colors.backgroundMain;

const paddingsCss = css`
    padding: ${({ theme: { base } }) => base.spacing} ${({ theme: { base } }) => base.spacingSmall};
`;

export const Container = styled.div`
    margin: -${({ theme: { base } }) => base.spacingSmall};
    box-shadow: 0 0 30px rgb(0 0 0 / 50%);
`;

export const Header = styled.div`
    ${paddingsCss};
    color: ${(props) => props.theme.colors.text};
    border-bottom: 1px solid ${borderColor};
`;

export const Footer = styled.div`
    ${paddingsCss};
    color: ${(props) => props.theme.colors.text};
    border-top: 1px solid ${borderColor};
`;

export const Content = styled.ul`
    list-style: none;
    max-height: 360px;
    margin: 0;
    padding: 0;
    overflow-y: auto;
    height: 50vh;
`;

export const Item = styled.li<{ selected?: boolean }>`
    padding: ${(props) => props.theme.base.spacingSmall};
    margin: 0;
    transition: color ${({ theme: { base } }) => base.transition}s;
    cursor: pointer;
    font-size: ${({ theme: { base } }) => base.size};
    color: ${(props) => (props.selected ? props.theme.colors.primary : props.theme.colors.text)};
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
        color: ${({ theme: { colors } }) => colors.primary};
    }

    &:first-child {
        border-top: none;
    }
`;
