// LIBRARIES
import React, { useCallback, useEffect, useState } from "react";
import { ampli } from "../../../../ampli";

// MODELS
import { ReportPeriod, SubjectData, SubjectReportActivityData } from "p6m-subjects";

// COMPONENTS
import {
    Container,
    InformationBox,
    InfoImageContainer,
    InfoImage,
    InfoTextContainer,
    List,
    Entry,
    Question,
    CorrectAnswer,
    GivenAnswer,
    InfoText,
} from "./styles";
import { useT } from "@transifex/react";
import { getReportInputControlData } from "../../../../networking/reports";
import { stripMediaIds } from "../../../../helpers/Cards";

export interface ReportsProps {
    ownerId?: string;
    selectedSubject: SubjectData;
}

const ReportControl: React.FC<ReportsProps> = (props) => {
    const t = useT();
    const [period] = useState<ReportPeriod>("ONE_MONTH"); // ask bernd if period selection will come - maybe use for "load more"
    const [data, setData] = useState<SubjectReportActivityData[] | null>(null);

    const loadData = useCallback(async () => {
        const {
            data: { replyContent: data },
        } = await getReportInputControlData(
            props.ownerId || "",
            props.selectedSubject.subjectMetadata.subjectIdToOwner,
            period
        );
        setData(data);
    }, [props.ownerId, props.selectedSubject.subjectMetadata.subjectIdToOwner, period]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    useEffect(() => {
        ampli.openReportsTab({
            reports_tab: "input_control",
        });
    }, []);

    const infoText_t = t("The cards that weren't learned correctly are shown here.", {
        _tags: "report,control,message",
    });
    const question_t = t("Question", { _tags: "report,control,message" });
    const answer_t = t("Correct answer", { _tags: "report,control,message" });
    const correctAnswer_t = t("Given Answer", { _tags: "report,control,message" });
    const noData_t = t("There is no data to display.", { _tags: "report,control,message" });

    return (
        <Container>
            <InformationBox>
                <InfoImageContainer>
                    <InfoImage src={require("../../../../assets/img/avatar/monster_basic.png")} />
                </InfoImageContainer>
                <InfoTextContainer>
                    <InfoText>{infoText_t}</InfoText>
                </InfoTextContainer>
            </InformationBox>
            <List>
                <Entry header>
                    <Question>{question_t}</Question>
                    <CorrectAnswer>{answer_t}</CorrectAnswer>
                    <GivenAnswer>{correctAnswer_t}</GivenAnswer>
                </Entry>
                {data &&
                    (data.length > 0 ? (
                        data.map((entry, index) => {
                            return (
                                <Entry key={"control_" + index}>
                                    <Question dangerouslySetInnerHTML={{ __html: stripMediaIds(entry.question) }} />
                                    <CorrectAnswer dangerouslySetInnerHTML={{ __html: stripMediaIds(entry.answer) }} />
                                    <GivenAnswer
                                        dangerouslySetInnerHTML={{
                                            __html: entry.userAnswer ? stripMediaIds(entry.userAnswer) : "",
                                        }}
                                    />
                                </Entry>
                            );
                        })
                    ) : (
                        <Entry>
                            <Question>{noData_t}</Question>
                        </Entry>
                    ))}
            </List>
        </Container>
    );
};

export default ReportControl;
