// LIBRARIES
import React, { useCallback, useContext, useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import { T } from "@transifex/react";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";

//TYPES
import { SubjectData, SubjectUnitCard } from "p6m-subjects";
import { CardInfoForActionButtons } from "p6m-cards";

// COMPONENTS
import { CoverImage } from "p6-react-shared";
import CardActionButtons from "../../complex/cardActionButtons";
import ListCheckbox from "../../basic/listCheckbox/ListCheckbox";
import { ListRow } from "./ListRow";

// UTILS
import { LanguagesContext } from "../../../context/languages";
import { getSubjectCover } from "../../../helpers/Subjects";

// NETWORKING
import { getSubjectUnitCards } from "../../../networking/subjects";

//STYLED-COMPONENTS
import {
    Container,
    ActionButtonsContainer,
    ActionContainer,
    CardCount,
    CardList,
    SelectedCardsCount,
    NoCardContainer,
    NoCardText,
    SubjectHeader,
    FlagImage,
    DeleteEntry,
    Title,
    SubjectSelection,
    StyledPhaseSixIcon,
} from "./styles";

export interface LibrarySubjectEntryProps {
    isLoaded: boolean;
    isOverview: boolean;
    canSwap: boolean;
    subject: SubjectData;
    cards: SubjectUnitCard[];
    isSearchResult?: boolean;
    limitCardsShown: boolean;
    units: { title: string; value?: string | number }[];
    isFirstInList: boolean;
    goToSubject?: (subjectId: string) => void;
    deleteSubject?: (subjectId: string) => void;
    onScroll: () => void;
    onLoadAllCards?: () => Promise<void>;
    hasMoreCards: boolean;
    refreshCardList: (initialValue?: number) => void | undefined;
}

const LibrarySubjectEntry: React.FC<LibrarySubjectEntryProps> = (props) => {
    const {
        cards = [],
        subject,
        isSearchResult,
        limitCardsShown,
        canSwap,
        units,
        isLoaded,
        hasMoreCards,
        isOverview,
        goToSubject,
        deleteSubject,
        onScroll,
        onLoadAllCards,
        refreshCardList,
    } = props;

    const {
        subjectContent,
        subjectMetadata: {
            subjectIdToOwner: { id: subjectId },
        },
    } = subject;

    const { getFlagSource } = useContext(LanguagesContext);
    const image = getSubjectCover(subject);

    const [allCardsSelected, setAllCardsSelected] = useState<boolean>(false);
    const [selectedCardsIds, setSelectedCardsIds] = useState<string[]>([]);
    const [cardCount, setCardCount] = useState<number>(0);
    const [updatedCardIds, setUpdatedCardIds] = useState<string[]>([]);

    const allCardsLength = cards.length;
    const selectedCardsLength = selectedCardsIds.length;

    const cardDataById: { [key: string]: CardInfoForActionButtons } = {};
    cards.forEach((card) => {
        cardDataById[card.cardIdToOwner.id] = { ...card, idToOwnerId: card.cardIdToOwner };
    });

    const onSelectSubject = () => {
        if (isOverview) {
            // only navigate if not yet in selected view
            goToSubject && goToSubject(subjectId);
        }
    };

    const toggleSelectAllCards = () => {
        const fetch = async () => {
            if (hasMoreCards) {
                await onLoadAllCards?.();
            }

            // this check includes if the user manually checks all cards in the list
            if (!allCardsSelected && !(allCardsLength === selectedCardsLength)) {
                setAllCardsSelected(true);
            } else {
                setAllCardsSelected(false);
                // unselect all
                setSelectedCardsIds([]);
            }
        };

        fetch();
    };

    useEffect(() => {
        if (allCardsSelected) {
            // select all
            const newSelectedCardsIds = cards.map((card) => card.cardIdToOwner.id);
            setSelectedCardsIds(newSelectedCardsIds);
        }
    }, [allCardsSelected]);

    const toggleSingleCard = useCallback((cardId: string) => {
        setAllCardsSelected(false);
        setSelectedCardsIds((oldSelectedCardsIds) => {
            const newSelectedCardIds = cloneDeep(oldSelectedCardsIds);
            return newSelectedCardIds.includes(cardId)
                ? newSelectedCardIds.filter((id) => id !== cardId)
                : [...newSelectedCardIds, cardId];
        });
    }, []);

    const groupedCardCount = subject.groupedCardCount?.cardCounts.LIBRARY.cardCount;

    //EFFECT
    useEffect(() => {
        const retrieveCards = async () => {
            const subjectUnitCards = await getSubjectUnitCards({ subjectId });
            setUpdatedCardIds(subjectUnitCards.data.replyContent.cards.map((card) => card.cardIdToOwner.id));
        };

        retrieveCards();

        if (!isNaN(Number(groupedCardCount))) {
            setCardCount(groupedCardCount!);
        }
    }, [groupedCardCount]);

    useEffect(() => {
        // at some point we should check per unit
        setSelectedCardsIds((oldSelectedCardIds) =>
            oldSelectedCardIds.filter((selectedCardId) => updatedCardIds.includes(selectedCardId))
        );
    }, [updatedCardIds.length]);

    useEffect(() => {
        setSelectedCardsIds([]);
    }, [subjectId]);

    useEffect(() => {
        setAllCardsSelected(false);
    }, [allCardsLength]);

    const isItemLoaded = (index: number) => !hasMoreCards || index < cards.length;

    return (
        <Container>
            <SubjectHeader>
                <SubjectSelection onClick={onSelectSubject}>
                    <CoverImage
                        imageId={image}
                        size="small"
                    />
                    <Title>{subjectContent.name}</Title>
                    <CardCount>
                        <T
                            _str="{count} cards"
                            count={cardCount}
                        />
                    </CardCount>
                    <FlagImage src={getFlagSource(subjectContent.primaryLang)} />
                    <FlagImage src={getFlagSource(subjectContent.secondaryLang)} />
                </SubjectSelection>
                {!!deleteSubject && (
                    <DeleteEntry>
                        <StyledPhaseSixIcon
                            name="trash"
                            onClick={() => deleteSubject(subjectId)}
                        />
                    </DeleteEntry>
                )}
            </SubjectHeader>
            {!!allCardsLength && (
                <ActionContainer isSearchResult={isSearchResult}>
                    {!isSearchResult && (
                        <>
                            <ActionButtonsContainer>
                                <ListCheckbox
                                    checked={allCardsLength === selectedCardsLength}
                                    onChange={toggleSelectAllCards}
                                />
                                {!!selectedCardsLength && (
                                    <CardActionButtons
                                        selectedCardsIds={selectedCardsIds}
                                        canSwap={canSwap}
                                        subjectId={subjectId}
                                        refreshCardList={refreshCardList}
                                        cardDataById={cardDataById}
                                    />
                                )}
                            </ActionButtonsContainer>
                            <SelectedCardsCount>
                                <T
                                    _str={"{cardsSelected} card(s) selected"}
                                    cardsSelected={selectedCardsLength}
                                    _tags={"Library"}
                                />
                            </SelectedCardsCount>
                        </>
                    )}
                </ActionContainer>
            )}

            <CardList>
                {isLoaded && !limitCardsShown && !allCardsLength && (
                    <NoCardContainer>
                        <NoCardText>
                            <T
                                _str="There are no cards matching your search options."
                                _tags="message,library,content"
                            />
                        </NoCardText>
                    </NoCardContainer>
                )}
                {allCardsLength > 0 && (
                    <AutoSizer>
                        {({ width, height }: { width: number; height: number }) => (
                            <InfiniteLoader
                                isItemLoaded={isItemLoaded}
                                itemCount={cardCount}
                                loadMoreItems={onScroll}
                            >
                                {({ onItemsRendered, ref }) => (
                                    <List
                                        height={height}
                                        itemCount={cards.length}
                                        onItemsRendered={onItemsRendered}
                                        itemSize={65}
                                        width={width}
                                        ref={ref}
                                        itemData={{
                                            cards,
                                            units,
                                            subjectId,
                                            toggleSingleCard,
                                            allCardsSelected,
                                            selectedCardsIds,
                                            refreshCardList,
                                        }}
                                    >
                                        {ListRow}
                                    </List>
                                )}
                            </InfiniteLoader>
                        )}
                    </AutoSizer>
                )}
            </CardList>
        </Container>
    );
};

LibrarySubjectEntry.defaultProps = {
    isFirstInList: false,
};

export default React.memo(LibrarySubjectEntry);
