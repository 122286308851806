import styled from "styled-components";
import { createElement } from "react";
import Icon from "../../../basic/phaseSixIcon/PhaseSixIcon";

export const Wrapper = styled.div`
    display: block;
`;

const ArrowElement = () => {
    return createElement(Icon, { name: "chevron-right" });
};

export const Arrow = styled(ArrowElement)`
    margin: 0 ${({ theme: { base } }) => base.borderRadius};
    font-size: ${({ theme: { base } }) => base.bigSize};
    cursor: pointer;
    transition: transform ${({ theme: { base } }) => base.transition}s;
`;
