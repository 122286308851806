// REACT
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

// LIBRARIES
import { cloneDeep, differenceBy, differenceWith, intersectionWith } from "lodash";
import { useT } from "@transifex/react";

// STYLED COMPONENTS
import {
    CancelButton,
    Container,
    Content,
    DeleteWarning,
    Dropdown,
    DropdownEntry,
    DropdownEntryContentWrapper,
    DropdownText,
    DropdownTextInput,
    FinishButtons,
    Footer,
    Header,
    HeaderContainer,
    HeaderRow,
    IconWrapper,
    InactiveIconContainer,
    InputRow,
    InvalidInputMessage,
    InvalidInputWarning,
    Label,
    OptionCount,
    SaveButton,
    SelectableIconContainer,
    SelectableSquareDiv,
    SelectedIconContainer,
    Selection,
    StyledPhaseSixIcon,
    TitleContainer,
    Toggle,
} from "./styles";

// TYPES
import { DropdownContentType, ExtendedOption } from "p6m-viewData";
import { EntryChange } from "p6m-subjects";

// UTILS
import { checkInputDuplication, InputValidationResultType, validateInput } from "../../../helpers/Validators";

// COMPONENT
import PhaseSixIcon from "../phaseSixIcon/PhaseSixIcon";

export interface EntryProps {
    option: ExtendedOption;
    selected: boolean;
    deleted: boolean;
    index: number;
    onClick: (id: number) => void;
    onChange: (index: number, name: string) => void;
    isEditMode: boolean;
    deletable: boolean;
    editable: boolean;
    showValidationMessages: boolean;
}

export interface DropdownSelectionProps {
    label: string;
    withBorder?: boolean;
    options: { title: string; value?: string; editable?: boolean }[];
    multiSelection: boolean;
    forceSelection?: boolean;
    selectedIds: string[];
    saveIds: (selectedIds: string[]) => void;
    editable?: boolean;
    forbiddenNames?: string[]; // needed to check additional titles, that are not part of the shown options
    contentType?: DropdownContentType;
    deletable?: boolean;
    resetEdit?: () => void;
    onChange?: (i: number, name: string) => void;
    saveEdit?: (changes: EntryChange[]) => void;
    displayDeleteWarning?: boolean;
}

const entriesAreEqual = (prevProps: EntryProps, nextProps: EntryProps) => {
    return (
        prevProps.isEditMode === nextProps.isEditMode &&
        prevProps.selected === nextProps.selected &&
        prevProps.option.value === nextProps.option.value &&
        prevProps.option.deleted === nextProps.option.deleted &&
        prevProps.option.title === nextProps.option.title &&
        prevProps.option.validationResult === nextProps.option.validationResult &&
        prevProps.showValidationMessages === nextProps.showValidationMessages
    );
};

export const Entry: React.FC<EntryProps> = React.memo((props) => {
    const t = useT();
    const nameIsDuplicate_t: string = t("A content with that title already exists.", {
        _tags: "response,warning,library",
    });
    const nameIsEmpty_t: string = t("You have to enter a title for the content.", {
        _tags: "response,warning,library",
    });

    const validationMessage =
        props.option.validationResult === InputValidationResultType.Empty
            ? nameIsEmpty_t
            : props.option.validationResult === InputValidationResultType.Duplicate
            ? nameIsDuplicate_t
            : "";

    return (
        <DropdownEntry
            key={`dropdown_option_${props.option.value}`}
            onClick={() => {
                if (!props.isEditMode) {
                    // click whole line if non-editable
                    props.onClick(props.index);
                }
            }}
            value={props.option.title}
            color={props.selected ? "primary" : props.option.deleted ? "red" : ""}
        >
            <DropdownEntryContentWrapper>
                <IconWrapper
                    alignCenter
                    onClick={() => {
                        if (props.isEditMode && (props.editable || props.deletable)) {
                            // click only icon if editable to not overlay click on input
                            props.onClick(props.index);
                        }
                    }}
                >
                    {props.isEditMode ? (
                        !props.editable && !props.deletable ? (
                            <InactiveIconContainer>
                                <StyledPhaseSixIcon name="trash" />
                            </InactiveIconContainer>
                        ) : (
                            <SelectableIconContainer deleted={props.deleted.toString()}>
                                <StyledPhaseSixIcon name="trash" />
                            </SelectableIconContainer>
                        )
                    ) : props.selected ? (
                        <SelectedIconContainer>
                            <StyledPhaseSixIcon
                                name="exercise-ok"
                                highlighted
                            />
                        </SelectedIconContainer>
                    ) : (
                        <SelectableSquareDiv />
                    )}
                </IconWrapper>
                {props.isEditMode ? (
                    props.deleted ? (
                        <DropdownText deleted>{props.option.title}</DropdownText>
                    ) : props.editable ? (
                        <DropdownTextInput
                            value={props.option.title}
                            onChange={(e) => props.onChange(props.index, e.target.value)}
                            minLength={
                                process.env.REACT_APP_INPUT_CHARS_MIN ? +process.env.REACT_APP_INPUT_CHARS_MIN : 1
                            }
                            maxLength={
                                process.env.REACT_APP_INPUT_CHARS_MAX ? +process.env.REACT_APP_INPUT_CHARS_MAX : 254
                            }
                        />
                    ) : (
                        <DropdownText notEditable>{props.option.title}</DropdownText>
                    )
                ) : (
                    <DropdownText>{props.option.title}</DropdownText>
                )}
            </DropdownEntryContentWrapper>
            {props.showValidationMessages && validationMessage && (
                <InvalidInputMessage>{validationMessage}</InvalidInputMessage>
            )}
        </DropdownEntry>
    );
}, entriesAreEqual);

const DropdownSelection: React.FC<DropdownSelectionProps> = (props) => {
    const {
        label,
        options: inputOptionsList,
        multiSelection,
        selectedIds,
        saveIds,
        editable,
        forbiddenNames,
        contentType,
        resetEdit = () => {},
        saveEdit = () => {},
        displayDeleteWarning,
    } = props;

    const t = useT();
    const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [optionsToShow, setOptionsToShow] = useState<ExtendedOption[]>([]);
    const selectedOptionsToShowIds = optionsToShow.reduce(
        (acc, { selected, value: optionId }) => (selected && optionId ? [...acc, optionId] : acc),
        [] as string[]
    );

    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [showValidationMessages, setShowValidationMessages] = useState<boolean>(false);
    const [warning, setWarning] = useState<string>("");
    const [deleteCardsCount, setDeleteCardsCount] = useState<number>(0);

    //TRANSIFEX
    const nameIsDuplicate_t = t("This name already exists.", {
        _tags: "response,warning,library",
    });
    const t_contents = t("content(s)", { _tags: "label,selection,library" });
    const t_xOfY = t("of", { _tags: "library,inline,connector" });
    const t_deleteCardsOnDeleteSubject = t("You are about to delete {deleted} card(s). This action cannot be undone!", {
        deleted: deleteCardsCount,
        _tags: "response,warning,library",
    });
    const t_moveCardsOnDeleteUnit = t("{deleted} card(s) will be deleted. This action cannot be undone!", {
        deleted: deleteCardsCount,
        _tags: "response,warning,library",
    });

    const selectedCountTitle = t("Selected {selectionCount}", {
        selectionCount: selectedOptionsToShowIds.length,
    });
    const allText = t("All", { _tags: "title" });

    let title = !selectedOptionsToShowIds.length ? allText : selectedCountTitle;
    if (selectedOptionsToShowIds.length === 1) {
        const selectedOption = optionsToShow.find(({ selected }) => selected);
        if (selectedOption) title = selectedOption.title;
    }

    const cancelText = t("Cancel", { _tags: "button" });
    const okText = t("Ok", { _tags: "button" });
    const saveText = t("Save", { _tags: "button" });

    const toggleEdit = useCallback(() => {
        if (isEditMode) {
            // reset changes on toggle off
            setOptionsToShow((_) =>
                inputOptionsList.map((option) => ({
                    ...option,
                    selected: false,
                    deleted: false,
                    validationResult: InputValidationResultType.Valid,
                }))
            );
            setWarning("");
            setShowValidationMessages(false);
        }
        setIsEditMode(!isEditMode);
    }, [isEditMode, setOptionsToShow, inputOptionsList]);

    const onResetEdit = useCallback(() => {
        setOptionsToShow((oldOptionsToShow) =>
            inputOptionsList
                .filter((option) => option.value)
                .map((option) => ({
                    ...option,
                    selected: oldOptionsToShow.find((olOption) => olOption.value === option.value)?.selected || false,
                    deleted: false,
                    validationResult: InputValidationResultType.Valid,
                }))
        );
        resetEdit();
        setWarning("");
        setShowValidationMessages(false);
        setIsEditMode(false);
    }, [setOptionsToShow, inputOptionsList, resetEdit, setIsEditMode]);

    const toggleDropdown = useCallback(
        (saveData: boolean) => {
            if (saveData && dropdownIsOpen) {
                saveIds(selectedOptionsToShowIds);
            }

            setDropdownIsOpen(!dropdownIsOpen);
        },
        [dropdownIsOpen, selectedOptionsToShowIds, saveIds]
    );

    const handleClick = useCallback(
        (event: MouseEvent) => {
            const ref = dropdownRef;
            const { target } = event;
            if (ref && target instanceof Node && !ref.current?.contains(target)) {
                setTimeout(() => {
                    if (isEditMode) {
                        onResetEdit();
                        toggleDropdown(true);
                    } else {
                        toggleDropdown(true);
                    }
                }, 100);
            }
        },
        [toggleDropdown, isEditMode, onResetEdit]
    );

    const eventHandlers = useMemo(
        () => ({
            onBlur: () => {
                if (dropdownIsOpen && !isEditMode) {
                    // set up
                    onResetEdit();
                    setWarning("");
                    setShowValidationMessages(false);
                    setIsEditMode(false);
                } else {
                    if (!isEditMode) {
                        saveIds(selectedOptionsToShowIds);
                    }
                }
            },
        }),
        [dropdownIsOpen, isEditMode, onResetEdit, saveIds, selectedOptionsToShowIds]
    );

    const changeOption = (index: number, name: string) => {
        setOptionsToShow((oldOptionsToShow) => {
            if (oldOptionsToShow[index].title === name) {
                return oldOptionsToShow;
            } else {
                const newOptionsToShow = cloneDeep(oldOptionsToShow);
                const validationResult: InputValidationResultType = validateInput(index, name, newOptionsToShow);
                const duplicatesIndices: number[] = checkInputDuplication(index, newOptionsToShow);
                if (duplicatesIndices.length === 1) {
                    newOptionsToShow[duplicatesIndices[0]].validationResult = InputValidationResultType.Valid;
                }
                newOptionsToShow[index].title = name;
                newOptionsToShow[index].validationResult = validationResult;
                return newOptionsToShow;
            }
        });
    };

    const toggleOption = useCallback(
        async (index: number) => {
            if (isEditMode) {
                if (displayDeleteWarning) {
                    setDeleteCardsCount((deleteCardsCount: number) => {
                        if (optionsToShow[index].deleted) {
                            return deleteCardsCount - (optionsToShow[index].cardCount || 0);
                        }
                        return deleteCardsCount + (optionsToShow[index].cardCount || 0);
                    });
                }
                setOptionsToShow((oldOptionsToShow) => {
                    const newOptions = cloneDeep(oldOptionsToShow);
                    newOptions[index].deleted = !newOptions[index].deleted;
                    return newOptions;
                });
            } else {
                if (multiSelection) {
                    setOptionsToShow((oldOptionsToShow) => {
                        const newOptions = cloneDeep(oldOptionsToShow);
                        newOptions[index].selected = !newOptions[index].selected;
                        return newOptions;
                    });
                } else {
                    setOptionsToShow((oldOptionsToShow) => {
                        const newOptions = cloneDeep(oldOptionsToShow).map((option, i) => ({
                            ...option,
                            selected: i === index,
                        }));

                        const newSelectedOptionIds = newOptions.reduce(
                            (acc, option) => (option.selected && option.value ? [...acc, option.value] : acc),
                            [] as string[]
                        );

                        if (newSelectedOptionIds.length) {
                            saveIds(newSelectedOptionIds);
                        }
                        return newOptions;
                    });

                    toggleDropdown(false);
                }
            }
        },
        [isEditMode, displayDeleteWarning, optionsToShow, multiSelection, toggleDropdown, saveIds]
    );

    const selectAll = (value: boolean) => {
        if (multiSelection) {
            setOptionsToShow((oldOptionsToShow) =>
                oldOptionsToShow.map((oldOption) => ({
                    ...oldOption,
                    selected: value,
                }))
            );
        }
    };

    const addEntryNameRef = useRef<HTMLInputElement>(null);
    const onAddEntry = () => {
        if (
            addEntryNameRef &&
            addEntryNameRef.current &&
            addEntryNameRef.current.value &&
            addEntryNameRef.current.value.trim() !== ""
        ) {
            const name = addEntryNameRef.current.value;
            const isAllowed = !forbiddenNames || !forbiddenNames.includes(name);
            const alreadyExists = optionsToShow.some((option) => option.title.toLowerCase() === name.toLowerCase());
            if (isAllowed && !alreadyExists) {
                setWarning("");
                const newOWS = cloneDeep(optionsToShow);
                newOWS.push({
                    value: undefined,
                    title: name,
                    selected: false,
                    editable: true,
                    deleted: false,
                    deletable: true,
                    validationResult: InputValidationResultType.Valid,
                });
                setOptionsToShow(newOWS);
                addEntryNameRef.current.value = "";
            } else {
                setWarning(nameIsDuplicate_t);
            }
        }
    };

    const onSaveEdit = useCallback(() => {
        const inputValue = addEntryNameRef.current?.value.trim() ?? "";
        const hasNewEntries = inputOptionsList.length !== optionsToShow.length || !!inputValue;

        const hasDeletedEntries = !!intersectionWith(
            inputOptionsList.map((option) => option.value),
            optionsToShow.filter((option) => option.deleted).map((option) => option.value)
        ).length;
        const hasChangedNames = !!differenceWith(
            inputOptionsList.map((option) => option.title),
            optionsToShow.map((option) => option.title)
        ).length;

        if (hasNewEntries || hasDeletedEntries || hasChangedNames) {
            const allInputsAreValid = optionsToShow.every(
                ({ validationResult }) => validationResult === InputValidationResultType.Valid
            );
            if (allInputsAreValid) {
                const changes: EntryChange[] = [];
                optionsToShow.forEach(({ title, value, deleted }) => {
                    const hasTitle = title;
                    //we only want to send deletion-info to backend, if option already exists in database.
                    //In case the user creates an item, but deletes it immediately before saving it,
                    //we will not commit any change in the first place:
                    const wasDeletedBeforeSaving = !value && deleted;
                    if (hasTitle && !wasDeletedBeforeSaving) {
                        changes.push({
                            id: `${value}`,
                            name: title.trim(),
                            deleted: deleted,
                        });
                    }
                });

                const isInputValueDuplicateToExistingEntry =
                    optionsToShow.find((element) => {
                        return element.title === inputValue;
                    }) !== undefined;

                if (!isInputValueDuplicateToExistingEntry) {
                    if (inputValue) {
                        changes.push({
                            id: `undefined`,
                            name: inputValue,
                            deleted: false,
                        });
                    }

                    saveEdit(changes);
                    setOptionsToShow([]);
                    setWarning("");
                    setShowValidationMessages(false);
                    setIsEditMode(false);
                    if (optionsToShow.find((eos) => eos.deleted)) toggleDropdown(false);
                } else {
                    setWarning(nameIsDuplicate_t);
                }
            } else {
                setShowValidationMessages(true);
            }
        } else {
            setWarning("");
            setShowValidationMessages(false);
            setIsEditMode(false);
        }
    }, [optionsToShow, inputOptionsList, saveEdit, toggleDropdown]);

    useEffect(() => {
        setOptionsToShow((oldOptionsToShow) => {
            const idsToBeAdded = cloneDeep(differenceBy(inputOptionsList, oldOptionsToShow, "value")).map(
                ({ value }) => value
            );
            const newOptionsToShow = [] as ExtendedOption[];

            inputOptionsList.forEach((option) => {
                newOptionsToShow.push({
                    ...option,
                    selected: option.value ? selectedIds.includes(option.value! as string) : false,
                    validationResult: InputValidationResultType.Valid,
                    deleted: idsToBeAdded.includes(option.value) ? false : undefined,
                });
            });

            const newItems = oldOptionsToShow.filter((item) => item.value === undefined);

            return [...newOptionsToShow, ...newItems];
        });
    }, [setOptionsToShow, selectedIds, inputOptionsList, label, contentType]);

    useEffect(() => {
        if (dropdownIsOpen) {
            document.addEventListener("mousedown", handleClick);
            return () => document.removeEventListener("mousedown", handleClick);
        }
    }, [dropdownIsOpen, handleClick]);

    const header = (
        <Header>
            {isEditMode ? (
                <HeaderRow>
                    <InputRow>
                        <IconWrapper>
                            <SelectableIconContainer onClick={onAddEntry}>
                                <StyledPhaseSixIcon name="add" />
                            </SelectableIconContainer>
                        </IconWrapper>
                        <DropdownTextInput
                            ref={addEntryNameRef}
                            minLength={
                                process.env.REACT_APP_INPUT_CHARS_MIN ? +process.env.REACT_APP_INPUT_CHARS_MIN : 1
                            }
                            maxLength={
                                process.env.REACT_APP_INPUT_CHARS_MAX ? +process.env.REACT_APP_INPUT_CHARS_MAX : 254
                            }
                        />
                    </InputRow>
                    {warning && <InvalidInputWarning>{warning}</InvalidInputWarning>}
                </HeaderRow>
            ) : (
                <HeaderContainer>
                    {multiSelection ? (
                        <>
                            <IconWrapper>
                                {optionsToShow.every(({ selected }) => selected) ? (
                                    <SelectedIconContainer onClick={() => selectAll(false)}>
                                        <StyledPhaseSixIcon name="exercise-ok" />
                                    </SelectedIconContainer>
                                ) : (
                                    <SelectableSquareDiv onClick={() => selectAll(true)} />
                                )}
                            </IconWrapper>
                            <OptionCount>{`${optionsToShow.filter(({ selected }) => selected).length} ${t_xOfY} ${
                                optionsToShow.length
                            }`}</OptionCount>
                        </>
                    ) : (
                        <HeaderRow>
                            <InputRow>
                                <IconWrapper>
                                    <SelectableSquareDiv hidden />
                                </IconWrapper>
                                <OptionCount>
                                    {optionsToShow.length} {" " + t_contents}
                                </OptionCount>
                            </InputRow>
                        </HeaderRow>
                    )}

                    {editable && (
                        <IconWrapper flexEnd>
                            <SelectableIconContainer onClick={toggleEdit}>
                                <StyledPhaseSixIcon name="edit-avatar" />
                            </SelectableIconContainer>
                        </IconWrapper>
                    )}
                </HeaderContainer>
            )}
        </Header>
    );

    return (
        <Container withBorder={props.withBorder}>
            <TitleContainer
                onClick={() => {
                    // open only, close is handled by mouse down event
                    if (!dropdownIsOpen) {
                        toggleDropdown(false);
                    }
                }}
            >
                <Label>{label}</Label>
                <Selection>{title}</Selection>
                <Toggle>
                    <PhaseSixIcon name={dropdownIsOpen ? "chevron-up" : "chevron-down"} />
                </Toggle>
            </TitleContainer>
            {dropdownIsOpen && (
                <Dropdown
                    ref={dropdownRef}
                    onBlur={eventHandlers.onBlur}
                >
                    {header}
                    <Content>
                        {optionsToShow.map((option, index) => {
                            return (
                                <Entry
                                    key={`${option}_${index}_${option.value}`}
                                    option={option}
                                    selected={option.selected}
                                    deleted={!!option.deleted}
                                    index={index}
                                    onClick={(i) => toggleOption(i)}
                                    onChange={(i, name) => changeOption(i, name)}
                                    isEditMode={isEditMode}
                                    deletable={!!option.deletable}
                                    editable={!!option.editable}
                                    showValidationMessages={showValidationMessages}
                                />
                            );
                        })}
                    </Content>
                    <Footer>
                        {isEditMode && (
                            <>
                                {displayDeleteWarning && !!deleteCardsCount && (
                                    <DeleteWarning>
                                        {contentType === "subjects"
                                            ? t_deleteCardsOnDeleteSubject
                                            : contentType === "units"
                                            ? t_moveCardsOnDeleteUnit
                                            : undefined}
                                    </DeleteWarning>
                                )}
                                <FinishButtons>
                                    <CancelButton onClick={onResetEdit}>{cancelText}</CancelButton>
                                    <SaveButton
                                        onClick={onSaveEdit}
                                        disabled={
                                            showValidationMessages &&
                                            optionsToShow.some(
                                                ({ validationResult }) =>
                                                    validationResult !== InputValidationResultType.Valid
                                            )
                                        }
                                    >
                                        {saveText}
                                    </SaveButton>
                                </FinishButtons>
                            </>
                        )}
                        {!isEditMode && multiSelection && (
                            <FinishButtons>
                                <CancelButton onClick={() => toggleDropdown(false)}>{cancelText}</CancelButton>
                                <SaveButton onClick={() => toggleDropdown(true)}>{okText}</SaveButton>
                            </FinishButtons>
                        )}
                    </Footer>
                </Dropdown>
            )}
        </Container>
    );
};

export default DropdownSelection;
