// REACT
import React, { FunctionComponent, ReactNode, useEffect, useState, useMemo, useCallback } from "react";

// COMPONENTS
import Component from "./DropdownSelection";
import Input from "./DropdownInput/DropdownInput";
import DropdownList from "./DropdownList/DropdownList";

// STYLED COMPONENTS
import { Arrow } from "./styles";

import { CustomDropdown, CustomInput, ItemProps } from "p6m-dropdown";

type Props = {
    fullWidth?: boolean;
    onToggle?: (state: boolean) => void;
} & CustomDropdown &
    CustomInput;

export const DropdownSelection: FunctionComponent<Props> = (props) => {
    const { fullWidth, isOpen: isOpenFromProps = false, onToggle: onToggleFromProps } = props;
    const [isOpen, setOpen] = useState<boolean>(isOpenFromProps);

    useEffect(() => {
        setOpen(isOpenFromProps);
    }, [isOpenFromProps]);

    const onToggleCallback = useCallback(
        (state: boolean) => {
            if (onToggleFromProps) onToggleFromProps(state);
            setOpen(state);
        },
        [onToggleFromProps]
    );

    const InputComponent = useGetInputComponent({ ...props, isOpen });

    const DropdownComponent = useGetDropdownComponent(props);

    return (
        <Component
            input={InputComponent}
            dropdown={DropdownComponent}
            fullWidth={fullWidth}
            onToggle={onToggleCallback}
        />
    );
};

function useGetInputComponent({
    customInput,
    label,
    oneLine,
    onRemove,
    isOpen = false,
    selected = [],
    items = [],
    chevron = () => <Arrow />,
}: CustomInput): ReactNode {
    const DefaultInputComponent: ReactNode = useMemo(() => {
        if (!!customInput) return "";
        return (
            <Input
                items={items.filter(({ key }) => selected.includes(key))}
                oneLine={oneLine}
                label={label}
                onRemove={onRemove}
                isOpen={isOpen}
                chevron={!!chevron && chevron({ isOpen })}
            />
        );
    }, [customInput, label, oneLine, onRemove, isOpen, selected, chevron, items]);

    return useMemo(() => {
        return customInput || DefaultInputComponent;
    }, [customInput, DefaultInputComponent]);
}

function useGetDropdownComponent({
    customDropdown,
    dropdownHeader,
    dropdownFooter,
    items = [],
    selected = [],
    closeOnSelect = false,
    onSelect,
}: CustomDropdown): ReactNode {
    const DefaultDropdownComponent: ReactNode = useCallback(
        ({ closePopup }: any) => {
            if (!!customDropdown) return "";

            const onSelectCallback = (item: ItemProps) => {
                if (!onSelect) return;
                if (closeOnSelect) closePopup();
                onSelect(item);
            };

            return (
                <DropdownList
                    items={items}
                    selected={selected}
                    header={dropdownHeader}
                    footer={dropdownFooter}
                    onSelect={onSelectCallback}
                />
            );
        },
        [customDropdown, items, dropdownHeader, dropdownFooter, onSelect, closeOnSelect, selected]
    );

    return useMemo(() => {
        return customDropdown || DefaultDropdownComponent;
    }, [customDropdown, DefaultDropdownComponent]);
}
