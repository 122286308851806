// DEPS
import React, { FunctionComponent, memo, MutableRefObject } from "react";
import { T } from "@transifex/react";

// COMPONENTS
import PhaseSixIcon from "../../../../basic/phaseSixIcon/PhaseSixIcon";
import Modal, { Props as ModalProps, WithModalActions } from "../../../../basic/modal/Modal";

// STYLED COMPONENTS
import {
    Block,
    BlockTitle,
    BarsContainer,
    BarBox,
    Bar,
    BarDuration,
    modalCss,
    Arrow,
    Description,
    Button,
    ButtonWrapper,
    DescriptionNextPracticeDate,
} from "./styles";

export type Props = {
    phase: number;
    userPhases: {
        name: number;
        duration: string;
    }[];
    hasFirstPractice: boolean;
    buttonRef: MutableRefObject<HTMLButtonElement | undefined>;
} & Partial<ModalProps>;

const Phase: FunctionComponent<Props> = (props) => {
    const { phase, hasFirstPractice, userPhases, buttonRef, ...restProps } = props;

    if (!userPhases.length) {
        //this should never happen. But if it does, we might want to also send an ErrorNotification via Email to us at this point
        return <></>;
    }

    const lastIndex = userPhases.length - 1;
    const lastPhaseReached = phase === userPhases[lastIndex].name;

    const daysBeforeNextPractice = (() => {
        const days = parseInt(userPhases[phase - 1]?.duration) || 0;
        if (days === 1)
            return (
                <T
                    _str="Tomorrow"
                    _tags="Learn"
                    _comment="Full string will be: 'Next automatic query: Tomorrow'"
                />
            );
        return (
            <T
                _str="in {X} days"
                _tags="Learn"
                _comment="Full string will be: 'Next automatic query: in {X} days'"
                X={days}
            />
        );
    })();

    const firstPhase = {
        duration: (
            <T
                _str="Start"
                _tags="Learn"
            />
        ),
    };
    const lastPhase = {
        name: <PhaseSixIcon name="school" />, // todo: how to figure out the right size here?
        duration: (
            <T
                _str="Goal"
                _tags="Learn"
            />
        ),
    };

    const result: any = [...userPhases];
    result[0] = { ...result[0], ...firstPhase };
    result[lastIndex] = { ...result[lastIndex], ...lastPhase };

    return (
        <Modal
            active={true}
            headerComponent={false}
            footerComponent={(props: WithModalActions) => {
                const { modalActions } = props;
                return (
                    <ButtonWrapper>
                        <Button
                            ref={(refElement) => (buttonRef.current = refElement || undefined)}
                            tabIndex={1}
                            onClick={modalActions?.hide}
                        >
                            <T
                                _str="Got it. Continue practice"
                                _tags="Learn"
                            />
                        </Button>
                    </ButtonWrapper>
                );
            }}
            preventCloseOnBg
            modalBodyCss={modalCss}
            {...restProps}
        >
            <Block>
                <BlockTitle>
                    {!lastPhaseReached ? (
                        <T
                            _str="Well done! First vocabulary in {highlighted}"
                            _tags="Learn"
                            highlighted={
                                <span>
                                    <T
                                        _str="Phase {phase}"
                                        _tags="Learn"
                                        phase={phase}
                                    />
                                </span>
                            }
                        />
                    ) : (
                        <T
                            _str="Congratulations! First vocabulary in {highlighted}"
                            _tags="Learn"
                            highlighted={
                                <span>
                                    <T
                                        _str="Long time memory"
                                        _tags="Learn"
                                    />
                                </span>
                            }
                        />
                    )}
                </BlockTitle>
                <BarsContainer>
                    {result.map(({ name, duration }: any, i: number, arr: any[]) => {
                        const height = (100 / arr.length) * (i + 1);
                        return (
                            <BarBox key={name}>
                                <Bar
                                    height={height}
                                    active={phase === name}
                                >
                                    {name}
                                </Bar>
                                <BarDuration>{duration}</BarDuration>
                            </BarBox>
                        );
                    })}
                </BarsContainer>
                <Arrow />
                <Description>
                    <T
                        _str="Days until the next practice"
                        _tags="Learn, GraphLabel"
                    />
                </Description>
            </Block>

            <Block>
                {!lastPhaseReached ? (
                    <DescriptionNextPracticeDate>
                        <T
                            _str="Next automatic query: {daysBeforeNextPractice}"
                            _tags="Learn"
                            daysBeforeNextPractice={<span>{daysBeforeNextPractice}</span>}
                        />
                        <br />
                        {getSecondaryText(phase, userPhases, hasFirstPractice)}
                    </DescriptionNextPracticeDate>
                ) : (
                    <Description>
                        <T
                            _str="Learning process completed: {highlighted}"
                            _tags="Learn"
                            highlighted={
                                <span>
                                    <T
                                        _str="Well done!"
                                        _tags="Learn"
                                    />
                                </span>
                            }
                        />
                        <T
                            _str="There is no automatic further query."
                            _tags="Learn"
                        />
                    </Description>
                )}
            </Block>
        </Modal>
    );
};

export default memo(Phase);

function getSecondaryText(phase: number, userPhases: Props["userPhases"] = [], hasFirstPractice: boolean = false) {
    switch (phase) {
        case 2:
            if (hasFirstPractice) return "";
            return (
                <T
                    _str="… and if you continue to be diligent we have {highlited} for you!"
                    _tags="Learn"
                    highlited={
                        <span>
                            <T
                                _str="a surprise"
                                _tags="Learn"
                            />
                        </span>
                    }
                />
            );
        case 3:
            return (
                <T
                    _str="Thanks to increasing time intervals, you can effectively learn vocabulary"
                    _tags="Learn"
                />
            );
        case 4:
            return (
                <T
                    _str="You’ve already memorized the vocabulary well!"
                    _tags="Learn"
                />
            );
        case 5:
            return (
                <T
                    _str="Only {X} correct queries to long-term memory"
                    _tags="Learn"
                    X={userPhases.length - phase}
                />
            );
        default:
            return (
                <T
                    _str="Great job, your goal is close"
                    _tags="Learn"
                />
            );
    }
}
