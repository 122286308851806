import React, { useEffect, useState } from "react";
import { T } from "@transifex/react";
import Modal from "../../../components/basic/modal/Modal";
import { Actions, InnerModal, TestNameField } from "./styles";
import { FilledButton, OutlinedButton } from "../../../components/basic/button/Button";
import { DefaultFocus } from "../../../components/basic/defaultFocus/DefaultFocus";

interface RenameTestModalProps {
    oldName: string;
    active: boolean;
    onClose(): void;
    onSubmit(value: string): void;
}

export const RenameTestModal = ({ active, oldName, onClose, onSubmit }: RenameTestModalProps) => {
    const [value, setValue] = useState("");

    useEffect(() => {
        if (active) {
            setValue(oldName);
        }
    }, [active, oldName]);

    return (
        <Modal
            active={active}
            headerComponent={false}
            afterClose={onClose}
            modalBodyStyle={{
                width: 343,
            }}
        >
            <InnerModal>
                <DefaultFocus />
                <TestNameField
                    type="text"
                    value={value}
                    onChange={(ev) => setValue(ev.target.value)}
                />
                <Actions>
                    <FilledButton
                        onClick={() => {
                            onSubmit(value);
                            setValue("");
                            onClose();
                        }}
                    >
                        <T
                            _str="Save"
                            _tags="tests"
                        />
                    </FilledButton>
                    <OutlinedButton onClick={onClose}>
                        <T
                            _str="Cancel"
                            _tags="tests"
                        />
                    </OutlinedButton>
                </Actions>
            </InnerModal>
        </Modal>
    );
};
